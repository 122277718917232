import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { APP_CONSTANT } from "../constants/AppConstant";
import { UserContext } from "../contexts/UserContext";
import { useNotionMutation } from "../hooks";
import { Loader } from "../stories/components/Loader/Loader";
import { getLoadingImage, getLoadingMessage, useQueryParams } from "../utils";

export const NotionAuth = () => {
    const query = useQueryParams();
    const code = query.get("code");
    const history = useHistory();
    const { notionOAuth } = useNotionMutation();
    const { mutate: mutateNotionOAuth, isSuccess, isError, error, isLoading } = notionOAuth;

    const { user, setDoItLater } = UserContext();
    useEffect(() => {
        if (!user.notionConnected && code) {
            mutateNotionOAuth({
                code,
                callbackUrl: APP_CONSTANT.notion.redirectUri,
            });
        }
    }, [code]);

    useEffect(() => {
        if (isSuccess) {
            setDoItLater(true);
            history.push({
                pathname: "projects",
                state: "success",
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError && error) {
            if (error.response?.status === 404) {
                history.push({
                    pathname: "projects",
                    state: "page-not-selected",
                });
            }
        }
    }, [isError]);

    if (isLoading)
        return <Loader message={getLoadingMessage("project")} image={getLoadingImage("project")} />;

    return <></>;
};

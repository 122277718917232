import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

import { APP_CONSTANT } from "../../../constants/AppConstant";
import { useNotionMutation } from "../../../hooks";
import { ActionButtonType } from "../../../types";
import { EcModal } from "../EcModal/EcModal";

const StepsPanel = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        height: "auto",
        overflow: "scroll",
    },
}));

interface INotionLetsStartModal {
    onClose: () => void;
    open: boolean;
}

const NotionLetsStartModal = (props: INotionLetsStartModal) => {
    const { onClose, open } = props;
    const [isTermsCondition, setIsTermsCondition] = useState(false as boolean);
    const { acceptTermsOfUseAndPrivacyPolicy } = useNotionMutation();
    const { mutate, isSuccess, isLoading } = acceptTermsOfUseAndPrivacyPolicy;

    useEffect(() => {
        if (isSuccess) {
            const {
                notionUrl,
                notion: { clientId, redirectUri },
            } = APP_CONSTANT;
            const baseUrl = `${notionUrl}/v1/oauth/authorize?owner=user&client_id=${clientId}&redirect_uri=${encodeURIComponent(
                redirectUri
            )}&response_type=code`;
            window.open(baseUrl, "_self");
        }
    }, [isSuccess]);

    const ModalContent = () => (
        <Box sx={{ my: 2 }}>
            <StepsPanel>
                <Grid item spacing={{ xs: 4, sm: 2, md: 2 }}>
                    <img src={APP_CONSTANT.notion.createPageGif} alt="" width="100%" />
                </Grid>
            </StepsPanel>
            <Box
                sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                }}
            >
                <FormGroup>
                    <FormControlLabel
                        checked={isTermsCondition}
                        onChange={() => setIsTermsCondition(!isTermsCondition)}
                        control={<Checkbox />}
                        label={
                            <Box sx={{ display: "inline-flex" }}>
                                I agree to&nbsp;
                                <Link target="_blank" href="/terms-of-use">
                                    Terms of Use
                                </Link>
                                &nbsp;&amp;&nbsp;
                                <Link target="_blank" href="/privacy-policy">
                                    Privacy Policy
                                </Link>
                            </Box>
                        }
                    />
                </FormGroup>
            </Box>
        </Box>
    );
    const LetsStartActionButtons: ActionButtonType = {
        label: "Connect Now",
        onClick: () => mutate(),
        variant: "contained",
        disabled: !isTermsCondition || isLoading,
    };
    return (
        <EcModal
            size="md"
            open={open}
            onClose={onClose}
            closeOnClickOutside
            title="Enjoy seamless integration with Notion"
            subtitle="Utilize our integration to view your Figma comments data on Notion"
            actionButtons={LetsStartActionButtons}
            showCloseIcon
        >
            <ModalContent />
        </EcModal>
    );
};

export default NotionLetsStartModal;

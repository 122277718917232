import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface EcDrawerPropsType {
    title: JSX.Element | string;
    open: boolean;
    direction: "left" | "right" | "top" | "bottom";
    onClose: () => void;
    children: JSX.Element | JSX.Element[];
    closeOnClickOutside?: boolean;
    customeWidth?: boolean;
}
const useStyles = makeStyles(() => ({
    drawer: {
        width: "672px",
    },

    "@media (max-width: 600px)": {
        drawer: {
            width: "400px",
        },
    },
}));
const EcDrawer = (props: EcDrawerPropsType) => {
    const classes = useStyles();
    const { title, open, direction, children, closeOnClickOutside, onClose, customeWidth } = props;

    return (
        <Drawer
            anchor={direction}
            open={open}
            onClose={closeOnClickOutside ? onClose : undefined}
            sx={{ maxWidth: "100%" }}
        >
            <Box
                sx={{
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    // maxWidth: "100%",
                }}
                className={customeWidth ? classes.drawer : ""}
            >
                <Box
                    sx={{
                        px: 1,
                        pt: 0.5,
                        borderBottom: "1px solid",
                        borderBottomColor: (theme) => theme.palette.grey[300],
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {title}
                    <IconButton
                        aria-label="close drawer"
                        component="span"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        overflowY: "scroll",
                        maxWidth: "100%",
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Drawer>
    );
};

EcDrawer.defaultProps = {
    closeOnClickOutside: true,
};

export { EcDrawer };

import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { useAddProject, useFetchProjectDetails } from "../../hooks/filesHook";
import { EcDrawer } from "../../stories/components";
import { Cards } from "../../stories/components/Cards/Cards";
import { ProjectLoader } from "./ProjectLoader";

const GridWrapper = styled.div`
    display: block;
    h3 {
        position: relative;
        font-size: 1.125rem;
        z-index: 1;
        overflow: hidden;
        text-align: center;
        font-weight: 400;
        color: #212121;
        margin: 1.5625rem 0 1.5625rem;
        &:after,
        &:before {
            position: absolute;
            top: 51%;
            overflow: hidden;
            width: 50%;
            height: 1px;
            content: " ";
            background-color: #e0e0e0;
            margin-left: 1%;
        }
        &:before {
            margin-left: -51%;
            text-align: right;
        }
    }
    .unmark {
        display: inline-flex;
        margin: 0 0.48rem 1rem 0.48rem;
        width: 32%;
        &:nth-child(3n) {
            margin-right: 0;
        }
        div {
            width: 100%;
        }
        }
    }

    @media screen and (max-width: 1200px) {
        .m-cards-outer {
            .unmark {
                margin-left: 0 0.45rem !important;
                margin-right: 0 0.45rem !important;
            }
        }
    }

    @media screen and (max-width: 1140px) {
        .m-cards-outer {
            .unmark {
                margin: 0 0.4rem 1rem 0.4rem !important;
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .m-cards-outer {
            justify-content: center;
            .unmark {
                width: 360px !important;
            }
        }
    }

    @media screen and (max-width: 858px) {
        .m-cards-outer {
            .unmark {
                width: 47% !important;
                margin-inline: 0.5rem !important;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .m-cards-outer {
            .unmark {
                width: 400px !important;
            }
        }
    }

    @media screen and (max-width: 458px) {
        .m-cards-outer {
            .unmark {
                width: 96% !important;
            }
        }
    }
`;

const CardOuter = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

interface ProjectDataType {
    projectId: string;
    fileName: string;
    allComments: number;
    myComments: number;
    resolvedComments: number;
    unresolvedComments: number;
    newComments: number;
    editorType: string;
}

const AddProjectDrawer = (props: { onClose: () => void }) => {
    const [projectUrl, setProjectUrl] = useState("");
    const [urlError, setUrlError] = useState("");
    const { onClose } = props;
    const [projectKey, setProjectKey] = useState("");
    const [projectData, setProjectData] = useState<ProjectDataType[]>([]);
    const { fetchProjectDetails } = useFetchProjectDetails();
    const {
        mutate: mutateProjectDetails,
        data: projectDetailsResponse,
        isLoading: fetchingProjectDetails,
        isSuccess: isProjectDetailsFetched,
    } = fetchProjectDetails;

    const { addProject } = useAddProject();

    const { mutate: mutateAddProjectData, isSuccess } = addProject;

    const handleAddProjectUrl = (url: string) => {
        setProjectUrl(url);
        if (url === "") {
            setUrlError("");
            return;
        }
        let filteredUrl: string[] = url.split("?");
        const designUrlPrefix = "https://www.figma.com/design/";
        const protoUrlPrefix = "https://www.figma.com/proto/";
        const boardUrlPrefix = "https://www.figma.com/board/";
        if (filteredUrl.length >= 1) {
            const [newfilteredUrl] = filteredUrl;
            let splittedUrl: string[] = [];
            if (newfilteredUrl.search(designUrlPrefix) !== -1) {
                splittedUrl = newfilteredUrl.split(designUrlPrefix);
            } else if (newfilteredUrl.search(protoUrlPrefix) !== -1) {
                splittedUrl = newfilteredUrl.split(protoUrlPrefix);
            } else if (newfilteredUrl.search(boardUrlPrefix) !== -1) {
                splittedUrl = newfilteredUrl.split(boardUrlPrefix);
            }
            if (splittedUrl.length > 1) {
                filteredUrl = splittedUrl[1].split("/");
                const fileKey = filteredUrl[0];
                setProjectKey(fileKey);
                setUrlError("");
            } else {
                // invalid file url
                setUrlError("Invalid URL");
            }
        } else {
            // invalid file url
            setUrlError("Invalid URL");
        }
    };

    const handleSubmitAddProject = () => {
        mutateAddProjectData(projectData);
    };

    const handleRemoveProject = (index: number) => {
        setProjectData((current) => current.filter((data, index2) => index2 !== index));
    };

    useEffect(() => {
        if (projectDetailsResponse) {
            const {
                fileName,
                myComments,
                allComments,
                resolvedComments,
                unresolvedComments,
                newComments,
                editorType,
            } = projectDetailsResponse.data.data;
            const tempProjectData = {
                projectId: projectKey,
                fileName,
                myComments,
                allComments,
                resolvedComments,
                unresolvedComments,
                newComments,
                editorType,
            };
            setProjectData((fileDetails: ProjectDataType[] = []) => {
                const index = fileDetails.findIndex(
                    (file) => file.projectId === tempProjectData.projectId
                );
                if (index === -1) {
                    return [...fileDetails, tempProjectData];
                }
                const updatedFileDetails = [...fileDetails];
                updatedFileDetails[index] = tempProjectData;
                return updatedFileDetails;
            });
        }
    }, [projectDetailsResponse]);

    useEffect(() => {
        if (projectKey !== "") {
            mutateProjectDetails({ projectKey });
        }
    }, [projectKey]);

    useEffect(() => {
        if (isSuccess) {
            // TODO: need to optimize it
            onClose();
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isProjectDetailsFetched) {
            setProjectUrl("");
            setProjectKey("");
        }
    }, [isProjectDetailsFetched]);

    return (
        <EcDrawer
            open
            direction="right"
            closeOnClickOutside={false}
            onClose={onClose}
            title={
                <Typography variant="h6" fontWeight="500" mb={1.5}>
                    Add Projects
                </Typography>
            }
        >
            <Box
                width={350}
                height="calc(100vh - 135px)"
                overflow="scroll"
                pr={1}
                sx={{
                    overflowX: "hidden",
                }}
            >
                <Box mt={2}>
                    <TextField
                        label="Add Figma File URL"
                        variant="standard"
                        sx={{ width: "100%" }}
                        onChange={(event) => handleAddProjectUrl(event.target.value)}
                        value={projectUrl}
                        id="NB-add-figma-file-url"
                        error={!!urlError}
                        helperText={urlError}
                        disabled={fetchingProjectDetails}
                    />
                    {fetchingProjectDetails && <ProjectLoader />}
                    {projectData && projectData.length > 0 && (
                        <>
                            <Box mt={3} fontSize={16} fontWeight={700} mb={2}>
                                <Typography variant="inherit">Added Projects</Typography>
                            </Box>
                            <GridWrapper>
                                {projectData.map((file, index) => (
                                    <CardOuter className="m-cards-outer">
                                        <Box
                                            width="100% !important"
                                            className="unmark"
                                            sx={{
                                                margin: "0 1px 10px 1px !important",
                                            }}
                                        >
                                            <Cards
                                                filterType={file?.fileName}
                                                editorType={file?.editorType}
                                                createdAt="Last updated on Dec 14, 2022"
                                                allComments={file.allComments}
                                                myComments={file.myComments}
                                                newComments={file.newComments}
                                                resolvedComments={file.resolvedComments}
                                                unresolvedComments={file.unresolvedComments}
                                                usedIn="sideDrawer"
                                                handleCardClose={() => handleRemoveProject(index)}
                                            />
                                        </Box>
                                    </CardOuter>
                                ))}
                            </GridWrapper>
                        </>
                    )}
                </Box>
            </Box>
            <Box width={350} display="flex" justifyContent="flex-end" mt={1}>
                <Button variant="text" sx={{ marginRight: "10px" }} onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    sx={{ marginRight: "10px" }}
                    onClick={handleSubmitAddProject}
                    disabled={projectData.length === 0}
                    id="NB-add-project-button"
                >
                    Add
                </Button>
            </Box>
        </EcDrawer>
    );
};

export { AddProjectDrawer };

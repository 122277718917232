import { Box, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import Figma from "../../../../assets/figma.svg";
import { ReactionList, ReactionPopover } from "../../../../containers";
import { UserContext } from "../../../../contexts";
import { useFocusRef } from "../../../../hooks/useFocusRef";
import { ITreeRowType, ReactionActionType, ReactionType } from "../../../../types";
import { getMessagesWithEmojis, getReactionAction } from "../../../../utils";

const Cell = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    .action-location {
        strong {
            color: #2979ff;
            width: 5rem;
            margin-right: 1.5rem;
            font-weight: 700;
            font-size: 0.875rem;
            cursor: pointer;
            align-items: center;
            & > span {
                white-space: nowrap;
            }
            .down-icon {
                margin-left: 2px;
                display: inline-flex;
                line-height: 1;
            }
        }
        a {
            cursor: pointer;
        }
        img {
            height: 18px;
            width: 18px;
            cursor: pointer;
            display: flex;
        }
    }
`;

const HighlightStatusIcon = styled.div`
    &.highlight-icon {
        background-color: #2979ff;
        height: 11px;
        width: 11px;
        display: none;
        border-radius: 50%;
        position: absolute;
        right: 0;
    }
`;

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#fff",
        color: "#212121",
        fontSize: 12,
        boxShadow: "0 2px 8px 0 rgba(0,0,0,0.25)",
        padding: "10px",
        lineHeight: "16px",
        letterSpacing: "0.5px",
    },
}));
interface CellExpanderFormatterProps {
    isCellSelected: boolean;
    row: ITreeRowType;
    onCellExpand?: () => void;
    handleAction: (para: string, prop: any) => void;
    onAddOrRemoveReaction: (reaction: ReactionType, action: ReactionActionType) => void;
}

export const CommentCell = (props: CellExpanderFormatterProps) => {
    const { figmaUser } = UserContext();
    const { isCellSelected, row, handleAction, onAddOrRemoveReaction } = props;
    const { ref, tabIndex } = useFocusRef<HTMLSpanElement>(isCellSelected);
    const commentsDomElement = useRef<any>(null);
    const [getEllips, hideEllips] = useState(false);
    useEffect(() => {
        if (commentsDomElement.current) {
            hideEllips(
                commentsDomElement.current.offsetWidth < commentsDomElement.current.scrollWidth
            );
        }
    }, []);

    const handleAction2 = (parameter: string) => () => {
        handleAction(parameter, props);
    };

    return (
        <>
            <Cell>
                <Box display="flex" flexDirection="column" minWidth="32rem">
                    <Box display="flex" alignItems="center" height="30px">
                        {getEllips ? (
                            <LightTooltip
                                className="comment-cell-tooltip"
                                title={row.message}
                                placement="top-start"
                                arrow
                            >
                                <span
                                    className="cell"
                                    ref={commentsDomElement}
                                    style={{
                                        width: "70%",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        lineHeight: "1rem",
                                    }}
                                >
                                    {getMessagesWithEmojis(row.message)}
                                </span>
                            </LightTooltip>
                        ) : (
                            <span
                                className="cell"
                                ref={commentsDomElement}
                                style={{
                                    width: "70%",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    lineHeight: "1rem",
                                }}
                            >
                                {getMessagesWithEmojis(row.message)}
                            </span>
                        )}
                    </Box>
                    <Box display="flex" alignItems="center" height="44px">
                        <ReactionPopover
                            onClickReaction={(reaction) => {
                                onAddOrRemoveReaction(
                                    reaction,
                                    getReactionAction(row.reactions || [], reaction, figmaUser)
                                );
                            }}
                        />
                        <ReactionList
                            data={row.reactions}
                            onClick={(reaction) => {
                                onAddOrRemoveReaction(
                                    reaction,
                                    getReactionAction(row.reactions || [], reaction, figmaUser)
                                );
                            }}
                        />
                    </Box>
                </Box>
                <Box position="absolute" right="0" top="0" width="11rem" height="100%">
                    <Box
                        component="span"
                        display="flex"
                        alignItems="center"
                        alignContent="center"
                        justifyContent="flex-end"
                        paddingLeft="1rem"
                        paddingRight="1rem"
                        position="relative"
                        className="action-location"
                        width="10rem"
                        height="100%"
                    >
                        <strong
                            style={{
                                display: row.children && row.children.length ? "flex" : "none",
                            }}
                            role="presentation"
                            onClick={handleAction2("reply")}
                        >
                            <span ref={ref} tabIndex={tabIndex}>
                                {row?.children?.length === 1
                                    ? `${row?.children?.length} Reply`
                                    : `${row?.children?.length} Replies`}
                            </span>
                        </strong>

                        <a target="_blank" rel="noreferrer" href={row.commentLocation}>
                            <LightTooltip title="Figma Location" placement="top" arrow>
                                <img alt="notification" src={Figma} />
                            </LightTooltip>
                        </a>

                        <HighlightStatusIcon className="highlight-icon" />
                    </Box>
                </Box>
            </Cell>
        </>
    );
};

CommentCell.defaultProps = {
    onCellExpand: () => {},
};

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import styled from "styled-components";

import { GlobalFooter } from "../stories/components/global-footer/GlobalFooter";

const MainContainer = styled.div`
    padding: 10px;
    display: block;
    width: 1128px;
    max-width: 95%;
    margin: auto;
`;

export const TermsOfUse = () => (
    <MainContainer>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "28px",
                    marginBottom: "15px",
                    lineHeight: "35px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Acceptance of Terms Of Use
            </Typography>
            <Typography variant="body1">
                The website and web application at https://exportcomments.io/ and such other
                locations, may be updated, relocated or otherwise modified, including through
                downloadable software, tablet applications and all intellectual property contained
                therein (the Website and App hereinafter being collectively referred to as the
                “Site” are owned and operated by Intelligaia Technologies and its affiliated
                companies. By using the Site or the Service (as hereinafter defined), you indicate
                your acceptance of the terms and conditions set forth in these Terms of Use and to
                follow all applicable regulations that apply to the Service. If you do not agree to
                these Terms of Use you must stop using the Site and the Service.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "28px",
                    marginBottom: "15px",
                    lineHeight: "35px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                General
            </Typography>
            <Box sx={{ marginBottom: "20px" }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "24px",
                        marginBottom: "10px",
                        lineHeight: "35px",
                        fontWeight: "500",
                        letterSpacing: "0.18px",
                    }}
                >
                    Acceptance
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    The Site provides a network (the “Service”) to UX/UI Researchers and IT
                    professionals through which they may export, manage, and transfer feedback added
                    by their team on Figma. Individual consumers (“Export Comments Members”) may
                    utilize the Service&apos;s for:
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    <ul>
                        <li>Bookmarking & Sharing your projects.</li>
                        <li>Viewing grouped & live comments in the dashboard.</li>
                        <li>Searching, sorting & filtering comments.</li>
                        <li>Viewing/Replying/Deleting comments directly from the dashboard.</li>
                        <li>Highlighting new comments and mark your previous comments as Read.</li>
                        <li>Downloading comments into XLSX format.</li>
                        <li>Syncing projects and its comments with Notion</li>
                    </ul>
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    The Site (including any related sub-site, service, feature or functionality) and
                    the Service and Treatments are provided subject to these Terms of Use, as they
                    may be amended by us, and any guidelines, rules or operating policies that we
                    may post on the Site, including, without limitation, our Privacy Policy, all of
                    which are specifically incorporated herein by reference (collectively, the
                    “Agreement”). We may amend this Agreement from time to time due to changes to
                    the Site or the Service, to account for developments under the law, or for any
                    other commercially reasonable reason. Future performance by us of our
                    obligations under this Agreement is sufficient consideration for any such
                    amendment. Any amendment will only become effective upon notification to you (by
                    email or by posting on our Site) and, if you do not want to agree to any such
                    amendment, you should stop using the Site and the Service and contact us to
                    cancel your registration. By logging in to your Export Comments account, by
                    accessing the Site or by using the Service, you accept this Agreement on behalf
                    of yourself and any business or organization you represent (collectively,
                    “you”).
                </Typography>
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "24px",
                        marginBottom: "10px",
                        lineHeight: "35px",
                        fontWeight: "500",
                        letterSpacing: "0.18px",
                    }}
                >
                    Registration
                </Typography>
                <Typography variant="body1">
                    In order to become a Export Comments User you must have a valid Figma Account as
                    one can only login to the site via Figma Authentication Service. You also agree
                    to provide true, accurate, current and complete information about yourself and
                    your organization, as applicable, as requested on the Site.
                </Typography>
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "24px",
                        marginBottom: "10px",
                        lineHeight: "35px",
                        fontWeight: "500",
                        letterSpacing: "0.18px",
                    }}
                >
                    Account Access
                </Typography>
                <Typography variant="body1">
                    Any user who has access to a Figma account and also uses the Exports Comments
                    Plugin can login to the Export Comments Site by the Figma Authentication
                    Service. You are responsible for maintaining the confidentiality of your account
                    credentials and agree to notify Export Comments immediately of any unauthorized
                    use of your
                </Typography>
            </Box>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "28px",
                    marginBottom: "15px",
                    lineHeight: "35px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Copyright And Trademarks
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Except as otherwise indicated, the Site, and all text, images, marks, logos and
                other content contained herein, including, without limitation, the Export Comments
                logo and all designs, text, graphics, pictures, information, data, software, sound
                files, other files, messages, information, text, music, sound, photos, graphics,
                code or other material and the selection and arrangement thereof (collectively, the
                “Site Content”) are the proprietary property of or its licensors and are protected
                by international copyright laws. All rights to the Site Content are expressly
                reserved.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments, its logo and all other product or service names or slogans
                displayed on the Site are registered and/or common law trademarks of Export Comments
                and/or its licensors, and may not be copied, imitated or used, in whole or in part,
                without the prior written permission of Export Comments or the applicable trademark
                holder. In addition, the look and feel of the Site, including all page headers,
                custom graphics, button icons and scripts, is the service mark, trademark and/or
                trade dress of Export Comments and may not be copied, imitated or used, in whole or
                in part, without the prior written permission of Export Comments.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                ANY COPYING, DISTRIBUTING, TRANSMITTING, POSTING, LINKING, DEEP LINKING, OR OTHER
                MODIFICATION OF THIS SITE WITHOUT THE EXPRESS WRITTEN PERMISSION OF Export Comments
                IS STRICTLY PROHIBITED. Any violation of this policy may result in a copyright,
                trademark or other intellectual property right infringement that may subject you to
                civil and /or criminal penalties.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "28px",
                    marginBottom: "15px",
                    lineHeight: "35px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Access And Use Of The Site And Service
            </Typography>
            <Box sx={{ marginBottom: "20px" }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "24px",
                        marginBottom: "10px",
                        lineHeight: "35px",
                        fontWeight: "500",
                        letterSpacing: "0.18px",
                    }}
                >
                    Prohibition on Spam; Permission Practices
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    You may not use the Site or the Service for purposes of sending unsolicited
                    email messages (sometimes called “spam”).
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    We reserve the right without notice to take all measures of any nature (whether
                    legal, technical or otherwise) to prevent unsolicited bulk email and/or other
                    unauthorized email, messages or campaigns from entering, utilizing or remaining
                    within our network.
                </Typography>
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "24px",
                        marginBottom: "10px",
                        lineHeight: "35px",
                        fontWeight: "500",
                        letterSpacing: "0.18px",
                    }}
                >
                    Consent to Electronic Communications
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    By using the Service or providing personal information (as defined in the
                    Privacy Policy) to us, you agree that we may communicate with you electronically
                    regarding security, privacy, and administrative issues relating to your use of
                    the Service. If we learn of a security system’s breach, we may attempt to notify
                    you electronically by posting a notice on the Site or sending an email to you,
                    if we have your email address.
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    If you wish to remove yourself from any newsletters via email, please email us
                    with an “OPT-OUT”, “UNSUBSCRIBE” or “REMOVE” in the subject line. You
                    acknowledge that any terms between you and any third-party provider create no
                    obligation or responsibility on the part of Export Comments, and that Export
                    Comments is not responsible for any failure of warranty by any such third party.
                    Export Comments cannot control certain factors relating to message delivery. We
                    have no liability for transmission delays or message failures.
                </Typography>
            </Box>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "28px",
                    marginBottom: "15px",
                    lineHeight: "35px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Provision Of Service And User Conduct
            </Typography>
            <Box sx={{ marginBottom: "20px" }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "24px",
                        marginBottom: "10px",
                        lineHeight: "35px",
                        fontWeight: "500",
                        letterSpacing: "0.18px",
                    }}
                >
                    Use of Site and Service
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    You acknowledge that you are solely responsible for your data input messages
                    using the Site and the Service and that Export Comments is responsible solely
                    for providing the Site platform and interface and instructions on how to use the
                    same. You are solely responsible for ensuring adherence to all such instructions
                    on how to use the Site and Service.
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    Save and except for its obligations set forth above, Export Comments takes no
                    responsibility and assumes no liability for any content or data input or stored
                    or uploaded by you or any third party (including, without limitation, all
                    contact information and message content), or for any loss or damage thereto.
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    You further agree to comply with the following in connection with your use of
                    the Site and the Service:
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    You may not access or use the Site or the Service in a way that uses technology
                    or other means to access, index, re-render, frame, mirror, truncate, add to,
                    inject, filter or link to the Site or the Service that is not authorized by us
                    (including by removing, disabling, bypassing, or circumventing any content
                    protection or access control mechanisms intended to prevent the unauthorized
                    use, download, linking, framing, reproduction, access to, or distribution of the
                    Site or the Service).
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    You may not use any deep-link, page-scrape, robot, crawl, index, spider, offline
                    reader, click spam, macro programs, internet agent, or other automatic device,
                    program, algorithm or methodology which does the same things, to use, access,
                    copy, index, acquire information, generate impressions or clicks, input
                    information, store information, search, generate searches, or monitor any
                    portion of the Site or the Service for any unauthorized purpose.
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    You may not use the Site or the Service in a way that, as determined in our sole
                    discretion, damages, disables, overburdens, impairs, or gains unauthorized
                    access to the Site or the Service, including Export Comments’s servers, computer
                    network, or user accounts.
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    You may not use the Site or the Service in a way that removes, modifies,
                    disables, blocks, obscures or otherwise impairs any advertising in connection
                    with the Site or the Service.
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    You may not copy, display, distribute, download, license, modify, publish,
                    re-post, reproduce, reuse, sell, transmit, use to create a derivative work, or
                    otherwise use the content of the Site or the Service for public or commercial
                    purposes without our express written permission.
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    You shall not interfere with or disrupt the Site or any related Export Comments
                    servers or networks connected to the Site or the Service.
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    You shall not restrict or inhibit any other user from enjoying and using the
                    Site or the Service.
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    You shall not use the Site or the Service in violation of applicable law or
                    third party rights (including third party terms of service), and shall not use
                    the Site or the Service for hosting content (for example, images and documents)
                    that infringes on the intellectual property rights of others.
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    You may only use our templates, any images we provide, or any other features or
                    functionality of the Service (for example, you may not take an image or template
                    and use it on your website).
                </Typography>
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "24px",
                        marginBottom: "10px",
                        lineHeight: "35px",
                        fontWeight: "500",
                        letterSpacing: "0.18px",
                    }}
                >
                    Restrictions on Use
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    You expressly agree not to transmit, distribute, store, create or otherwise
                    publish through the Site any of the following:
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    content that is unlawful, libelous, defamatory, obscene, pornographic, indecent,
                    lewd, suggestive, harassing, threatening, invasive of privacy or publicity
                    rights, abusive, inflammatory, fraudulent or otherwise objectionable
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    content that would constitute, encourage or provide instructions for a criminal
                    offense, violate the rights of any party, or that would otherwise create
                    liability or violate any local, provincial, federal or international law
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    content that may infringe any patent, trademark, trade secret, copyright or
                    other intellectual or proprietary right of any party
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    content that would violate or infringe upon the rights of others
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    content that impersonates any person or entity or otherwise misrepresents your
                    affiliation with a person or entity
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    content that is false and misleading
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    unsolicited promotions, political campaigning, advertising or solicitations
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    viruses, corrupted data or other harmful, disruptive or destructive files
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    content that, in the sole judgment of Export Comments, is objectionable or which
                    may expose Export Comments or its users to any harm or liability of any kind.
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginBottom: "10px",
                    }}
                >
                    Any use of the Site in violation of the foregoing violates the Agreement and may
                    result in, among other things, termination or suspension of your rights to use
                    the Site or Service.
                </Typography>
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "24px",
                        marginBottom: "10px",
                        lineHeight: "35px",
                        fontWeight: "500",
                        letterSpacing: "0.18px",
                    }}
                >
                    Limitations on Use
                </Typography>
                <Typography variant="body1">
                    We reserve the right to modify, revise, suspend or discontinue any Service in
                    whole or in part, either temporarily or permanently and with or without notice,
                    and you acknowledge that we are not obligated to support or update the Service
                    in any manner. If we discontinue the Service in its entirety, we will provide
                    you with advance notice and an opportunity to cancel your account.
                </Typography>
            </Box>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "28px",
                    marginBottom: "15px",
                    lineHeight: "35px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Restrictions And Responsibilities
            </Typography>
            <Box sx={{ marginBottom: "20px" }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "24px",
                        marginBottom: "10px",
                        lineHeight: "35px",
                        fontWeight: "500",
                        letterSpacing: "0.18px",
                    }}
                >
                    No Rights in Software
                </Typography>
                <Typography variant="body1">
                    This is an Agreement for the Service and access to the Site, and you are not
                    granted a license to any software by this Agreement and nothing on the Site
                    shall be construed to confer any grant or license of any intellectual property
                    rights, whether by estoppel, by implication, or otherwise. You will not,
                    directly or indirectly, reverse engineer, decompile, disassemble, or otherwise
                    attempt to discover the source code, object code, or underlying structure,
                    ideas, or algorithms of, or found at or through the Site or the Service or any
                    software, documentation, or data related to the Site or the Service
                    (“Software”); remove any proprietary notices or labels from the Site or the
                    Service or any Software; modify, translate, or create derivative works based on
                    the Site or the Service or any Software; or copy, distribute, pledge, assign, or
                    otherwise transfer or encumber rights to the Site or the Service or any
                    Software. If you are using the Site or the Service in any jurisdiction which
                    restricts the ability of a software provider to restrict your right to reverse
                    engineer, decompile, disassemble, or otherwise attempt to discover the source
                    code, object code, or underlying structure, ideas, or algorithms of the Site,
                    the Software or the Service, then you hereby covenant that, prior to engaging in
                    such activities, you will first request that we perform such work at our
                    standard professional services rates. We can then decide either: (a) to perform
                    the work in order to achieve such interoperability and charge our then standard
                    rates for such work to you; (b) to permit you to reverse engineer parts of the
                    Software in order to obtain such source code, but only to the extent necessary
                    to achieve such interoperability; or (c) to provide you with the information
                    that you need regarding the Software for the purpose for which applicable law
                    permits you to engage in such activities despite a contractual prohibition on
                    such activities.
                </Typography>
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "24px",
                        marginBottom: "10px",
                        lineHeight: "35px",
                        fontWeight: "500",
                        letterSpacing: "0.18px",
                    }}
                >
                    Compliance with Laws
                </Typography>
                <Typography variant="body1">
                    The Site and the Service shall only be used for lawful purposes and you shall
                    use the Site and the Service only in compliance with this Agreement, and all
                    applicable laws, including, without limitation all applicable anti-spam
                    legislation and any other policies and laws related to unsolicited emails,
                    spamming, privacy, obscenity, defamation and copyright and trademark
                    infringement.
                </Typography>
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "24px",
                        marginBottom: "10px",
                        lineHeight: "35px",
                        fontWeight: "500",
                        letterSpacing: "0.18px",
                    }}
                >
                    Monitoring
                </Typography>
                <Typography variant="body1">
                    Although we have no obligation to monitor the content provided by you or your
                    use of the Site or the Service, we may do so and may block any messages, remove
                    any content or prohibit any use of the Site or the Service that we believe may
                    be (or is alleged to be) in violation of the foregoing or any other provision of
                    this Agreement.
                </Typography>
            </Box>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "28px",
                    marginBottom: "15px",
                    lineHeight: "35px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Third-Party Websities And Services
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                The Site may contain links to websites that are controlled by third parties and
                access to certain third-party services, which may include, without limitation,
                social bookmarking services and social network platforms (each, a “Third Party
                Service”). These links and services are provided to you as a convenience, and we are
                not affiliated with or responsible for the content, action or performance of any
                linked website or Third-Party Service and you use such websites or services at your
                own risk.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                You agree to abide by the terms and conditions of any applicable Third Party
                Service. Notwithstanding anything set forth herein to the contrary, you will abide
                by this Agreement regardless of anything to the contrary in your agreement with any
                third party and you shall not use such Third Party Service to avoid the restrictions
                set forth in this Agreement.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                We may terminate any Third Party Service’s ability to interact with the Site or the
                Service at any time, with or without notice, and in our sole discretion, with no
                liability to you or to the third party. Any Third Party Service may take actions to
                impact our ability to make available some or all of the features of the Site or the
                Service at any time, with or without notice, and we will not be liable to you or to
                the third party for any such actions. We accept no responsibility for reviewing
                changes or updates to, or the quality, content, policies, nature or reliability of,
                any Third Party Services.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "28px",
                    marginBottom: "15px",
                    lineHeight: "35px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Disclaimer Of Warranty
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                ALL CONTENT, PRODUCTS, AND SERVICES ON THE SITE, OR OBTAINED FROM A EXPORT COMMENTS
                PROVIDER ARE PROVIDED TO YOU “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND
                EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
                MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT,
                SECURITY OR ACCURACY.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                OTHER THAN AS REQUIRED UNDER APPLICABLE CONSUMER PROTECTION LAW, UNDER NO
                CIRCUMSTANCE WILL EXPORT COMMENTS BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
                USER’S RELIANCE ON INFORMATION OBTAINED THROUGH THE SITE OR A EXPORT COMMENTS
                PROVIDER, OR FROM A LINKED SITE.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "28px",
                    marginBottom: "15px",
                    lineHeight: "35px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Limitation Of Liability
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                You waive and covenant not to assert any claims or allegations of any nature
                whatsoever against Export Comments, its affiliates, or their respective directors,
                officers, employees arising out of or in any way relating to your use of the Site,
                the Service, or any other materials contained in or accessible through the Site.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                In no event shall the aggregate liability of Export Comments, whether in contract,
                warranty, tort (including negligence, whether active, passive or imputed), product
                liability, strict liability or other theory, arising out of or relating to the use
                of the Site exceed any compensation you pay to Export Comments.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT EXPORT COMMENTS AND ITS AFFILIATES,
                OFFICERS, EMPLOYEES, AND LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT,
                INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT
                NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
                LOSSES (EVEN IF EXPORT COMMENTS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES), RESULTING FROM USE OF THE SITE, CONTENT, SERVICE OR ANY TREATMENTS.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "28px",
                    marginBottom: "15px",
                    lineHeight: "35px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Indemnity
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                You hereby release and forever discharge Export Comments, its affiliates, and their
                respective shareholders, directors, employees, agents, contractors, successors and
                assigns (collectively, “Releasees”) from any and all claims, liabilities,
                obligations, actions, causes of action, suits, debts, covenants, controversies,
                damages, judgments and demands whatsoever in law, equity or any kind, type, or
                description, whether known or unknown, disputed or undisputed, accrued or unaccrued,
                liquidated or contingent, foreseen or unforeseen, asserted or unasserted, related to
                or arising out of any dispute with, any harm or injury (including, without
                limitation, personal injury or damage to property) caused by, or any other act or
                failure to act by, any advertisers, sponsors, other users of this Site and any other
                third parties, including Export Comments Providers.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                You agree to indemnify, defend and hold harmless all Releases from and against any
                and all claims, actions, demands, liabilities, losses, damages, penalties, interest,
                judgments, settlements, costs and expenses (including reasonable legal fees) that
                relate to, arise out of or are based upon: (a) any misrepresentation or breach of
                any warranty, covenant or agreement made by you related to these Terms of Use; or
                (b) any violation by you of any law, regulation or rule.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments reserves the right, at its own expense, to assume the exclusive
                defense and control of any matter otherwise subject to indemnification by you, but
                doing so shall not excuse your indemnity obligations. Export Comments shall have the
                right to participate in the defense of all claims as to which it does not assume
                defense and control, and you shall not settle any such claim without Export
                Comments’s prior written consent. You also agree to indemnify Export Comments for
                any reasonable legal fees or other costs incurred by Export Comments, acting
                reasonably, in investigating or enforcing its rights under these Terms of Use.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                You agree that Export Comments has set its prices and entered into this Agreement in
                reliance upon the disclaimers of warranty and the limitations of liability set forth
                herein, that they reflect an allocation of risk between the parties (including the
                risk that a contract remedy may fail of its essential purpose and cause
                consequential loss), and that they form an essential basis of the bargain between
                the parties.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "28px",
                    marginBottom: "15px",
                    lineHeight: "35px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Termination
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Export Comments may change or discontinue the Site or any of the Service at any time
                without prior notice. Export Comments reserves the right to terminate your use of
                this Site and the Service at Export Comments’s election and for any reason, without
                prior notice, and your agreement with Export Comments in accordance with these Terms
                of Use will automatically terminate in the event that you violate any of the terms
                and conditions set forth herein. In the event of any termination, you will
                immediately cease access to the Site and Service.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                If your Export Comments account is classified (at our sole discretion) as inactive
                for over 120 days, we have the right to permanently delete any of your data which
                has been stored.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "28px",
                    marginBottom: "15px",
                    lineHeight: "35px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Survival Of Terms
            </Typography>
            <Typography variant="body1">
                Notwithstanding any other provisions of these Terms of Use, or any general legal
                principles to the contrary, any provision of these Terms of Use that imposes or
                contemplates continuing obligations on a party will survive the expiration or
                termination of these Terms of Use.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "28px",
                    marginBottom: "15px",
                    lineHeight: "35px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Applicable Law And Venue
            </Typography>
            <Typography variant="body1">
                The Site is controlled by the Export Comments Team owned by Intelligaia Technologies
                operated by it from its offices in the USA and India. However, the Site is
                accessible internationally in all countries. You and Export Comments explicitly
                agree that these Terms of Use are governed and interpreted under the laws of the
                State of New York, United States of America, without regard to its choice of law
                provisions. By using this site, you consent to the jurisdiction of the federal and
                state courts located in New York for any action or claim arising from or relating to
                these Terms of Use. If any portion of these Terms of Use is deemed unlawful, void,
                or unenforceable, then that portion shall be deemed to be severable and shall be
                construed in accordance with applicable law. Such a portion will not affect the
                validity and enforceability of any remaining provisions or terms. Intelligaia&apos;s
                failure to act with respect to a breach of these Terms of Use by you or others does
                not constitute a waiver of the Terms of Use and shall not limit Intelligaia&apos;s
                rights with respect to such breach or any subsequent breaches.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "28px",
                    marginBottom: "15px",
                    lineHeight: "35px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Other
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                If any provision of these Terms of Use is deemed invalid, void, or for any reason
                unenforceable, then that provision shall be deemed severable from these Terms of Use
                and shall not affect the validity and enforceability of any remaining provisions.
                Export Comments may assign any or all of its rights hereunder to any party without
                your consent. You are not permitted to assign any of your rights or obligations
                hereunder without the prior written consent of Export Comments, and any such
                attempted assignment will be void and unenforceable. This Agreement constitutes the
                entire agreement between you and Export Comments regarding your use of the Site and
                the Service, and supersede all prior or contemporaneous communications whether
                electronic, oral or written between you and Export Comments regarding your use of
                the Site and the Service. Export Comments shall not be liable, or be deemed to be in
                default, to you by reason or on account of any delay or omission caused by epidemic,
                fire, power outages, action of the elements, strikes, lockouts, labor disputes,
                governmental law, regulations, ordinances, order of a court of competent
                jurisdiction, executive decree or order, act of God or public enemy, war, riot,
                civil commotion, earthquake, flood, accident, explosion, casualty, embargo or any
                other cause beyond the control of Export Comments, or any act, delay or omission not
                due to the negligence or default of Export Comments. Headings are for reference
                purposes only and do not limit the scope or extent of such sections. The failure of
                Export Comments to act with respect to a breach by you or others does not waive its
                right to act with respect to subsequent or similar breaches. Export Comments does
                not guarantee it will take action against all breaches of these Terms of Use. Except
                as otherwise expressly provided herein, there shall be no third-party beneficiaries
                to these Terms of Use.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                The parties hereto confirm their express wish that these Terms of Use and all
                documents and agreements directly or indirectly relating thereto be drawn up in the
                English language.
            </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: "28px",
                    marginBottom: "15px",
                    lineHeight: "35px",
                    fontWeight: "700",
                    letterSpacing: "0.18px",
                }}
            >
                Questions And Comments
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                If you have any questions regarding these Terms of Use or your use of the Site or
                the Service, please contact us here:
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                E-mail: uxdev@intelligaia.com , contact@intelligaia.com
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Full name of legal entity: Intelligaia
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Contact number: +1 (252) 400-1521
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: "10px",
                }}
            >
                Postal address of data protection officer: RESEARCH TRIANGLE PARK, NC 800, Park
                Offices Drive, Suite 406, Research Triangle Park, NC 27709 US
            </Typography>
        </Box>
        <GlobalFooter />
    </MainContainer>
);

import StarIcon from "@mui/icons-material/Star";
import { Box, Button, Container, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styledComponent from "styled-components";

import { APP_CONSTANT } from "../constants/AppConstant";
import {
    AddProjectDrawer,
    ConsentFormModal,
    FilterProjects,
    ManageTeamModal,
    ProjectNotFound,
    ProjectSearch,
} from "../containers";
import { NoProjectsFound } from "../containers/Projects/NoProjectsFound";
import { ProjectFilterContext } from "../contexts/ProjectFilterContext";
import { UserContext } from "../contexts/UserContext";
import {
    getFilterDateRange,
    useCommonPost,
    useDeleteProject,
    useFetchCommentsForDashboard,
    useFetchProjectList,
} from "../hooks";
import { useDebounce } from "../hooks/commonHook";
import {
    useCheckDatabaseExist,
    useCreateDatabase,
    useProjectConnect,
    useProjectDisconnect,
    useSyncComments,
    useUpdateDatabase,
} from "../hooks/notionHook";
import { saveDoItLaterState } from "../services/AuthService";
import { Cards } from "../stories/components/Cards/Cards";
import { EcDialog } from "../stories/components/EcDialog/EcDialog";
import { EcModal } from "../stories/components/EcModal/EcModal";
import { Loader } from "../stories/components/Loader/Loader";
import { NotionBanner } from "../stories/components/NotionBanner/NotionBanner";
import NotionLetsStartModal from "../stories/components/NotionLetsStartModal/NotionLetsStartModal";
import { ActionButtonType, IFavoriteRequestType, ISnackBarMessage, IUserFile } from "../types";
import {
    CheckIcon,
    exportCommentsToExcel,
    exportCommentsToNotion,
    getLoadingImage,
    getLoadingMessage,
    snackbar,
} from "../utils";

const GridWrapper = styledComponent.div`
    display: block;
    h3 {
        position: relative;
        font-size: 1.125rem;
        z-index: 1;
        overflow: hidden;
        text-align: center;
        font-weight: 400;
        color: #212121;
        margin:0;
        margin-bottom:1rem;
        &:after,
        &:before {
            position: absolute;
            top: 51%;
            overflow: hidden;
            width: 50%;
            height: 1px;
            content: " ";
            background-color: #e0e0e0;
            margin-left: 1%;
        }
        &:before {
            margin-left: -51%;
            text-align: right;
        }
    }
    .unmark {
        display: inline-flex;
        margin: 0 0.48rem 1rem 0.48rem;
        width: 32%;
        &:nth-child(3n) {
            margin-right: 0;
        }
        div {
            width: 100%;
        }
    }

    @media screen and (max-width: 1200px) {
        .m-cards-outer {
            .unmark {
                margin-left: 0 0.45rem !important;
                margin-right: 0 0.45rem !important;
            }
        }
    }

    @media screen and (max-width: 1140px) {
        .m-cards-outer {
            .unmark {
                margin: 0 0.4rem 1rem 0.4rem !important;
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .m-cards-outer {
            justify-content: center;
            .unmark {
                width: 360px !important;
            }
        }
    }

    @media screen and (max-width: 858px) {
        .m-cards-outer {
            .unmark {
                width: 47% !important;
                margin-inline: 0.5rem !important;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .m-cards-outer {
            .unmark {
                width: 400px !important;
            }
        }
    }

    @media screen and (max-width: 458px) {
        .m-cards-outer {
            .unmark {
                width: 96% !important;
            }
        }
    }

    @media screen and (max-width: 1210px) {
        &.sub-header .filter-w-search {
            flex-wrap: wrap;
        }
        &.sub-header .filter-w-search .seperator {
            display: none;
        }
        &.sub-header .filter-w-search .w-287 {
            width: 330px;
        }
    }

    @media screen and (max-width: 1023px) {
        &.sub-header {
            align-items: flex-start !important;
        }
    }

    @media screen and (max-width: 767px) {
        &.sub-header {
            flex-wrap: wrap;
            .m-640 {
                min-width: 100%;
            }
            .filter-w-search {
                min-width: 100%;
                flex-wrap: nowrap;
                margin-top: 15px;
                justify-content: flex-start;

                .searchwrap.w-287 {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 380px) {
        .filter-w-search .muicustSelect .MuiSelect-select {
                font-size: 13px !important;
            }
        }
    }
`;

const CardOuter = styledComponent.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

const StickyHeader = styledComponent.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 58px;
    background-color: rgba(245, 245, 245, 0.9); /* Adjust the alpha value as needed */
    z-index: 1;
    width:100%;
    @media screen and (min-width: 2560px) {

        //   margin: 0px 0px;
        //   padding: 20px 0px;

      }

      /* Styles for laptop screens (1440px and above) */
      @media screen and (max-width: 2560px) {

        //   margin: 0px 230px ;
          padding: 20px 0px;

      }
      @media screen and (max-width: 1920px) {

        // margin: 0px 300px ;
        padding: 20px 280px;

    }

      /* Styles for laptop screens (1024px and above) */
      @media screen and (max-width: 1440px) {

        //   margin: 0px 145px ;
          padding: 20px 50px;

      }

      /* Styles for tablet screens (768px and above) */
      @media screen and (max-width: 1024px) {

        //   margin: 0px 10px;
          padding: 20px 0px;

      }

      /* Styles for mobile screens (425px and above) */
      @media screen and (max-width: 768px) {

          margin: 0px 10px ;
          padding: 20px 0px;

      }

      /* Additional styles for smaller screens if necessary */
      @media screen and (max-width: 425px) {

        flex-direction: column;
        align-items: flex-start;
        padding: 0px 0px;


      }
`;

export const UserFiles = snackbar((props: ISnackBarMessage) => {
    const { figmaUrl } = APP_CONSTANT;
    const { user, doItLater, setDoItLater, setFileList } = UserContext();
    const { filter, range } = useContext(ProjectFilterContext);
    const history = useHistory();
    const location = useLocation();
    const [showAllProjects, setShowAllProjects] = useState(false);

    const [isViewLessClicked, setIsViewLessClicked] = useState(false);
    const toggleShowAllProjects = () => {
        setShowAllProjects(!showAllProjects);
        setIsViewLessClicked(!showAllProjects);
    };
    const getDisplayedProjects = () => {
        if (showAllProjects) {
            return files?.filter((file: IUserFile) => file.isFavorite === 0) || [];
        }
        return files?.filter((file: IUserFile) => file.isFavorite === 0).slice(0, 3) || [];
    };
    const [showAllFavProjects, setShowAllFavProjects] = useState(false);
    const [isViewLessFavClicked, setIsViewLessFavClicked] = useState(false);

    const toggleShowAllFavProjects = () => {
        setShowAllFavProjects(!showAllFavProjects);
        setIsViewLessFavClicked(!showAllFavProjects);
    };

    const getDisplayedFavProjects = () => {
        if (showAllFavProjects) {
            return files?.filter((file: IUserFile) => file.isFavorite === 1) || [];
        }
        return files?.filter((file: IUserFile) => file.isFavorite === 1).slice(0, 3) || [];
    };
    const [openShareModal, setOpenShareModal] = useState<JSX.Element>();
    const [files, setFiles] = useState<IUserFile[]>([]);
    const [projectId, setProjectId] = useState<any>();
    const [loadingMessage, setLoadingMessage] = useState<string>("");
    const [loadingImage, setLoadingImage] = useState<string>("");
    const [notionFilteredComments, setNotionFilteredComments] = useState();
    const [notionFileName, setNotionFileName] = useState("");
    const [databaseId, setDatabaseId] = useState("");
    const [notionBannerOpen, setNotionBannerOpen] = useState(true);
    const [succesModelOpen, setSuccessModelOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openConsentModal, setopenConsentModal] = useState(false);
    const [drawerContent, setDrawerContent] = useState<JSX.Element>();
    const [confirmDialog, setConfirmDialog] = useState<JSX.Element>();
    const [searchText, setSearchText] = useState("" as string);
    const successModelActionButtons: ActionButtonType = {
        label: "Let's Start Working",
        onClick: () => {
            setSuccessModelOpen(false);
        },
        variant: "contained",
    };
    // let countries: readonly CountryType[] = [];

    const { mutate: markAsFavourite, data: favouriteResponse } = useCommonPost({
        title: "Favourite Project",
        alertRequired: true,
    });
    /*
        Sync comments
        if datbase exists then we need to create database
        else useUpdateDatabase
    */

    const { syncComments } = useSyncComments(props);
    const {
        mutate: syncCommentsExecute,
        isSuccess: isCommentsSuccess,
        isLoading: syncingComments,
    } = syncComments;

    const {
        mutate: getComments,
        data: getCommentsData,
        isLoading: fetchingComments,
    } = useFetchCommentsForDashboard(props);

    const { databaseExist } = useCheckDatabaseExist(props);

    const { mutate: checkDatabaseExist, data: databaseExistResponse } = databaseExist;

    const { createDatabase } = useCreateDatabase(props);

    const {
        mutate: notionDatabaseCreate,
        isLoading: creatingNotionDatabase,
        isSuccess: isNotionDatabaseCreated,
    } = createDatabase;

    const { deleteProject } = useDeleteProject();

    const { mutate: mutateDeleteProject } = deleteProject;

    const { disconnectProject } = useProjectDisconnect(props);

    const { mutate: disconnectNotionProjectMutation, isSuccess: isNotionProjectDisconnected } =
        disconnectProject;

    const { connectProject } = useProjectConnect(props);

    const {
        mutate: connectNotionProjectMutation,
        isSuccess: isConnectedNotionProject,
        isLoading: loadingNotion,
    } = connectProject;

    const { updateDatabase } = useUpdateDatabase(props);

    const {
        mutate: notionDatabaseUpdate,
        isLoading: updatingNotionDatabase,
        isSuccess: notionDatabaseUpdateSuccess,
    } = updateDatabase;

    const handleConnectProjectWithNotion = (item: any) => {
        setConfirmDialog(
            <EcDialog
                title="Connect Project"
                description="Are you sure you want to Connect the project."
                open
                actionButtons={[
                    {
                        label: "Cancel",
                        onClick: () => {
                            setConfirmDialog(undefined);
                        },
                        variant: "outlined",
                    },
                    {
                        label: "Yes, I'm Sure",
                        onClick: () => {
                            connectNotionProjectMutation(item.exportedFileId);
                            setConfirmDialog(undefined);
                        },
                        variant: "contained",
                    },
                ]}
                onClose={() => {
                    setConfirmDialog(undefined);
                }}
            />
        );
        setProjectId(item.exportedFileId);
    };

    const handleDisconnectProjectWithNotion = (item: any) => {
        setConfirmDialog(
            <EcDialog
                title="Disconnect Project"
                description=" You will no longer able to sync your comments from this project to Notion. Are you sure you want to disconnect the project."
                open
                actionButtons={[
                    {
                        label: "Cancel",
                        onClick: () => {
                            setConfirmDialog(undefined);
                        },
                        variant: "outlined",
                    },
                    {
                        label: "Yes, I'm Sure",
                        onClick: () => {
                            disconnectNotionProjectMutation(item.exportedFileId);
                            setConfirmDialog(undefined);
                        },
                        variant: "contained",
                    },
                ]}
                onClose={() => setConfirmDialog(undefined)}
            />
        );
        setProjectId(item.exportedFileId);
    };

    const [openSyncCommentsModel, setOpenSyncCommentsModel] = useState(false);
    const syncCommentModelhandleOpen = () => setOpenSyncCommentsModel(true);
    const syncCommentModelhandleClose = () => setOpenSyncCommentsModel(false);

    const actionButtons: ActionButtonType = {
        label: "Let's Start Working",
        onClick: () => {
            syncCommentModelhandleClose();
        },
        variant: "contained",
    };
    useEffect(() => {
        if (user.acceptTermsOfUseAndPrivacyPolicy === 0) {
            setopenConsentModal(true);
        } else {
            setopenConsentModal(false);
        }
    }, [user.acceptTermsOfUseAndPrivacyPolicy]);

    useEffect(() => {
        if (databaseExistResponse) {
            if (databaseExistResponse?.data.data[0].notionDatabaseId) {
                setDatabaseId(databaseExistResponse?.data.data[0].notionDatabaseId);
            } else if (projectId && notionFileName && notionFilteredComments) {
                notionDatabaseCreate({
                    projectId,
                    notionFileName,
                    figmaUrl,
                    notionFilteredComments,
                });
            }
        }
    }, [databaseExistResponse]);

    useEffect(() => {
        if (location.state === "success") {
            setSuccessModelOpen(true);
            history.replace({ state: {} });
        }
        if (location.state === "page-not-selected") {
            if (props.snackbarShowMessage) {
                props.snackbarShowMessage("Export Comments Page Not Selected", "error");
            }
            history.replace({ state: {} });
        }
    }, [location]);

    useEffect(() => {
        if (
            databaseId !== "" &&
            projectId &&
            databaseId &&
            notionFileName &&
            notionFilteredComments
        ) {
            notionDatabaseUpdate({
                projectId,
                notionFileName,
                databaseId,
                figmaUrl,
                notionFilteredComments,
            });
        }
    }, [databaseId]);

    useEffect(() => {
        if (isNotionDatabaseCreated || notionDatabaseUpdateSuccess) {
            syncCommentModelhandleOpen();
        }
    }, [isNotionDatabaseCreated, notionDatabaseUpdateSuccess]);

    useEffect(() => {
        if (isCommentsSuccess) {
            syncCommentModelhandleOpen();
            setProjectId(null);
        }
    }, [isCommentsSuccess]);

    useEffect(() => {
        if (getCommentsData && projectId) {
            const originalComments = getCommentsData.data.comments.comments.comments;
            const { filteredComments, fileInfoData } = exportCommentsToNotion(
                originalComments,
                "total",
                user,
                getCommentsData.data.comments.fileInfo
            );
            const fileName =
                fileInfoData.createdAt === undefined || fileInfoData.createdAt === null
                    ? fileInfoData.data.comments.fileInfo.fileName
                    : fileInfoData.fileName;

            setNotionFilteredComments(filteredComments);
            setNotionFileName(fileName);

            checkDatabaseExist(projectId);
        } else if (getCommentsData) {
            exportCommentsToExcel(
                getCommentsData.data.comments.comments.comments,
                "total",
                user,
                getCommentsData.data.comments.fileInfo
            );
        }
    }, [getCommentsData]);

    useEffect(() => {
        if (favouriteResponse) {
            projectListRefetch();
        }
    }, [favouriteResponse]);

    useEffect(() => {
        if (isConnectedNotionProject) {
            projectListRefetch();
        }
    }, [isConnectedNotionProject]);

    useEffect(() => {
        if (isNotionProjectDisconnected) {
            projectListRefetch();
        }
    }, [isNotionProjectDisconnected]);

    // sync comments
    const handleExportProjectToNotion = (item: any) => {
        setProjectId(item.exportedFileId);
        syncCommentsExecute({ projectId: item.exportedFileId });
    };

    const handleRemoveProject = (exportedFileId: any) => {
        setConfirmDialog(
            <EcDialog
                title="Remove Project"
                description="Are you sure you want to remove the project."
                open
                actionButtons={[
                    {
                        label: "Cancel",
                        onClick: () => {
                            setConfirmDialog(undefined);
                        },
                        variant: "outlined",
                    },
                    {
                        label: "Yes, I'm Sure",
                        onClick: () => {
                            mutateDeleteProject(exportedFileId);
                            setConfirmDialog(undefined);
                        },
                        variant: "contained",
                    },
                ]}
                onClose={() => {
                    setConfirmDialog(undefined);
                }}
            />
        );
    };

    const handleMarkAsFavourite = (item: any, favId: any) => {
        const obj: IFavoriteRequestType = {} as IFavoriteRequestType;
        obj.projectId = parseInt(item, 10);
        obj.status = parseInt(favId, 10) ? 0 : 1;
        markAsFavourite({
            body: obj,
            url: APP_CONSTANT.webApi.userFavorite,
        });
    };

    const openAllComments = (item: any) => {
        const comments = "/comments";
        const file = "../project/";
        history.push({
            pathname: `${file}${item.exportedFileId}${comments}`,
            state: "total",
        });
    };

    const openResolvedComments = (item: any) => {
        const comments = "/comments";
        const file = "../project/";
        history.push({
            pathname: `${file}${item.exportedFileId}${comments}`,
            state: "resolved",
        });
    };
    const openUnresolvedComments = (item: any) => {
        const comments = "/comments";
        const file = "../project/";
        history.push({
            pathname: `${file}${item.exportedFileId}${comments}`,
            state: "unResolved",
        });
    };
    const handleOpenUnreadComments = (item: any) => {
        const comments = "/comments";
        const file = "../project/";
        history.push({
            pathname: `${file}${item.exportedFileId}${comments}`,
            state: "unreadComments",
        });
    };
    const openOwnComments = (item: any) => {
        const comments = "/comments";
        const file = "../project/";
        history.push({
            pathname: `${file}${item.exportedFileId}${comments}`,
            state: "own",
        });
    };

    const handleDownloadExcel = (item: any) => {
        getComments({
            body: { projectId: item.exportedFileId },
            url: APP_CONSTANT.webApi.comments,
        });
    };

    const handleCloseNotionBanner = () => {
        saveDoItLaterState(true);
        setDoItLater(true);
        setNotionBannerOpen(false);
    };

    const handleAddNewProject = () => {
        setDrawerContent(<AddProjectDrawer onClose={() => setDrawerContent(undefined)} />);
    };

    const debounceFilterProjects = useDebounce(() => {
        if (projectList)
            setFiles(
                projectList.filter((file: IUserFile) =>
                    file.fileName.toString().toLowerCase().includes(searchText.trim().toLowerCase())
                )
            );
    }, 500);

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        debounceFilterProjects();
    };

    const handleViewPrototypeLink = (item: any) => {
        history.push({
            pathname: `../project/${item.exportedFileId}/comments`,
            state: "prototypes",
        });
    };

    const { startDate, endDate } = getFilterDateRange({ filter, ...range });
    const {
        data: projectList,
        isLoading: projectListLoading,
        refetch: projectListRefetch,
    } = useFetchProjectList({
        startDate,
        endDate,
        enabled: !!startDate && !!endDate,
        key: !!startDate && !!endDate ? `${startDate}-to-${endDate}` : `all`,
    });
    const { data: userAllProjects } = useFetchProjectList({
        key: `all`,
    });

    useEffect(() => {
        setLoadingMessage(getLoadingMessage("project"));
        setLoadingImage(getLoadingImage("project"));
        if (projectList) {
            setFileList(projectList);
            setFiles(projectList);
        }
    }, [projectList]);

    return (
        <>
            {projectList && !fetchingComments && (
                <StickyHeader style={{ backgroundColor: "#f5f5f5" }}>
                    <Typography
                        sx={{
                            fontFamily: "Roboto",
                            fontSize: "30px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "48px",
                        }}
                    >
                        Projects
                    </Typography>

                    <FilterProjects data={userAllProjects} />

                    <ProjectSearch
                        placeholder="Search"
                        value={searchText}
                        searchChange={onSearch}
                    />
                </StickyHeader>
            )}

            <Container>
                <EcModal
                    icon={<CheckIcon sx={{ fontSize: 60, color: "#00ca5f" }} />}
                    open={openSyncCommentsModel}
                    onClose={syncCommentModelhandleClose}
                    title="Comments Synced Successfully"
                    subtitle="You have synced the comments to your Notion account successfully. You can see these comments in the Notion "
                    actionButtons={actionButtons}
                />
                <EcModal
                    icon={<CheckIcon sx={{ fontSize: 60, color: "#00ca5f" }} />}
                    open={succesModelOpen}
                    onClose={() => setSuccessModelOpen(false)}
                    title="Connected successfully with Notion"
                    subtitle="Now your comments data can be effortlessly synced from the Export Comments Dashboard to Notion."
                    actionButtons={successModelActionButtons}
                />
                {(projectListLoading || fetchingComments) && (
                    <Loader message={loadingMessage} image={loadingImage} page="project" />
                )}
                <GridWrapper className="" style={{ zIndex: -2 }}>
                    {projectList &&
                        projectList?.length !== 0 &&
                        !projectListLoading &&
                        !fetchingComments && (
                            <>
                                {user?.notionConnected === false &&
                                    !doItLater &&
                                    notionBannerOpen && (
                                        <NotionBanner
                                            onClose={() => handleCloseNotionBanner()}
                                            onOpen={() => setOpenModal(true)}
                                        />
                                    )}
                                <NotionLetsStartModal
                                    open={openModal}
                                    onClose={() => setOpenModal(!openModal)}
                                />
                                {files.filter((bookMark) => bookMark.isFavorite === 1).length >
                                    0 && (
                                    <div>
                                        {files.filter((bookMark) => bookMark.isFavorite === 1)
                                            .length ? (
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                mt="1.5rem"
                                                sx={{
                                                    alignItems: {
                                                        xs: "flex-start",
                                                        sm: "center",
                                                    },
                                                    flexDirection: {
                                                        xs: "column",
                                                        sm: "row",
                                                        md: "row",
                                                        lg: "row",
                                                        xl: "row",
                                                    },
                                                    paddingX: {
                                                        xs: "1rem",
                                                        sm: "0",
                                                    },
                                                    marginBottom: {
                                                        xs: "1.5rem",
                                                    },
                                                }}
                                            >
                                                <Box display="flex" margin="0px -8px">
                                                    <StarIcon
                                                        color="primary"
                                                        style={{
                                                            paddingBottom: "10px",
                                                            fontSize: "34px",
                                                        }}
                                                    />
                                                    <h3
                                                        style={{
                                                            paddingTop: "3px",
                                                            zIndex: -2,
                                                        }}
                                                    >
                                                        Favourite (
                                                        {
                                                            files?.filter(
                                                                (bookMark) =>
                                                                    bookMark.isFavorite === 1
                                                            ).length
                                                        }
                                                        )
                                                    </h3>
                                                </Box>

                                                <Button
                                                    variant="text"
                                                    onClick={toggleShowAllFavProjects}
                                                    disabled={
                                                        files?.filter(
                                                            (bookMark) => bookMark.isFavorite === 1
                                                        ).length <= 3
                                                    }
                                                >
                                                    {isViewLessFavClicked
                                                        ? "View Less"
                                                        : "View All"}
                                                </Button>
                                            </Box>
                                        ) : (
                                            ""
                                        )}
                                        {showAllFavProjects ? (
                                            <CardOuter className="m-cards-outer">
                                                {getDisplayedFavProjects().map((item) => (
                                                    <div
                                                        className="onlyFavMarked unmark"
                                                        key={item.exportedFileId}
                                                    >
                                                        <Cards
                                                            usedIn="projectList"
                                                            projectId={projectId}
                                                            editorType={item.editorType}
                                                            item={item}
                                                            isFavorite={item.isFavorite}
                                                            onShareProject={() => {
                                                                setOpenShareModal(
                                                                    <ManageTeamModal
                                                                        projectId={
                                                                            item.exportedFileId
                                                                        }
                                                                        onClose={() =>
                                                                            setOpenShareModal(
                                                                                undefined
                                                                            )
                                                                        }
                                                                    />
                                                                );
                                                            }}
                                                            handleMarkAsFavourite={() =>
                                                                handleMarkAsFavourite(
                                                                    item.exportedFileId,
                                                                    item.isFavorite
                                                                )
                                                            }
                                                            onDeleteProject={() =>
                                                                handleRemoveProject(
                                                                    item.exportedFileId
                                                                )
                                                            }
                                                            filterType={item.fileName}
                                                            createdAt={item.createdAt}
                                                            openAllComments={() =>
                                                                openAllComments(item)
                                                            }
                                                            openResolvedComments={() =>
                                                                openResolvedComments(item)
                                                            }
                                                            openUnresolvedComments={() =>
                                                                openUnresolvedComments(item)
                                                            }
                                                            openUnreadComments={() =>
                                                                handleOpenUnreadComments(item)
                                                            }
                                                            // openNewComments={() =>
                                                            //     openNewComments(item)
                                                            // }
                                                            openOwnComments={() =>
                                                                openOwnComments(item)
                                                            }
                                                            allComments={item.allComments}
                                                            myComments={item.myComments}
                                                            newComments={item.newComments}
                                                            resolvedComments={item.resolvedComments}
                                                            notionProjectConnected={
                                                                item.notionProjectConnected
                                                            }
                                                            unresolvedComments={
                                                                item.unresolvedComments
                                                            }
                                                            handleDownloadExcel={() =>
                                                                handleDownloadExcel(item)
                                                            }
                                                            handleExportToNotion={() =>
                                                                handleExportProjectToNotion(item)
                                                            }
                                                            updatingNotionDatabase={
                                                                updatingNotionDatabase
                                                            }
                                                            creatingNotionDatabase={
                                                                creatingNotionDatabase
                                                            }
                                                            fetchingComments={fetchingComments}
                                                            syncingComments={syncingComments}
                                                            loadingFiles={projectListLoading}
                                                            // notionExport={notionExport}
                                                            onConnectProjectWithNotion={() =>
                                                                handleConnectProjectWithNotion(item)
                                                            }
                                                            onDisconnectProjectWithNotion={() =>
                                                                handleDisconnectProjectWithNotion(
                                                                    item
                                                                )
                                                            }
                                                            loadingNotion={loadingNotion}
                                                            prototypeCount={
                                                                item.prototypeCount || 0
                                                            }
                                                        />
                                                    </div>
                                                ))}
                                            </CardOuter>
                                        ) : (
                                            <CardOuter className="m-cards-outer">
                                                {getDisplayedFavProjects().map((item) => (
                                                    <div
                                                        className="onlyFavMarked unmark"
                                                        key={item.exportedFileId}
                                                    >
                                                        <Cards
                                                            usedIn="projectList"
                                                            projectId={projectId}
                                                            editorType={item.editorType}
                                                            item={item}
                                                            isFavorite={item.isFavorite}
                                                            onShareProject={() => {
                                                                setOpenShareModal(
                                                                    <ManageTeamModal
                                                                        projectId={
                                                                            item.exportedFileId
                                                                        }
                                                                        onClose={() =>
                                                                            setOpenShareModal(
                                                                                undefined
                                                                            )
                                                                        }
                                                                    />
                                                                );
                                                            }}
                                                            handleMarkAsFavourite={() =>
                                                                handleMarkAsFavourite(
                                                                    item.exportedFileId,
                                                                    item.isFavorite
                                                                )
                                                            }
                                                            onDeleteProject={() =>
                                                                handleRemoveProject(
                                                                    item.exportedFileId
                                                                )
                                                            }
                                                            filterType={item.fileName}
                                                            createdAt={item.createdAt}
                                                            openAllComments={() =>
                                                                openAllComments(item)
                                                            }
                                                            openResolvedComments={() =>
                                                                openResolvedComments(item)
                                                            }
                                                            openUnresolvedComments={() =>
                                                                openUnresolvedComments(item)
                                                            }
                                                            openUnreadComments={() =>
                                                                handleOpenUnreadComments(item)
                                                            }
                                                            // openNewComments={() =>
                                                            //     openNewComments(item)
                                                            // }
                                                            openOwnComments={() =>
                                                                openOwnComments(item)
                                                            }
                                                            allComments={item.allComments}
                                                            myComments={item.myComments}
                                                            newComments={item.newComments}
                                                            resolvedComments={item.resolvedComments}
                                                            notionProjectConnected={
                                                                item.notionProjectConnected
                                                            }
                                                            unresolvedComments={
                                                                item.unresolvedComments
                                                            }
                                                            handleDownloadExcel={() =>
                                                                handleDownloadExcel(item)
                                                            }
                                                            handleExportToNotion={() =>
                                                                handleExportProjectToNotion(item)
                                                            }
                                                            updatingNotionDatabase={
                                                                updatingNotionDatabase
                                                            }
                                                            creatingNotionDatabase={
                                                                creatingNotionDatabase
                                                            }
                                                            fetchingComments={fetchingComments}
                                                            syncingComments={syncingComments}
                                                            loadingFiles={projectListLoading}
                                                            // notionExport={notionExport}
                                                            onConnectProjectWithNotion={() =>
                                                                handleConnectProjectWithNotion(item)
                                                            }
                                                            onDisconnectProjectWithNotion={() =>
                                                                handleDisconnectProjectWithNotion(
                                                                    item
                                                                )
                                                            }
                                                            loadingNotion={loadingNotion}
                                                            prototypeCount={
                                                                item.prototypeCount || 0
                                                            }
                                                            handleViewPrototypeLink={() =>
                                                                handleViewPrototypeLink(item)
                                                            }
                                                        />
                                                    </div>
                                                ))}
                                            </CardOuter>
                                        )}
                                    </div>
                                )}
                                <div>
                                    {files.length > 0 ? (
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            mt="1.5rem"
                                            sx={{
                                                alignItems: {
                                                    xs: "flex-start",
                                                    sm: "center",
                                                },
                                                flexDirection: {
                                                    xs: "column",
                                                    sm: "row",
                                                    md: "row",
                                                    lg: "row",
                                                    xl: "row",
                                                },
                                                paddingX: {
                                                    xs: "1rem",
                                                    sm: "0",
                                                },
                                                marginBottom: {
                                                    xs: "1.5rem",
                                                },
                                            }}
                                        >
                                            <h3
                                                style={{
                                                    zIndex: -2,
                                                }}
                                            >
                                                {filter} (
                                                {
                                                    files?.filter(
                                                        (bookMark) => bookMark.isFavorite === 0
                                                    ).length
                                                }
                                                )
                                            </h3>

                                            <Button
                                                variant="text"
                                                onClick={toggleShowAllProjects}
                                                disabled={
                                                    files?.filter(
                                                        (bookMark) => bookMark.isFavorite === 0
                                                    ).length <= 3
                                                }
                                            >
                                                {isViewLessClicked ? "View Less" : "View All"}
                                            </Button>
                                        </Box>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                {showAllProjects ? (
                                    <CardOuter className="m-cards-outer">
                                        {getDisplayedProjects().map((item) => (
                                            <div className="unmark" key={item.exportedFileId}>
                                                <Cards
                                                    usedIn="projectList"
                                                    projectId={projectId}
                                                    editorType={item.editorType}
                                                    item={item}
                                                    isFavorite={item.isFavorite}
                                                    onShareProject={() => {
                                                        setOpenShareModal(
                                                            <ManageTeamModal
                                                                projectId={item.exportedFileId}
                                                                onClose={() =>
                                                                    setOpenShareModal(undefined)
                                                                }
                                                            />
                                                        );
                                                    }}
                                                    handleMarkAsFavourite={() =>
                                                        handleMarkAsFavourite(
                                                            item.exportedFileId,
                                                            item.isFavorite
                                                        )
                                                    }
                                                    filterType={item.fileName}
                                                    createdAt={item.createdAt}
                                                    openAllComments={() => openAllComments(item)}
                                                    openResolvedComments={() =>
                                                        openResolvedComments(item)
                                                    }
                                                    openUnresolvedComments={() =>
                                                        openUnresolvedComments(item)
                                                    }
                                                    openUnreadComments={() =>
                                                        handleOpenUnreadComments(item)
                                                    }
                                                    openOwnComments={() => openOwnComments(item)}
                                                    onDeleteProject={() =>
                                                        handleRemoveProject(item.exportedFileId)
                                                    }
                                                    allComments={item.allComments}
                                                    myComments={item.myComments}
                                                    newComments={item.newComments}
                                                    resolvedComments={item.resolvedComments}
                                                    notionProjectConnected={
                                                        item.notionProjectConnected
                                                    }
                                                    unresolvedComments={item.unresolvedComments}
                                                    handleDownloadExcel={() =>
                                                        handleDownloadExcel(item)
                                                    }
                                                    handleExportToNotion={() =>
                                                        handleExportProjectToNotion(item)
                                                    }
                                                    updatingNotionDatabase={updatingNotionDatabase}
                                                    creatingNotionDatabase={creatingNotionDatabase}
                                                    fetchingComments={fetchingComments}
                                                    syncingComments={syncingComments}
                                                    loadingFiles={projectListLoading}
                                                    onConnectProjectWithNotion={() =>
                                                        handleConnectProjectWithNotion(item)
                                                    }
                                                    onDisconnectProjectWithNotion={() =>
                                                        handleDisconnectProjectWithNotion(item)
                                                    }
                                                    loadingNotion={loadingNotion}
                                                    prototypeCount={item.prototypeCount || 0}
                                                    handleViewPrototypeLink={() =>
                                                        handleViewPrototypeLink(item)
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </CardOuter>
                                ) : (
                                    <CardOuter className="m-cards-outer">
                                        {getDisplayedProjects().map((item) => (
                                            <div className="unmark" key={item.exportedFileId}>
                                                <Cards
                                                    usedIn="projectList"
                                                    editorType={item.editorType}
                                                    projectId={projectId}
                                                    item={item}
                                                    isFavorite={item.isFavorite}
                                                    onShareProject={() => {
                                                        setOpenShareModal(
                                                            <ManageTeamModal
                                                                projectId={item.exportedFileId}
                                                                onClose={() =>
                                                                    setOpenShareModal(undefined)
                                                                }
                                                            />
                                                        );
                                                    }}
                                                    handleMarkAsFavourite={() =>
                                                        handleMarkAsFavourite(
                                                            item.exportedFileId,
                                                            item.isFavorite
                                                        )
                                                    }
                                                    filterType={item.fileName}
                                                    createdAt={item.createdAt}
                                                    openAllComments={() => openAllComments(item)}
                                                    openResolvedComments={() =>
                                                        openResolvedComments(item)
                                                    }
                                                    openUnresolvedComments={() =>
                                                        openUnresolvedComments(item)
                                                    }
                                                    openUnreadComments={() =>
                                                        handleOpenUnreadComments(item)
                                                    }
                                                    openOwnComments={() => openOwnComments(item)}
                                                    onDeleteProject={() =>
                                                        handleRemoveProject(item.exportedFileId)
                                                    }
                                                    allComments={item.allComments}
                                                    myComments={item.myComments}
                                                    newComments={item.newComments}
                                                    resolvedComments={item.resolvedComments}
                                                    notionProjectConnected={
                                                        item.notionProjectConnected
                                                    }
                                                    unresolvedComments={item.unresolvedComments}
                                                    handleDownloadExcel={() =>
                                                        handleDownloadExcel(item)
                                                    }
                                                    handleExportToNotion={() =>
                                                        handleExportProjectToNotion(item)
                                                    }
                                                    updatingNotionDatabase={updatingNotionDatabase}
                                                    creatingNotionDatabase={creatingNotionDatabase}
                                                    fetchingComments={fetchingComments}
                                                    syncingComments={syncingComments}
                                                    loadingFiles={projectListLoading}
                                                    onConnectProjectWithNotion={() =>
                                                        handleConnectProjectWithNotion(item)
                                                    }
                                                    onDisconnectProjectWithNotion={() =>
                                                        handleDisconnectProjectWithNotion(item)
                                                    }
                                                    loadingNotion={loadingNotion}
                                                    prototypeCount={item.prototypeCount || 0}
                                                    handleViewPrototypeLink={() =>
                                                        handleViewPrototypeLink(item)
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </CardOuter>
                                )}
                            </>
                        )}
                    {userAllProjects?.length === 0 ? (
                        <ProjectNotFound onAddNewProject={handleAddNewProject} />
                    ) : (
                        (projectList?.length === 0 || searchText.length > 0) && <NoProjectsFound />
                    )}
                </GridWrapper>

                {drawerContent && drawerContent}
                {confirmDialog && confirmDialog}
                {openShareModal && openShareModal}
                {openConsentModal && (
                    <ConsentFormModal
                        open={openConsentModal}
                        onClose={() => setopenConsentModal(!openConsentModal)}
                        showCloseIcon={false}
                    />
                )}
            </Container>
        </>
    );
});

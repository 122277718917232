import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

import ReplyCommentsDrawer from "../../../containers/Comments/ReplyCommentsDrawer";
import NotionProjectsDrawer from "../../../containers/Notion/NotionProjectsDrawer";
import { EcDrawer } from "../EcDrawer";
import { SnackBar } from "../snackbar/SnackBar";

function Drawer(props: any) {
    const {
        row,
        openDrawer,
        users,
        title,
        isComment,
        onAddOrRemoveReaction,
        onCloseDrawer,
        onCommentsDrawer,
        customeWidth,
    } = props;

    const [error, setError] = useState(false);
    const [state, setState] = useState(false);
    const [replyComment, setReplycomment] = useState("" as string);
    const [projectDisconnected, setProjectDisconnected] = useState<boolean>(false);
    const [projectConnected, setProjectConnected] = useState<boolean>(false);
    const [commentsSynced, setCommentsSynced] = useState<boolean>(false);

    const handleCloseDrawer = () => () => {
        onCloseDrawer();
    };

    const handleCommentsDrawer = (parameter: string) => () => {
        if ((replyComment.length === 0 || replyComment.trim() === "") && parameter === "submit") {
            setError(true);
        } else {
            setReplycomment("");
            onCommentsDrawer(parameter, replyComment.trim());
        }
    };
    useEffect(() => {
        setState(openDrawer);
    }, [openDrawer]);

    return (
        <>
            {projectDisconnected ? (
                <SnackBar
                    duration={5000}
                    onClose={() => {
                        setProjectDisconnected(!projectDisconnected);
                    }}
                    message="Project disconnected successfully"
                    open
                    severity="success"
                />
            ) : (
                ""
            )}
            {projectConnected ? (
                <SnackBar
                    duration={5000}
                    onClose={() => {
                        setProjectConnected(false);
                    }}
                    message="Project connected successfully"
                    open
                    severity="success"
                />
            ) : (
                ""
            )}
            {commentsSynced ? (
                <SnackBar
                    duration={5000}
                    onClose={() => {
                        setCommentsSynced(!commentsSynced);
                    }}
                    message="Comments synced successfully"
                    open
                    severity="success"
                />
            ) : (
                ""
            )}

            <EcDrawer
                open={state}
                direction="right"
                closeOnClickOutside={false}
                onClose={isComment ? handleCommentsDrawer("cancel-pop") : handleCloseDrawer()}
                title={
                    <Typography variant="h6" fontWeight="500" mb={1.5}>
                        {title || "Temporary Header"}
                    </Typography>
                }
                customeWidth={customeWidth}
            >
                {isComment ? (
                    <ReplyCommentsDrawer
                        users={users}
                        onCommentsDrawer={onCommentsDrawer}
                        onAddOrRemoveReaction={onAddOrRemoveReaction}
                        row={row}
                        handleCommentsDrawer={handleCommentsDrawer}
                        error={error}
                        setError={setError}
                        replyComment={replyComment}
                        setReplycomment={setReplycomment}
                    />
                ) : (
                    <NotionProjectsDrawer
                        users={users}
                        isComment={isComment}
                        onCloseDrawer={onCloseDrawer}
                        projectConnected={projectConnected}
                        setProjectConnected={setProjectConnected}
                        setProjectDisconnected={setProjectDisconnected}
                        setCommentsSynced={setCommentsSynced}
                        commentsSynced={commentsSynced}
                    />
                )}
            </EcDrawer>
        </>
    );
}

export default Drawer;

import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ShareIcon from "@mui/icons-material/Share";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { List, ListItem, Menu, MenuItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { first } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import branchIcon from "../../../assets/branch-icon.svg";
import branchIcons from "../../../assets/branch-icon-black.svg";
import checkCircle from "../../../assets/check_circle.svg";
import figJamIcon from "../../../assets/figjamIcon.svg";
import figmaIcon from "../../../assets/figmaIcon.svg";
import newIcon from "../../../assets/new-icon.svg";
import { ManageTeamModal } from "../../../containers";
import { UserContext } from "../../../contexts/UserContext";
import { useFetchFiles, useFetchProjectBranch } from "../../../hooks";
import { SyncCommentIcon } from "../../../utils";

const FileNameWithAction = styled.div`
    display: flex;
    align-items: self-start;
    flex-wrap: wrap;
    min-width: 45%;
    max-width: 100%;

    .heading--h4 {
        font-size: 20px;
        color: #212121;
        font-weight: 500;
        margin: 0;
        max-width: calc(100% - 5px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        span {
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.5px;
            text-align: left;
            display: block;
            color: #616161;
            position: relative;
            top: 2px;
        }
    }

    .lastdate {
        display: block;
        width: 100%;
        padding-left: 66px;
        font-size: 14px;
        font-weight: 500;
        color: #616161;
        margin-top: 5px;
        @media (max-width: 1366px) {
            padding-left: 5px;
        }
        @media (max-width: 480px) {
            padding-left: 5px;
        }
    }

    .back-arrow {
        cursor: pointer;
        line-height: 1;
    }

    .seperator {
        background-color: #9e9e9e;
        height: 23px;
        width: 1px;
        display: inline-block;
        margin: 0 12px;
    }

    .inline-listing {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;
        @media (max-width: 480px) {
            margin: 0.5rem 0;
        }
        @media (max-width: 1366px) {
            padding: 10px 0px;
        }
        &.with-seprator {
            li {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    margin-top: -7px;
                    width: 1px;
                    background-color: #9e9e9e;
                    height: 14px;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
                @media (max-width: 480px) {
                    &:first-child {
                        padding-left: 0;
                    }
                }
            }
        }
        li {
            line-height: 1;
            padding: 0 13px;

            a {
                color: #2979ff;
                text-decoration: none;
                cursor: pointer;
                &.disable {
                    color: #9e9e9e;
                    cursor: not-allowed;
                }
            }
            &:last-child {
                @media (max-width: 480px) {
                    padding: 10px 0px;
                }
            }
        }
    }
    .share-detail {
        svg {
            font-size: 1.25rem !important;
        }
    }
`;

const BookmarkBox = styled.a`
    svg {
        font-size: 1.25rem;
    }
`;

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#fff",
        color: "#212121",
        fontSize: 12,
        boxShadow: "0 2px 8px 0 rgba(0,0,0,0.25)",
        padding: "10px",
        lineHeight: "16px",
        letterSpacing: "1px",
    },
}));
interface fileInterface {
    fileName: any;
    actions: any;
    fileInfo: any;
    exportExls: any;
    handleExportToNotion: any;
    filterOptions: any;
    syncingComments: any;
    setBranchKey: any;
    selectedBranchName: any;
    setSelectedBranchName: any;
    editorType: string;
}

const useStyles: any = makeStyles({
    selectProject: {
        marginTop: "-1rem",
        color: "#2d8cff",
        paddingBottom: "0",
    },
    activeProject: {
        color: "#2d8cff !important",
        "& p": {
            color: "#2d8cff !important",
            fontWeight: "normal",
            fontSize: "0.875rem",
        },
    },
});
export const FileName = ({
    fileName,
    actions,
    fileInfo,
    exportExls,
    handleExportToNotion,
    filterOptions,
    syncingComments,
    setBranchKey,
    selectedBranchName,
    setSelectedBranchName,
    editorType,
    ...props
}: fileInterface) => {
    const { data, isFetched } = useFetchFiles(props);
    const { data: branchList } = useFetchProjectBranch(fileInfo?.projectId);

    const [branchName, setBranchName] = useState("");
    const [anchorElBranch, setAnchorElBranch] = useState<HTMLDivElement | null>(null);

    const openBranch = Boolean(anchorElBranch);
    const [projectList, setProjectList] = useState(data?.data.data);
    const classes: any = useStyles();
    const [openShareModal, setOpenShareModal] = useState<JSX.Element>();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const history = useHistory();
    const userfiles = "/projects/";

    const back = () => {
        if (history) {
            history.replace(`${userfiles}`);
        }
    };
    const { user } = UserContext();
    const action = (id: string, object: any) => () => {
        actions(id, object);
    };

    const option: any = first(filterOptions);
    const totalCount = option ? option.record : 0;
    const handleSelectProject = (index: number, id: any) => {
        setAnchorEl(null);
        history.push(`/project/${id}/comments`);
    };
    const handleSelectBranch = (index: number, id: any) => {
        setAnchorElBranch(null);
        setBranchName(id.name);
        setBranchKey(id.key);
        setSelectedBranchName(id.name);
    };
    const handleSelectMain = (id: any) => {
        setAnchorElBranch(null);
        setBranchName("");
        setSelectedBranchName("");
        setBranchKey(id);
    };
    const { projectId }: any = useParams();
    useEffect(() => {
        if (data) {
            setProjectList(data?.data?.data);
        }
    }, [projectList, projectId, setProjectList, isFetched, handleSelectProject]);

    return (
        <>
            <FileNameWithAction>
                <a className="back-arrow" role="presentation" onClick={back}>
                    <LightTooltip title="Return to Home page" placement="top" arrow>
                        <ArrowBackSharpIcon />
                    </LightTooltip>
                </a>
                <span className="seperator" />

                <List
                    style={{ display: "flex", flexDirection: "row" }}
                    disablePadding
                    className={classes.root}
                >
                    <ListItem
                        button
                        id="Select-Project"
                        aria-haspopup="listbox"
                        aria-controls="project-menu"
                        aria-label="Project"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(event: any) => {
                            setAnchorEl(event.currentTarget);
                        }}
                        className={classes.selectProject}
                        style={{ marginTop: "-0.5rem" }}
                    >
                        <img
                            src={editorType === "figma" ? figmaIcon : figJamIcon}
                            alt="icon"
                            style={{
                                marginRight: "10px",
                                height: "20px",
                                width: "20px",
                            }}
                        />
                        <h4
                            className="heading--h4"
                            style={{
                                maxWidth: "350px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                wordWrap: "break-word",
                            }}
                        >
                            {fileName}
                        </h4>
                        <ArrowDropDownIcon />
                    </ListItem>
                </List>
                <Menu
                    id="ProjectName"
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                >
                    {projectList?.map((project: any, index: any) => (
                        <MenuItem
                            key={project?.exportedFileId}
                            onClick={() => handleSelectProject(index, project?.exportedFileId)}
                            selected={parseInt(fileInfo?.projectId, 10) === project?.exportedFileId}
                        >
                            <h4
                                style={{
                                    margin: "0px 40px 0px 0px",
                                    padding: "0px",
                                    flexGrow: 1,
                                    fontWeight: "normal",
                                    maxWidth: "300px",
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                }}
                            >
                                {project?.fileName}
                            </h4>
                            {parseInt(fileInfo?.projectId, 10) === project?.exportedFileId && (
                                <img src={checkCircle} alt="checkCircle" />
                            )}
                        </MenuItem>
                    ))}
                </Menu>

                <ul className="inline-listing with-seprator">
                    <li role="presentation" onClick={action("fav", fileInfo)}>
                        <LightTooltip
                            title={
                                fileInfo?.isFavorite ? "Remove from Favourite" : "Add to Favourite"
                            }
                            placement="top"
                            arrow
                        >
                            <BookmarkBox>
                                {fileInfo?.isFavorite ? <StarIcon /> : <StarOutlineIcon />}
                            </BookmarkBox>
                        </LightTooltip>
                    </li>
                    <li>
                        <LightTooltip title="Download" placement="top" arrow>
                            <a
                                onClick={totalCount ? exportExls : null}
                                aria-hidden="true"
                                className={totalCount === 0 ? "disable" : ""}
                            >
                                <FileDownloadOutlinedIcon />
                            </a>
                        </LightTooltip>
                    </li>
                    <li
                        role="presentation"
                        onClick={() => {
                            setOpenShareModal(
                                <ManageTeamModal
                                    projectId={fileInfo.projectId}
                                    onClose={() => setOpenShareModal(undefined)}
                                />
                            );
                        }}
                    >
                        <LightTooltip title="Share" placement="top" arrow>
                            <a className="share-detail">
                                <ShareIcon />
                            </a>
                        </LightTooltip>
                    </li>
                    {user.notionConnected && fileInfo?.notionProjectConnected && totalCount ? (
                        <li role="presentation">
                            <LightTooltip
                                title={`Sync${syncingComments ? `ing` : ``} Comments With Notion`}
                                placement="top"
                                arrow
                            >
                                <a
                                    onClick={!syncingComments ? handleExportToNotion : null}
                                    aria-hidden="true"
                                >
                                    {syncingComments ? (
                                        <Box sx={{ display: "flex" }}>
                                            <CircularProgress size="1rem" />
                                        </Box>
                                    ) : (
                                        <SyncCommentIcon />
                                    )}
                                </a>
                            </LightTooltip>
                        </li>
                    ) : (
                        <li role="presentation">
                            <LightTooltip
                                title={
                                    totalCount
                                        ? "Connect this project with notion to sync comments"
                                        : "Add some comments to connect this project with notion to sync comments"
                                }
                                placement="top"
                                arrow
                            >
                                <a aria-hidden="true" className="disable">
                                    <SyncCommentIcon fill="#9e9e9e" />
                                </a>
                            </LightTooltip>
                        </li>
                    )}
                    {branchList !== undefined && branchList?.length > 0 && (
                        <li
                            role="presentation"
                            style={{
                                display: "flex",
                                alignItems: "flex-start",
                            }}
                        >
                            <img src={branchIcon} alt="branchIcon" />
                            <List disablePadding className={classes.root}>
                                <ListItem
                                    button
                                    id="Select-Branch"
                                    aria-haspopup="listbox"
                                    aria-controls="branch-menu"
                                    aria-label="Branch"
                                    aria-expanded={openBranch ? "true" : undefined}
                                    onClick={(event) => {
                                        setAnchorElBranch(event.currentTarget);
                                    }}
                                    className={classes.selectProject}
                                    disableRipple
                                    sx={{
                                        "&:hover": {
                                            background: "transparent",
                                        },
                                        height: "30px",
                                    }}
                                >
                                    {selectedBranchName ? (
                                        <Typography
                                            variant="h5"
                                            fontSize={14}
                                            fontWeight={500}
                                            style={{
                                                marginTop: "18px",
                                            }}
                                        >
                                            {selectedBranchName}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant="h5"
                                            fontSize={14}
                                            fontWeight={500}
                                            style={{
                                                marginTop: "18px",
                                            }}
                                        >
                                            Select a branch ({branchList?.length})
                                        </Typography>
                                    )}
                                    <ArrowDropDownIcon
                                        style={{
                                            marginTop: "15px",
                                        }}
                                    />
                                </ListItem>
                            </List>
                            <Menu
                                id="BranchName"
                                open={openBranch}
                                onClose={() => setAnchorElBranch(null)}
                                anchorEl={anchorElBranch}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                style={{
                                    marginTop: "10px",
                                }}
                            >
                                <button
                                    type="button"
                                    onClick={() => handleSelectMain(fileInfo.figmaFileId)}
                                    style={{
                                        width: "180px",
                                        height: "45px",
                                        padding: "5px 10px",
                                        fontSize: "14px",
                                        border: "none",
                                        borderBottom: "1px solid #b8bbbf",
                                        cursor: "pointer",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                paddingRight: "8px",
                                                fontWeight: "bold",
                                                fontSize: "14px",
                                            }}
                                        >
                                            <Typography variant="h5" fontSize={14} fontWeight={500}>
                                                {fileName}
                                            </Typography>
                                        </div>
                                        <div
                                            style={{
                                                fontSize: "12px",
                                            }}
                                        >
                                            Main
                                        </div>
                                    </div>
                                </button>
                                {branchList.map((branch, index) => (
                                    <MenuItem
                                        onClick={() => handleSelectBranch(index, branch)}
                                        selected={branch?.name === branchName}
                                        sx={{
                                            "&:hover": {
                                                background: "#EBF2FF !important",
                                            },
                                            height: "40px",
                                        }}
                                    >
                                        <Box
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <img
                                                src={branchIcons}
                                                alt="prototypeIcon"
                                                style={{
                                                    paddingRight: "10px",
                                                }}
                                            />
                                            <Typography
                                                variant="h5"
                                                fontSize={14}
                                                fontWeight={500}
                                                maxWidth={300}
                                                style={{
                                                    margin: "0px 50px 0px 0px",
                                                }}
                                            >
                                                {branch.name}
                                            </Typography>
                                        </Box>
                                        {branch?.name === selectedBranchName && (
                                            <img src={checkCircle} alt="checkCircle" />
                                        )}
                                    </MenuItem>
                                ))}
                            </Menu>
                            <img src={newIcon} alt="newIcon" />
                        </li>
                    )}
                </ul>
                <span className="lastdate">
                    Last updated on {moment(fileInfo?.createdAt).format("ll")}
                </span>
            </FileNameWithAction>
            {openShareModal && openShareModal}
        </>
    );
};

import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { usePageMutations } from "../../hooks";
import { Loader } from "../../stories/components/Loader/Loader";
import { useQueryParams } from "../../utils";

export const InviteProjectPage = () => {
    const query = useQueryParams();
    const history = useHistory();
    const token = query.get("token");
    const { verifyPage } = usePageMutations();
    const { mutate: mutateVerifyPage, data, isLoading: isVerifyPageLoading, isError } = verifyPage;
    useEffect(() => {
        mutateVerifyPage({ token });
    }, [token]);

    useEffect(() => {
        if (isError) {
            setTimeout(() => history.push("/"), 1000);
        }
    }, [isError]);

    if (data) {
        const { link } = data;
        window.location.href = link;
    }
    return <>{isVerifyPageLoading && <Loader />}</>;
};

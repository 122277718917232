import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Button, Stack, Typography } from "@mui/material";
import styled from "styled-components";

import { NotionIcon } from "../../../utils";

interface NotionBannerPropsType {
    onClose: () => void;
    onOpen: () => void;
}

const CloseModal = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
`;

const NotionBannerStyle = styled.div`
    .marginR-10 {
        margin-right: 10px;
    }

    .notion-banner {
        margin-top: 15px;
        padding: 10px;
        background: url(/wallComments.png) no-repeat 90% bottom #ebf2ff;
        border: 1px solid #2979ff;
        border-radius: 4px;
        position: relative;
    }
`;

export const NotionBanner = ({ onClose, onOpen }: NotionBannerPropsType) => (
    <NotionBannerStyle>
        <Stack direction="row" spacing={1} className="notion-banner">
            <CloseModal onClick={onClose}>
                <CloseRoundedIcon />
            </CloseModal>
            <NotionIcon />
            <Typography component="div">
                <Typography fontWeight="700" variant="h6" className="mr-4" sx={{ fontSize: 18 }}>
                    Enjoy seamless integration with Notion.
                </Typography>
                <Typography color="text.secondary" paragraph sx={{ fontSize: 14 }}>
                    Utilize our integration to view your Figma comments data on Notion.
                </Typography>

                <Button
                    className="marginR-10"
                    variant="contained"
                    onClick={onOpen}
                    id="NB-notion-integration-start"
                >
                    Let’s Start
                </Button>
                <Button variant="outlined" onClick={onClose} id="NB-notion-do-it-later-button">
                    I’ll do it later
                </Button>
            </Typography>
        </Stack>
    </NotionBannerStyle>
);

import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

import { useNotionMutation } from "../../hooks";
import { ConsentPage } from "../../pages/ConsentPage";
import { EcModal } from "../../stories/components/EcModal/EcModal";
import { ActionButtonType } from "../../types";

const StepsPanel = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        height: "auto",
        overflow: "scroll",
    },
}));

interface IConsentFormModal {
    onClose: () => void;
    open: boolean;
    showCloseIcon: boolean;
}

const ConsentFormModal = (props: IConsentFormModal) => {
    const { onClose, open } = props;
    const [isTermsCondition, setIsTermsCondition] = useState(false as boolean);
    const { acceptTermsOfUseAndPrivacyPolicy } = useNotionMutation();
    const { mutate, isSuccess, isLoading } = acceptTermsOfUseAndPrivacyPolicy;

    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess]);

    const ModalContent = () => (
        <Box sx={{ my: 2 }}>
            <StepsPanel>
                <Grid item spacing={{ xs: 4, sm: 2, md: 2 }}>
                    <Box
                        mb={2}
                        flexDirection="column"
                        height="400px"
                        style={{
                            borderBottom: "2px solid LightGrey",
                            overflow: "hidden",
                            overflowY: "scroll",
                            textAlign: "left",
                        }}
                    >
                        <ConsentPage />
                    </Box>
                </Grid>
            </StepsPanel>
            <Box
                sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                }}
            >
                <FormGroup>
                    <FormControlLabel
                        checked={isTermsCondition}
                        onChange={() => setIsTermsCondition(!isTermsCondition)}
                        control={<Checkbox />}
                        label={
                            <Box sx={{ display: "inline-flex" }}>
                                I agree to&nbsp;
                                <Link target="_blank" href="/terms-of-use">
                                    Terms of Use
                                </Link>
                                &nbsp;&amp;&nbsp;
                                <Link target="_blank" href="/privacy-policy">
                                    Privacy Policy
                                </Link>
                            </Box>
                        }
                    />
                </FormGroup>
            </Box>
        </Box>
    );
    const LetsStartActionButtons: ActionButtonType = {
        label: "Submit",
        onClick: () => mutate(),
        variant: "contained",
        disabled: !isTermsCondition || isLoading,
    };
    return (
        <EcModal
            size="md"
            open={open}
            onClose={onClose}
            title="Privacy Policy"
            actionButtons={LetsStartActionButtons}
            showCloseIcon={false}
        >
            <ModalContent />
        </EcModal>
    );
};

export { ConsentFormModal };

import { createContext, useLayoutEffect, useState } from "react";

import { SizeContextType } from "../types";

export const SizeContext = createContext({} as SizeContextType);

export const SizeContextProvider = ({ children }: any) => {
    const [width, setWidth] = useState<number>(0);
    const value = {
        width,
        setWidth,
    };

    useLayoutEffect(() => {
        function updateWidth() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", updateWidth);
        updateWidth();
        return () => window.removeEventListener("resize", updateWidth);
    }, []);

    return <SizeContext.Provider value={value}>{children}</SizeContext.Provider>;
};

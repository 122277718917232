import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { PrototypeSearch } from "../../../containers/Prototype/PrototypeSearch";
import { FileName } from "../file-name/FileName";
import { FilterDropdown } from "../filter-dropdown/FilterDropdown";
import { Search } from "../search/Search";

const SubHeaderDiv = styled.section`
    display: flex;
    align-items: end;
    justify-content: space-between;
    width: 100%;
    padding-inline: 20px;
    padding-block: 23px 10px;
    box-sizing: border-box;
    .seperator {
        background-color: #9e9e9e;
        height: 23px;
        width: 1px;
        display: inline-block;
        margin: 0 12px;
    }
    .align-center {
        align-items: center;
    }
    .filter-w-search {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: end;
        min-width: 55%;
        max-width: 100%;
    }

    .mobilesearch-outer {
        display: none;
        width: 100%;
        position: relative;
        margin-top: 5px;

        .search-input {
            width: 100%;
            padding-right: 30px;
            border: none;
            box-shadow: 0 1px 0 #616161;
            -webkit-box-shadow: 0 1px 0 #616161;
            padding: 8px 4px;

            &:hover {
                box-shadow: 0 2px 0 #616161;
                -webkit-box-shadow: 0 2px 0 #616161;
            }
            &:active,
            &:focus {
                box-shadow: 0 2px 0 #2979ff;
                -webkit-box-shadow: 0 2px 0 #2979ff;
            }
            &::placeholder {
                color: #9e9e9e;
            }
            &::-webkit-input-placeholder {
                color: #9e9e9e;
            }
            &:-ms-input-placeholder {
                color: #9e9e9e;
            }
        }

        .search-inline-ic {
            position: absolute;
            right: 5px;
            z-index: 1;
            top: calc(50% - 12px);
        }

        &.open {
            display: block;
        }
    }

    @media screen and (max-width: 1210px) {
        &.sub-header .filter-w-search {
            flex-wrap: wrap;
        }
        &.sub-header .filter-w-search .seperator {
            display: none;
        }
        &.sub-header .filter-w-search .w-287 {
            width: 330px;
        }
    }

    @media screen and (max-width: 1023px) {
        &.sub-header {
            align-items: flex-start !important;
        }
    }

    @media screen and (max-width: 767px) {
        &.sub-header {
            flex-wrap: wrap;
            .m-640 {
                min-width: 100%;
            }
            .filter-w-search {
                min-width: 100%;
                flex-wrap: nowrap;
                margin-top: 15px;
                margin-bottom:5px;
                justify-content: flex-start;
                .w-287 {
                    width: 100%;
                }
                .searchwrap.w-287 {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 380px) {
        .filter-w-search .muicustSelect .MuiSelect-select {
                font-size: 13px !important;
            }
        }
    }
`;

export const SubHeader = ({
    fileName,
    filterType,
    searchText,
    searchChange,
    filterOptions,
    fileInfo,
    handleFilterClick,
    actions,
    exportExls,
    handleExportToNotion,
    handleMarkAsReadClick,
    syncingComments,
    aligment,
    setBranchKey,
    selectedBranchName,
    setSelectedBranchName,
    editorType,
}: any) => {
    const [mobSearch, setMobSearch] = useState(false);
    const inputRef = useRef(null as any);
    useEffect(() => {
        inputRef.current.focus();
    }, [searchText]);

    return (
        <SubHeaderDiv className="sub-header">
            <FileName
                fileName={fileName}
                editorType={editorType}
                actions={actions}
                fileInfo={fileInfo}
                exportExls={exportExls}
                handleExportToNotion={handleExportToNotion}
                filterOptions={filterOptions}
                syncingComments={syncingComments}
                setBranchKey={setBranchKey}
                selectedBranchName={selectedBranchName}
                setSelectedBranchName={setSelectedBranchName}
            />
            <div className="filter-w-search">
                {aligment === "comments" && (
                    <>
                        <FilterDropdown
                            filterOptions={filterOptions}
                            handleFilterClick={handleFilterClick}
                            filterType={filterType}
                            handleMarkAsReadClick={handleMarkAsReadClick}
                        />
                        <span className="seperator" />
                    </>
                )}
                {aligment === "comments" ? (
                    <Search
                        placeholder="Search"
                        value={searchText}
                        searchChange={searchChange}
                        mobSearch={mobSearch}
                        setMobSearch={setMobSearch}
                        handleMarkAsReadClick={handleMarkAsReadClick}
                    />
                ) : (
                    <PrototypeSearch
                        placeholder="Search"
                        value={searchText}
                        searchChange={searchChange}
                    />
                )}
            </div>
            <div className={mobSearch ? "mobilesearch-outer open" : "mobilesearch-outer"}>
                <SearchRoundedIcon className="search-inline-ic" />
                <input
                    ref={inputRef}
                    value={searchText}
                    onChange={(e: any) => searchChange(e)}
                    className="search-input"
                    placeholder="Search"
                    type="text"
                />
            </div>
        </SubHeaderDiv>
    );
};

import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { cloneDeep, sortBy as sort } from "lodash";
import moment from "moment";
import { SortColumn } from "react-data-grid";

import eyesEmoji from "../assets/eyes.png";
import fireEmoji from "../assets/fire.png";
import heartEyesEmoji from "../assets/frame.png";
import tearOfJoyEmoji from "../assets/tearOfJoy.png";
import thumbDownEmoji from "../assets/thumbsDown.png";
import thumbUpEmoji from "../assets/thumbsUp.png";
import plusEmoji from "../assets/Vector.png";
import { APP_CONSTANT } from "../constants/AppConstant";
import {
    FigmaUserType,
    ITreeCommentResponse,
    ITreeRowType,
    IUserToken,
    ReactionActionType,
    ReactionType,
} from "../types";

const exportCommentsToExcel = (
    originalComments: any,
    filterType: any,
    user: any,
    commentsFileInfo: any
) => {
    const fileInfoData = { ...commentsFileInfo };
    const exportComments = [...originalComments];
    let checkComment = true;
    exportComments.forEach((comment) => {
        if (
            new Date(comment.createdAt) >
            new Date(
                fileInfoData.createdAt === undefined || fileInfoData.createdAt === null
                    ? fileInfoData.data.comments.fileInfo.createdAt
                    : fileInfoData.createdAt
            )
        ) {
            checkComment = true;
        } else {
            checkComment = false;
        }
        // eslint-disable-next-line no-unneeded-ternary
        comment.new = checkComment ? true : false;
    });
    /* sorting comments */
    exportComments.sort((x, y) => {
        const firstDate: any = new Date(x.createdAt);
        const secondDate: any = new Date(y.createdAt);
        return firstDate - secondDate;
    });
    /* sorting comments ends */

    const parentComments: any = [];
    const childComments: any = [];
    const childCommentIds: any = [];
    const parentCommentIds: any = [];
    const parentOrderIds: any = [];

    exportComments.forEach((value: any) => {
        value.group = 0;
        if (value.parentId !== "") {
            childComments.push(value);
            childCommentIds.push(value.id);
        } else {
            parentComments.push(value);
            parentCommentIds.push(value.id);
            parentOrderIds.push(value.orderId);
        }
    });

    const allComments: any = [];
    parentComments.forEach((value: any) => {
        allComments.push(value);
    });

    childComments.sort((x: any, y: any) => {
        const firstDate: any = new Date(x.createdAt);
        const secondDate: any = new Date(y.createdAt);
        return secondDate - firstDate;
    });

    const parentGroupArray: any = [];
    let groupLevel = 0;
    childComments.forEach((value: any, index: any) => {
        const parentIndex = parentCommentIds.indexOf(value.parentId);
        const groupIndex = parentGroupArray.indexOf(value.parentId);
        if (index === 0) {
            // if first comment
            groupLevel = 1;
        }

        if (groupIndex === -1) {
            groupLevel = parentGroupArray.length + 1;
            parentGroupArray.push(value.parentId);
        } else {
            groupLevel = groupIndex + 1;
        }
        value.group = groupLevel;
        allComments.splice(parentIndex + 1, 0, value);
        parentCommentIds.splice(parentIndex + 1, 0, value.comment_id); // check this response object
        parentOrderIds.splice(parentIndex + 1, 0, value.orderId);
    });

    let filteredComments: any = [];
    const commentList = [...originalComments];

    switch (filterType) {
        case "total":
            filteredComments = allComments;
            break;
        case "resolved": {
            const resolvedParentCommentIds: any = [];
            allComments.forEach((value: any) => {
                if (value.parentId === "" && value.resolvedAt != null) {
                    resolvedParentCommentIds.push(value.id);
                    filteredComments.push(value);
                } else if (resolvedParentCommentIds.indexOf(value.parentId) !== -1) {
                    filteredComments.push(value);
                }
            });
            break;
        }
        case "unResolved": {
            const unresolvedParentCommentIds: any = [];
            allComments.forEach((value: any) => {
                if (value.parentId === "" && value.resolvedAt == null) {
                    unresolvedParentCommentIds.push(value.id);
                    filteredComments.push(value);
                } else if (unresolvedParentCommentIds.indexOf(value.parentId) !== -1) {
                    filteredComments.push(value);
                }
            });
            break;
        }
        case "unreadReplies": {
            filteredComments = getAllUnreadReplies(
                allComments,
                fileInfoData.data.comments.fileInfo
            ).filteredRows;
            break;
        }
        case "unreadComments": {
            filteredComments = getAllUnreadComments(
                allComments,
                fileInfoData.data.comments.fileInfo
            ).filteredRows;
            break;
        }

        case "my": {
            const myCommentIdList: any = [];
            if (user != null) {
                commentList.forEach((value) => {
                    if (user.id === value.user.id) {
                        myCommentIdList.push(value.id);
                        if (
                            value.parent_id !== "" &&
                            myCommentIdList.indexOf(value.parentId) === -1
                        ) {
                            myCommentIdList.push(value.parentId);
                        }
                    }
                });

                allComments.forEach((value: any) => {
                    if (myCommentIdList.indexOf(value.id) !== -1) {
                        filteredComments.push(value);
                    }
                });
            }
            break;
        }
        default:
            filteredComments = allComments;
            break;
    }

    /* excel export using exceljs */
    const ExcelJSWorkbook = new ExcelJS.Workbook();
    const filename =
        fileInfoData.createdAt === undefined || fileInfoData.createdAt === null
            ? fileInfoData.data.comments.fileInfo.fileName
            : fileInfoData.fileName;
    const worksheet: any = ExcelJSWorkbook.addWorksheet(filename);
    worksheet.headerFooter.differentFirst = true;
    worksheet.properties.outlineProperties = {
        summaryBelow: false,
    };
    worksheet.columns = [
        { header: "", key: "comment_number", width: 20 },
        { header: "", key: "created_by", width: 20 },
        { header: filename, key: "comment", width: 50 },
        { header: "", key: "comment_location", width: 20 },
        { header: "", key: "status", width: 20 },
        { header: "", key: "resolved_at", width: 20 },
        { header: "", key: "created_at", width: 20 },
        { header: "", key: "new", width: 20 },
        { header: "", key: "my", width: 20 },
    ];
    worksheet.mergeCells("A1:I1");
    worksheet.getCell("I1").value = `(${filename})`;
    worksheet.getCell("I1").fill = {
        type: "pattern",
        pattern: "darkVertical",
        fgColor: { argb: "FFC9DAF8" },
    };

    worksheet.addRow([
        "Comment Number",
        "Created By",
        "Comment",
        "Comment Location",
        "Resolved At",
        "Created At",
        "Unread Comment",
        "Unread Reply",
        "My Comment",
    ]);
    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(2).font = { bold: true };

    worksheet.columns.forEach((col: any) => {
        worksheet.getCell(col.letter + 2).fill = {
            type: "pattern",
            pattern: "darkVertical",
            fgColor: { argb: "FFC9DAF8" },
        };
    });
    const keys = [
        "orderId",
        "user_name",
        "message",
        "comment_location",
        "resolvedAt",
        "createdAt",
        "unreadComment",
        "unreadReply",
        "my",
    ];

    worksheet.getColumn("C").alignment = { wrapText: true };
    worksheet.getColumn("D").alignment = {
        wrapText: true,
        horizontal: "center",
    };
    worksheet.getColumn("H").alignment = { horizontal: "center" };
    worksheet.getColumn("I").alignment = { horizontal: "center" };
    worksheet.getRow(1).alignment = { horizontal: "center" };
    worksheet.getRow(2).alignment = { horizontal: "center" };

    const resolvedArray: any = [];
    const myCommentArray: any = [];
    const unreadCommentArray: any = [];
    const unreadReplyCommentArray: any = [];
    const groupColorArray: any = [];
    const linksArray: any = [];
    const groupColorIndexArray: any = [];
    let lastGroup = 0;
    filteredComments.forEach((object: any, index: any) => {
        const valueArray: any = [];
        let isMyComment = false;
        let isUnreadComment = false;
        let isUnreadReplyComment = false;
        const is_resolved = false;
        keys.forEach((key) => {
            if (key === "user_name") {
                const user_name = object.user.handle;
                valueArray.push(user_name);
            } else if (key === "comment_location") {
                if (object.parentId === "") {
                    valueArray.push("");
                    linksArray.push({
                        index: index + 3,
                        link: `https://www.figma.com/file/${object.fileKey}?node-id=${object.clientMeta.nodeId}#${object.id}`,
                    });
                } else {
                    valueArray.push("");
                }
            } else if (key === "resolvedAt") {
                if (!object[key]) valueArray.push("");
                else valueArray.push(moment(object[key]).format("MM/D/YYYY"));
            } else if (key === "createdAt") {
                if (!object[key]) valueArray.push("");
                else valueArray.push(moment(object[key]).format("MM/D/YYYY"));
            } else if (key === "unreadComment") {
                isUnreadComment = !!object[key];
                valueArray.push(isUnreadComment ? "Y" : "");
            } else if (key === "unreadReply") {
                isUnreadReplyComment = !!object[key];
                valueArray.push(isUnreadReplyComment ? "Y" : "");
            } else if (key === "my") {
                isMyComment = user.figmaUserId === object.user.id;
                valueArray.push(isMyComment ? "Y" : "");
            } else {
                const value = object[key] !== null && object[key] !== "" ? object[key] : "";
                valueArray.push(value);
            }
        });

        worksheet.addRow(valueArray);
        if (is_resolved) {
            resolvedArray.push(`E${index + 3}`);
        }

        if (isUnreadComment) {
            unreadCommentArray.push(`G${index + 3}`);
        }

        if (isUnreadReplyComment) {
            unreadReplyCommentArray.push(`H${index + 3}`);
        }

        if (isMyComment) {
            myCommentArray.push(`I${index + 3}`);
        }

        if (object.group > 0) {
            const groupColorIndex = groupColorIndexArray.indexOf(object.group);
            let cellArray: any = [];
            const cellPreviousArray: any = [];
            const avoidCellArray = ["E", "H", "I"];
            worksheet.columns.forEach((col: any) => {
                if (avoidCellArray.indexOf(col.letter) === -1) {
                    if (lastGroup === 0 && lastGroup !== object.group) {
                        cellPreviousArray.push(col.letter + (3 + index - 1));
                    }
                    cellArray.push(col.letter + (3 + index));
                    cellArray = cellPreviousArray.concat(cellArray);
                }
            });
            if (groupColorIndex === -1) {
                groupColorIndexArray.push(object.group);
                groupColorArray.push({
                    group: object.group,
                    cells: cellArray,
                });
            } else {
                groupColorArray[groupColorIndex].cells =
                    groupColorArray[groupColorIndex].cells.concat(cellArray);
            }
        }
        lastGroup = object.group;

        worksheet.getRow(index + 1 + 2).outlineLevel = object.group !== 0 ? 1 : 0;
    });

    groupColorArray.forEach((dataCell: any, index: any) => {
        const groupColor = (index + 1) % 2 === 0 ? "FFF2DCBB" : "FFF9F7CF";
        dataCell.cells.forEach((cell: any) => {
            worksheet.getCell(cell).fill = {
                type: "pattern",
                pattern: "darkVertical",
                fgColor: { argb: groupColor },
            };
        });
    });
    resolvedArray.forEach((value: any) => {
        worksheet.getCell(value).fill = {
            type: "pattern",
            pattern: "darkVertical",
            fgColor: { argb: "FF00CA5F" },
        };
    });
    myCommentArray.forEach((value: any) => {
        worksheet.getCell(value).fill = {
            type: "pattern",
            pattern: "darkVertical",
            fgColor: { argb: "FF18B7FB" },
        };
    });
    unreadCommentArray.forEach((value: any) => {
        worksheet.getCell(value).fill = {
            type: "pattern",
            pattern: "darkVertical",
            fgColor: { argb: "FF70D8E7" },
        };
    });
    unreadReplyCommentArray.forEach((value: any) => {
        worksheet.getCell(value).fill = {
            type: "pattern",
            pattern: "darkVertical",
            fgColor: { argb: "FF00FFBF" },
        };
    });

    linksArray.forEach((value: any) => {
        worksheet.getCell(`D${value.index}`).value = {
            text: "View In Figma",
            hyperlink: value.link,
            //  tooltip: "View In Figma",
        };
        worksheet.getCell(`D${value.index}`).font = {
            color: { argb: "FF0802FF" },
        };
    });

    worksheet.views = [{ state: "frozen", ySplit: 2, activeCell: "A3" }];
    ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/octet-stream" }), `${filename}.xlsx`);
    });
};

const exportCommentsToNotion = (
    originalComments: any,
    filterType: any,
    user: any,
    commentsFileInfo: any
) => {
    const fileInfoData = { ...commentsFileInfo };
    const exportComments = [...originalComments];
    let checkComment = true;
    exportComments.forEach((comment) => {
        if (
            new Date(comment.createdAt) >
            new Date(
                fileInfoData.createdAt === undefined || fileInfoData.createdAt === null
                    ? fileInfoData.data.comments.fileInfo.createdAt
                    : fileInfoData.createdAt
            )
        ) {
            checkComment = true;
        } else {
            checkComment = false;
        }
        comment.new = checkComment;
    });
    /* sorting comments */
    exportComments.sort((x, y) => {
        const firstDate: any = new Date(x.createdAt);
        const secondDate: any = new Date(y.createdAt);
        return firstDate - secondDate;
    });
    /* sorting comments ends */

    const parentComments: any = [];
    const childComments: any = [];
    const childCommentIds: any = [];
    const parentCommentIds: any = [];
    const parentOrderIds: any = [];

    exportComments.forEach((value: any) => {
        value.group = 0;
        if (value.parentId !== "") {
            childComments.push(value);
            childCommentIds.push(value.id);
        } else {
            parentComments.push(value);
            parentCommentIds.push(value.id);
            parentOrderIds.push(value.orderId);
        }
    });

    const allComments: any = [];
    parentComments.forEach((value: any) => {
        allComments.push(value);
    });

    childComments.sort((x: any, y: any) => {
        const firstDate: any = new Date(x.createdAt);
        const secondDate: any = new Date(y.createdAt);
        return secondDate - firstDate;
    });

    const parentGroupArray: any = [];
    let groupLevel = 0;
    childComments.forEach((value: any, index: any) => {
        const parentIndex = parentCommentIds.indexOf(value.parentId);
        const groupIndex = parentGroupArray.indexOf(value.parentId);
        if (index === 0) {
            // if first comment
            groupLevel = 1;
        }

        if (groupIndex === -1) {
            groupLevel = parentGroupArray.length + 1;
            parentGroupArray.push(value.parentId);
        } else {
            groupLevel = groupIndex + 1;
        }
        value.group = groupLevel;
        allComments.splice(parentIndex + 1, 0, value);
        parentCommentIds.splice(parentIndex + 1, 0, value.comment_id); // check this response object
        parentOrderIds.splice(parentIndex + 1, 0, value.orderId);
    });

    let filteredComments: any = [];
    const commentList = [...originalComments];

    switch (filterType) {
        case "total":
            filteredComments = allComments;
            break;
        case "resolved": {
            const resolvedParentCommentIds: any = [];
            allComments.forEach((value: any) => {
                if (value.parentId === "" && value.resolvedAt != null) {
                    resolvedParentCommentIds.push(value.id);
                    filteredComments.push(value);
                } else if (resolvedParentCommentIds.indexOf(value.parentId) !== -1) {
                    filteredComments.push(value);
                }
            });
            break;
        }
        case "unResolved": {
            const unresolvedParentCommentIds: any = [];
            allComments.forEach((value: any) => {
                if (value.parentId === "" && value.resolvedAt == null) {
                    unresolvedParentCommentIds.push(value.id);
                    filteredComments.push(value);
                } else if (unresolvedParentCommentIds.indexOf(value.parentId) !== -1) {
                    filteredComments.push(value);
                }
            });
            break;
        }
        case "unreadReplies": {
            allComments.forEach((comment: ITreeRowType) => {
                if (
                    comment.parentId !== "" &&
                    new Date(comment.createdAt) > new Date(fileInfoData.createdAt)
                ) {
                    filteredComments.push(comment);
                }
            });
            break;
        }
        case "unreadComments": {
            allComments.forEach((comment: ITreeRowType) => {
                if (
                    comment.parentId === "" &&
                    new Date(comment.createdAt) > new Date(fileInfoData.createdAt)
                ) {
                    filteredComments.push(comment);
                }
            });

            break;
        }

        case "my": {
            const myCommentIdList: any = [];
            if (user != null) {
                commentList.forEach((value) => {
                    if (user.id === value.user.id) {
                        myCommentIdList.push(value.id);
                        if (
                            value.parent_id !== "" &&
                            myCommentIdList.indexOf(value.parentId) === -1
                        ) {
                            myCommentIdList.push(value.parentId);
                        }
                    }
                });

                allComments.forEach((value: any) => {
                    if (myCommentIdList.indexOf(value.id) !== -1) {
                        filteredComments.push(value);
                    }
                });
            }
            break;
        }
        default:
            filteredComments = allComments;
            break;
    }
    return { filteredComments, fileInfoData };
};

const processTreeData = (newData: ITreeRowType[], expandId: any[]): ITreeRowType[] => {
    if (!newData.length) {
        return [] as ITreeRowType[];
    }
    if (expandId.length) {
        expandId.forEach((comment: ITreeRowType) => {
            const rowIndex = newData.findIndex(
                (expandedComment: ITreeRowType) =>
                    comment.id === expandedComment.id && !comment.parentId
            );
            if (rowIndex > -1) {
                const { children } = newData[rowIndex];
                newData[rowIndex].isExpanded = true;
                newData.splice(rowIndex + 1, 0, ...children);
            }
        });
        return newData;
    }
    return newData;
};

function toggleSubRow(rows: ITreeRowType[], id: string): ITreeRowType[] {
    if (!id) {
        return rows;
    }
    const rowIndex = rows.findIndex((r) => r.id === id);
    const row = rows[rowIndex];
    const { children } = row;
    if (!children) return rows;

    const newRows = [...rows];
    newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
    if (!row.isExpanded) {
        newRows.splice(rowIndex + 1, 0, ...children);
    } else {
        newRows.splice(rowIndex + 1, children.length);
    }

    return newRows;
}

const sortingTreeData = (
    rows: ITreeRowType[],
    sortColumn: readonly SortColumn[],
    expandId: any[],
    filterText: string
) => {
    const v = sortColumn[0].direction === "ASC" ? 1 : -1;
    const key = sortColumn[0].columnKey;
    let sortedRows = rows.sort((a: any, b: any) => (a[key] > b[key] ? v : -v));
    sortedRows = processTreeData(cloneDeep(sortedRows), expandId);
    sortedRows = sortedRows.filter((comment: ITreeRowType) => rowMatched(comment, filterText));
    return sortedRows;
};
const rowMatched = (row: any, searchText: string): boolean => {
    let assumeNotMatch = false;
    Object.keys(row).forEach((key: string) => {
        if (key !== "children") {
            if (row[key] && row[key].toString().toLowerCase().includes(searchText.toLowerCase())) {
                assumeNotMatch = true;
            }
        }
    });
    return assumeNotMatch;
};

const processReplyCommnet = (
    previousComments: ITreeCommentResponse,
    newComment: ITreeRowType,
    filterType: string,
    user: IUserToken
): ITreeCommentResponse => {
    if (previousComments?.data?.comments?.originalResponse) {
        const oldData = previousComments;
        if (oldData?.data?.comments?.comments?.comments && oldData.data.comments.originalResponse) {
            oldData.data.comments.originalResponse.push(newComment);
            oldData.data.comments.comments.comments = oldData.data.comments.originalResponse;
        }
        return processCommentsData(oldData, filterType, user);
    }
    return previousComments;
};

const processDeleteComment = (
    previousComments: ITreeCommentResponse,
    currentDeletedComment: ITreeRowType,
    filterType: string,
    user: IUserToken
): ITreeCommentResponse => {
    if (previousComments?.data?.comments?.originalResponse) {
        const oldData = previousComments;
        if (oldData?.data?.comments?.comments?.comments && oldData.data.comments.originalResponse) {
            const index = oldData.data.comments.originalResponse.findIndex(
                (commnet: ITreeRowType) => commnet.id === currentDeletedComment.id
            );
            if (index > -1) {
                oldData.data.comments.originalResponse.splice(index, 1);
                oldData.data.comments.originalResponse =
                    oldData.data.comments.originalResponse.filter(
                        (comment: any) =>
                            !(comment.parentId && comment.parentId === currentDeletedComment.id)
                    );
            }

            oldData.data.comments.comments.comments = oldData.data.comments.originalResponse;
        }
        return processCommentsData(oldData, filterType, user);
    }

    return previousComments;
};

const getResolvedFilteredComments = (comments: ITreeRowType[]) => {
    const filteredComments: ITreeRowType[] = [];
    const resolvedParent: string[] = comments
        .filter((comment: ITreeRowType) => comment.resolvedAt)
        .map((comment) => comment.id);

    comments.forEach((comment: ITreeRowType) => {
        if (resolvedParent.indexOf(comment.id) !== -1) {
            filteredComments.push(comment);
        } else if (resolvedParent.indexOf(comment.parentId) !== -1) {
            filteredComments.push(comment);
        }
    });

    return {
        filteredRows: filteredComments,
    };
};

const getUnResolvedFilteredComments = (comments: ITreeRowType[]) => {
    const resolvedParent: string[] = comments
        .filter((comment: ITreeRowType) => comment.resolvedAt)
        .map((comment) => comment.id);

    const filteredComments: ITreeRowType[] = comments.filter(
        (comment: ITreeRowType) =>
            (!comment.parentId && resolvedParent.indexOf(comment.id) === -1) ||
            (comment.parentId && resolvedParent.indexOf(comment.parentId) === -1)
    );

    return {
        filteredRows: filteredComments,
    };
};

const getMyOwnComments = (comments: ITreeRowType[], userId: string) => {
    const myCommentIdList: string[] = [];
    const filteredComments: ITreeRowType[] = [];
    comments.forEach((comment: ITreeRowType) => {
        if (userId === comment.user.id) {
            myCommentIdList.push(comment.id);
            if (comment.parentId !== "" && myCommentIdList.indexOf(comment.parentId) === -1) {
                myCommentIdList.push(comment.parentId);
            }
        }
    });
    comments.forEach((comment: ITreeRowType) => {
        if (myCommentIdList.indexOf(comment.id) !== -1) {
            filteredComments.push(comment);
        }
    });
    return {
        filteredRows: filteredComments,
    };
};

const getAllComments = (comments: ITreeRowType[]) => ({
    filteredRows: comments,
});
const getAllUnreadComments = (comments: ITreeRowType[], fileInfo: any) => {
    let filteredComments: ITreeRowType[] = [];
    let commentsCount = 0 as number;

    // For identifying all replies corresponding to comments
    const commentsMap = new Map<string, ITreeRowType[]>();
    comments.forEach((comment: ITreeRowType) => {
        if (comment.parentId !== "") {
            commentsMap.set(comment.parentId, [
                ...(commentsMap.get(comment.parentId) || []),
                {
                    ...comment,
                    unreadReply: new Date(comment.createdAt) > new Date(fileInfo.createdAt),
                },
            ]);
        }
    });

    comments.forEach((comment: ITreeRowType) => {
        if (comment.parentId === "" && new Date(comment.createdAt) > new Date(fileInfo.createdAt)) {
            commentsCount += 1;
            comment.unreadComment = true;
            filteredComments.push(comment);
            if (commentsMap.has(comment.id))
                filteredComments = [...filteredComments, ...(commentsMap.get(comment.id) || [])];
        }
    });

    return {
        filteredRows: filteredComments,
        count: commentsCount,
    };
};
const getAllUnreadReplies = (comments: ITreeRowType[], fileInfo: any) => {
    const filteredComments: any = [];
    let repliesCount = 0 as number;

    // For identifying all comments corresponding to replies
    const commentsMap = new Map<string, ITreeRowType>();
    comments.forEach((comment: ITreeRowType) => {
        if (comment.parentId === "") {
            commentsMap.set(comment.id, {
                ...comment,
                unreadComment: new Date(comment.createdAt) > new Date(fileInfo.createdAt),
            });
        }
    });

    comments.forEach((comment: ITreeRowType) => {
        if (comment.parentId !== "" && new Date(comment.createdAt) > new Date(fileInfo.createdAt)) {
            if (commentsMap.has(comment.parentId)) {
                filteredComments.push(commentsMap.get(comment.parentId));
                commentsMap.delete(comment.parentId);
            }
            repliesCount += 1;
            comment.unreadReply = true;
            filteredComments.push(comment);
        }
    });
    return {
        filteredRows: filteredComments,
        count: repliesCount,
    };
};

const processCommentsData = (
    commentsResponse: ITreeCommentResponse,
    filterType: string,
    user: IUserToken
): ITreeCommentResponse => {
    const nodeId = "?node-id=";
    if (commentsResponse) {
        const temp = cloneDeep(commentsResponse.data.comments.comments.comments);
        commentsResponse.data.comments.originalResponse = cloneDeep(
            commentsResponse.data.comments.comments.comments
        );
        switch (filterType) {
            case "total":
                commentsResponse.data.comments.comments.comments =
                    getAllComments(temp).filteredRows;
                break;
            case "resolved":
                commentsResponse.data.comments.comments.comments =
                    getResolvedFilteredComments(temp).filteredRows;
                break;
            case "unResolved":
                commentsResponse.data.comments.comments.comments =
                    getUnResolvedFilteredComments(temp).filteredRows;
                break;
            case "own":
                commentsResponse.data.comments.comments.comments = getMyOwnComments(
                    temp,
                    user.figmaUserId
                ).filteredRows;
                break;
            case "unreadComments":
                commentsResponse.data.comments.comments.comments = getAllUnreadComments(
                    temp,
                    commentsResponse.data.comments.fileInfo
                ).filteredRows;
                break;
            case "unreadReplies":
                commentsResponse.data.comments.comments.comments = getAllUnreadReplies(
                    temp,
                    commentsResponse.data.comments.fileInfo
                ).filteredRows;
                break;
            default:
                break;
        }
        commentsResponse.data.comments.comments.comments.forEach((comment: any) => {
            comment.markAsReadCreatedAt = comment.createdAt;
            if (!comment.clientMeta) {
                const index = temp.findIndex(
                    (tempComment: ITreeRowType) => tempComment.id === comment.parentId
                );
                if (index > -1) {
                    comment.myComment =
                        user.figmaUserId.toString() === comment.user.id.toString() ? "Y" : "";
                    comment.userName = comment.user.handle;
                    comment.status = comment.resolvedAt ? "resolved" : "Unresolved";

                    // comment.createdAt = moment(comment.createdAt).format("DD-MM-YYYY HH:MM:SS");

                    // comment.resolvedAt = comment.resolvedAt
                    //     ? moment(comment.resolvedAt).format("DD-MM-YYYY HH:MM:SS")
                    //     : "-";
                }
            } else {
                comment.commentLocation = `${APP_CONSTANT.figmaUrl}/file/${comment.fileKey}${nodeId}${comment.clientMeta.nodeId}#${comment.id}`;

                comment.myComment =
                    user.figmaUserId.toString() === comment.user.id.toString() ? "Y" : "";
                comment.userName = comment.user.handle;
                comment.status = comment.resolvedAt ? "resolved" : "Unresolved";
                if (comment.parentId !== "")
                    comment.unreadReply =
                        new Date(commentsResponse.data.comments.fileInfo.createdAt) <
                        new Date(comment.createdAt)
                            ? "Y"
                            : "";
                else
                    comment.unreadComment =
                        new Date(commentsResponse.data.comments.fileInfo.createdAt) <
                        new Date(comment.createdAt)
                            ? "Y"
                            : "";

                // comment.createdAt = moment(comment.createdAt).format("DD-MM-YYYY HH:MM:SS");

                // comment.resolvedAt = comment.resolvedAt
                //     ? moment(comment.resolvedAt).format("DD-MM-YYYY HH:MM:SS")
                //     : "-";
            }
        });
        commentsResponse.data.comments.comments.comments = sort(
            commentsResponse.data.comments.comments.comments,
            [(comment: ITreeRowType) => comment.createdAt]
        );

        const tree = [...commentsResponse.data.comments.comments.comments];

        commentsResponse.data.comments.comments.comments.forEach((comment: any) => {
            if (!comment.parentId) {
                comment.children = tree.filter(
                    (treeComment) => treeComment.parentId === comment.id
                );
                comment.isExpanded = false;
            }
        });

        commentsResponse.data.comments.comments.comments =
            commentsResponse.data.comments.comments.comments.filter(
                (comment: ITreeRowType) => comment.parentId === ""
            );
        commentsResponse.data.comments.sortedResponse = cloneDeep(
            commentsResponse.data.comments.comments.comments
        );
        return commentsResponse;
    }
    return commentsResponse;
};

const REACTIONS: { [key: string]: string } = {
    ":+1:": thumbUpEmoji,
    ":-1:": thumbDownEmoji,
    ":eyes:": eyesEmoji,
    ":heavy_plus_sign:": plusEmoji,
    ":heart_eyes:": heartEyesEmoji,
    ":joy:": tearOfJoyEmoji,
    ":fire:": fireEmoji,
};

const getReactionImage = (emoji: ReactionType) => REACTIONS[emoji];

const getReactionsList = ({
    keyLabel = "key",
    valueLabel = "value",
}: {
    keyLabel?: string;
    valueLabel?: string;
}) => {
    const data: { [key: string]: string | number }[] = [];
    Object.entries(REACTIONS).forEach(([key, value]) =>
        data.push({
            [keyLabel]: key,
            [valueLabel]: value,
        })
    );
    return data;
};

// TODO: update type definitions
const getReactionAction = (reactions: any[], reaction: ReactionType, figmaUser: FigmaUserType) => {
    let action: ReactionActionType = "add";
    if (reactions.length) {
        const userReactionExist = reactions.find(
            ({ emoji, user }: { emoji: ReactionType; user: any }) =>
                emoji === reaction && user.id === figmaUser.id
        );
        if (userReactionExist) {
            action = "delete";
        }
    }
    return action;
};

export {
    exportCommentsToExcel,
    exportCommentsToNotion,
    getAllComments,
    getAllUnreadComments,
    getAllUnreadReplies,
    getMyOwnComments,
    getReactionAction,
    getReactionImage,
    getReactionsList,
    getResolvedFilteredComments,
    getUnResolvedFilteredComments,
    processCommentsData,
    processDeleteComment,
    processReplyCommnet,
    processTreeData,
    REACTIONS,
    sortingTreeData,
    toggleSubRow,
};

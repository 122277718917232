export const saveUserToken = (tokenDetail: any) => {
    localStorage.setItem("userToken", tokenDetail);
};
export const removeUserToken = () => {
    localStorage.removeItem("userToken");
};
export const getUserToken = () => {
    const token = localStorage.getItem("userToken");
    if (token) {
        return token;
    }
    return null;
};
export const saveDoItLaterState = (doItLater: boolean) => {
    localStorage.setItem("doItLater", doItLater.toString());
};

export const getDoItLaterState = () => {
    const doItLater = localStorage.getItem("doItLater");
    return doItLater === "true";
};

export const saveUser = (detail: any) => {
    localStorage.setItem("user", detail);
};

export const removeUser = () => {
    localStorage.removeItem("user");
};
export const getUser = () => {
    const user = localStorage.getItem("user");
    if (user) {
        return user;
    }
    return null;
};

export const fetchDoItLaterStatus = () => localStorage.getItem("doItLater");

export const fetchGenerateAccessTokenStatus = () => sessionStorage.getItem("GenerateAccessToken");

export const saveGenerateAccessTokenStatus = (status: string) => {
    sessionStorage.setItem("GenerateAccessToken", status);
};

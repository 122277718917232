import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useRef } from "react";
import styled from "styled-components";

import { UserContext } from "../../../contexts/UserContext";

const LabelTxt = styled.div`
    color: #616161;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    line-height: 1.4375em;

    @media (max-width: 640px) {
        &.label-text {
            display: none !important;
        }
    }
`;

const CustSelectDropdown = styled.div`
    &.muicust-select {
        .MuiBox-root {
            min-width: 150px;
            .MuiOutlinedInput-root {
                .MuiSelect-select {
                    color: #2979ff;
                    font-weight: 500;
                    font-size: 14px;
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-left: 5px;
                    line-height: 1.4375em;
                }
                .MuiSvgIcon-root {
                    fill: #2979ff;
                }
            }
        }
    }
`;

const LabelSelectWrap = styled.div`
    display: flex;
    align-items: center;
`;

const MarkAsRead = styled.a`
    display: flex;
    white-space: nowrap;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: #2979ff;
    line-height: 1.4375em;

    &.disable {
        color: #9e9e9e;
        cursor: not-allowed;
    }
`;

export const FilterDropdown = ({
    filterOptions,
    handleFilterClick,
    handleMarkAsReadClick,
}: any) => {
    const { commentType, setCommentType, isDisable, setIsDisable } = UserContext();

    const handleChange = (event: any) => {
        setCommentType(event.target.value);
    };
    const dropdownRef = useRef(null as any);

    useEffect(() => {
        let numberOfNewComments = 0;
        filterOptions.forEach((item: any) => {
            if (item.name === "Unread Comments" || item.name === "Unread Replies")
                numberOfNewComments += item.record;
        });
        if (numberOfNewComments === 0) {
            setIsDisable(true);
        }
    }, [filterOptions]);
    return (
        <>
            <LabelSelectWrap>
                <LabelTxt className="label-text">I want to view</LabelTxt>
                <CustSelectDropdown
                    className="dropdown with-arrow mx-5px muicust-select"
                    ref={dropdownRef}
                >
                    <Box sx={{ minWidth: 180 }}>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={commentType}
                                label="Age"
                                onChange={handleChange}
                                sx={{
                                    ".MuiOutlinedInput-notchedOutline": {
                                        border: "0px",
                                    },
                                }}
                            >
                                {filterOptions.map((item: any) => (
                                    <MenuItem
                                        role="presentation"
                                        key={item.id}
                                        value={item.name}
                                        className="mui-dropdown-list-item"
                                        onClick={() => {
                                            handleFilterClick(item.id);
                                        }}
                                    >
                                        {item.name} ({item.record})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </CustSelectDropdown>
            </LabelSelectWrap>

            <span className="mob-none">
                <MarkAsRead
                    className={isDisable ? "disable" : ""}
                    onClick={isDisable ? null : handleMarkAsReadClick}
                >
                    Mark as Read
                </MarkAsRead>
            </span>
        </>
    );
};

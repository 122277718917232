import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import noNotionAccount from "../../assets/no-notion-account.svg";
import {
    Projects,
    useFetchFiles,
    useNotionMutation,
    useProjectConnect,
    useProjectDisconnect,
    useSyncComments,
} from "../../hooks";
import { EcDialog } from "../../stories/components/EcDialog/EcDialog";
import { ProjectCard } from "../../stories/components/ProjectCard/ProjectCard";
import { IUserFile } from "../../types";
import { NotionDisconnectIcon, NotionIcon, SyncCommentIcon } from "../../utils";

const NotionProjectsDrawer = (props: any) => {
    const {
        isComment,
        onCloseDrawer,
        users,
        setProjectConnected,
        setProjectDisconnected,
        setCommentsSynced,
        commentsSynced,
    } = props;
    const queryClient = useQueryClient();
    const [isDisconnectProjectsModalOpen, setIsDisconnectProjectsModalOpen] = useState(false);
    const [row, setRow] = useState<any[]>([]);
    const [projectId, setProjectId] = useState<number>();
    const { disconnectProject } = useProjectDisconnect(props);

    const {
        mutate: notionProjectDisconnect,
        isLoading: isProjectDisconnecting,
        isSuccess: isProjectDisconnected,
    } = disconnectProject;
    const { disconnectNotion } = useNotionMutation();
    const { mutate: mutateDisconnectNotion, isLoading: disconnectingNotion } = disconnectNotion;

    // sync comments
    const { syncComments } = useSyncComments(props);
    const {
        mutate: syncCommentsExecute,
        isSuccess: isCommentsSuccess,
        isLoading: syncingComments,
    } = syncComments;
    useEffect(() => {
        if (isCommentsSuccess) {
            queryClient.refetchQueries([Projects]);
            setCommentsSynced(!commentsSynced);
        }
    }, [isCommentsSuccess]);
    const { connectProject } = useProjectConnect(props);

    const {
        mutate: notionProjectConnect,
        isSuccess: isProjectConnected,
        isLoading: isProjectConnecting,
        variables: projectConnectingVariables,
    } = connectProject;

    useEffect(() => {
        if (isProjectConnected) {
            // refetch all files
            queryClient.refetchQueries([Projects]);
            setProjectConnected(true);
        }
    }, [isProjectConnected]);

    useEffect(() => {
        if (isProjectDisconnected) {
            // refetch all files
            queryClient.refetchQueries([Projects]);
            setProjectDisconnected(true);
        }
    }, [isProjectDisconnected]);
    // Move the code related to the NotionProjectsDrawer component here
    const handleNotionSync = (item: any) => {
        setProjectId(item.exportedFileId);
        syncCommentsExecute({ projectId: item.exportedFileId });
    };
    const { data: filesData } = useFetchFiles({
        ...props,
        isEnabled: !isComment,
    });

    useEffect(() => {
        const tempRow: any[] = [];
        filesData?.data?.data.forEach((item) => {
            if (item.notionProjectConnected) {
                tempRow.push(item);
            }
        });
        setRow(tempRow);
    }, [filesData?.data]);
    const handleCloseDrawer = () => () => {
        onCloseDrawer();
    };
    return (
        <Box style={{ height: "90vh" }}>
            <Box display="flex" justifyContent="end">
                <Button
                    variant="outlined"
                    color="error"
                    style={{
                        marginBottom: "20px",
                        textTransform: "capitalize",
                    }}
                    onClick={() => setIsDisconnectProjectsModalOpen(true)}
                >
                    Disconnect all
                </Button>
            </Box>
            {row && row.length ? (
                row.map((item: IUserFile) => (
                    <ProjectCard
                        key={item.projectId}
                        actionButtons={[
                            {
                                icon: <NotionDisconnectIcon sx={{ fontSize: 20 }} />,
                                label: "Disconnect from Notion",
                                disabled:
                                    (item.exportedFileId === projectId && isProjectDisconnecting) ||
                                    disconnectingNotion,
                                onClick: () => {
                                    setProjectId(item.exportedFileId);
                                    notionProjectDisconnect(item.exportedFileId);
                                },
                            },
                            {
                                icon:
                                    item.exportedFileId === projectId && syncingComments ? (
                                        <CircularProgress size="1rem" />
                                    ) : (
                                        <SyncCommentIcon sx={{ fontSize: 20 }} />
                                    ),
                                label: "Sync Comments",
                                disabled:
                                    (item.exportedFileId === projectId && syncingComments) ||
                                    disconnectingNotion,
                                onClick: () => {
                                    handleNotionSync(item);
                                },
                            },
                        ]}
                        icon={<NotionIcon sx={{ fontSize: 20 }} />}
                        title={
                            <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                                {item.fileName}
                            </Typography>
                        }
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box>
                                <Typography
                                    color="#616161"
                                    component="div"
                                    gutterBottom
                                    variant="body2"
                                >
                                    Last Synced
                                </Typography>
                                <Typography
                                    color="#212121"
                                    component="div"
                                    gutterBottom
                                    variant="subtitle2"
                                >
                                    {moment(new Date(item.createdAt)).format("l") ===
                                    moment(new Date()).format("l")
                                        ? `${moment(new Date(item.createdAt))
                                              .startOf("hour")
                                              .fromNow()}`
                                        : moment(new Date(item.createdAt)).format("LL")}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    color="#616161"
                                    component="div"
                                    gutterBottom
                                    variant="body2"
                                >
                                    Synced By
                                </Typography>
                                <Typography
                                    color="#212121"
                                    component="div"
                                    gutterBottom
                                    variant="subtitle2"
                                >
                                    {users.name}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    color="#616161"
                                    component="div"
                                    gutterBottom
                                    variant="body2"
                                >
                                    Total Comments
                                </Typography>
                                <Typography
                                    color="#212121"
                                    component="div"
                                    gutterBottom
                                    variant="subtitle2"
                                >
                                    {item.allComments}
                                </Typography>
                            </Box>
                        </Box>
                    </ProjectCard>
                ))
            ) : (
                <Box display="flex" flexDirection="column" mb={1}>
                    <Box display="flex" flexDirection="column" alignItems="center" mt={3} mb={3}>
                        <Box>
                            <img width="100%" src={noNotionAccount} alt="Add Notion Banner" />
                        </Box>
                        <Typography variant="h6" fontWeight="400" paddingTop={3}>
                            No Projects Found
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        mt={3}
                        mb={1}
                        p={3}
                        borderRadius={1}
                        sx={{ background: "#F5F5F5" }}
                    >
                        <Typography variant="body2" fontWeight="500" color="#000">
                            There are no projects connected to Notion. Here are the list of projects
                            that could be connected.
                        </Typography>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="spaceBetween"
                            mt={2}
                            style={{
                                overflowY: "auto",
                                height: "fit-content",
                                maxHeight: "calc(100vh - 40rem)",
                                overflowX: "hidden",
                            }}
                        >
                            {filesData?.data?.data.map((file: IUserFile) => (
                                <Box
                                    width="98%"
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="body2" width="100%">
                                        {file.fileName}
                                    </Typography>
                                    <Button
                                        color="primary"
                                        style={{ textTransform: "inherit" }}
                                        disabled={isProjectConnecting || disconnectingNotion}
                                        onClick={() => notionProjectConnect(file.exportedFileId)}
                                        {...(isProjectConnecting &&
                                            projectConnectingVariables === file.exportedFileId && {
                                                endIcon: (
                                                    <CircularProgress size={15} color="inherit" />
                                                ),
                                            })}
                                    >
                                        {isProjectConnecting &&
                                        projectConnectingVariables === file.exportedFileId
                                            ? "Connecting"
                                            : "Connect"}
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            )}
            <EcDialog
                title="Disconnect Notion"
                description="Disconnecting notion will remove all your connected projects and reset notion connectivity completely. Are you sure you want to disconnect Notion from your Dashboard."
                open={isDisconnectProjectsModalOpen}
                onClose={() => setIsDisconnectProjectsModalOpen(false)}
                actionButtons={[
                    {
                        label: "Cancel",
                        onClick: () => {
                            setIsDisconnectProjectsModalOpen(false);
                        },
                        variant: "outlined",
                    },
                    {
                        label: "Yes, I'm Sure",
                        onClick: () => {
                            mutateDisconnectNotion();
                            setIsDisconnectProjectsModalOpen(false);
                            handleCloseDrawer()();
                        },
                        variant: "contained",
                    },
                ]}
            />
        </Box>
    );
};

export default NotionProjectsDrawer;

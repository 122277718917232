import { AddReactionOutlined as AddReactionOutlinedIcon } from "@mui/icons-material";
import { Box, Button, IconButton, Popover } from "@mui/material";
import { useState } from "react";

import { ReactionType } from "../../types";
import { getReactionImage, getReactionsList } from "../../utils";

interface ReactionPopoverPropsType {
    onClickReaction: (reaction: ReactionType) => void;
}

const COMMENT_REACTIONS = getReactionsList({
    keyLabel: "name",
    valueLabel: "id",
});

const ReactionPopover = (props: ReactionPopoverPropsType) => {
    const { onClickReaction } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <AddReactionOutlinedIcon />
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Box display="flex" className="reactions" alignItems="center">
                    {COMMENT_REACTIONS.map(({ name }) => (
                        <Box>
                            <Button
                                sx={{
                                    minWidth: "auto",
                                    fontSize: "18px",
                                    display: "inline-flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                }}
                                variant="text"
                                onClick={() => {
                                    onClickReaction(name as ReactionType);
                                    setAnchorEl(null);
                                }}
                            >
                                <img src={getReactionImage(name as ReactionType)} alt="emoji" />
                            </Button>
                        </Box>
                    ))}
                </Box>
            </Popover>
        </>
    );
};

export { ReactionPopover };

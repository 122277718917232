import {
    UnfoldLessOutlined as UnfoldLessOutlinedIcon,
    UnfoldMoreOutlined as UnfoldMoreOutlinedIcon,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ShareIcon from "@mui/icons-material/Share";
import { Box, Button, Link as Links, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { useContext, useEffect, useRef, useState } from "react";
import DataGrid from "react-data-grid";
import { useQueryClient } from "react-query";
import styled from "styled-components";

import commentIcon from "../assets/comment-icon.svg";
import figmaIcon from "../assets/figma.svg";
import FileIcon from "../assets/file.svg";
import {
    Cell,
    CellExpanderFormatter,
    CreateVersionDrawer,
    FlowVersionDrawer,
    PageOrFlowCommentsDrawer,
    PrototypeNameCell,
    ShareFlowVersionModal,
    SharePageLinkModel,
    UserAccessMenu,
} from "../containers/Prototype";
import { SizeContext } from "../contexts";
import { useFetchPrototypeList } from "../hooks";
import { COMMENTS, processPageAndPrototypeComments } from "../hooks/commentHook";
import { EcModal } from "../stories/components";
import {
    ChipType,
    CommentDataType,
    FlowRowType,
    ITreeCommentResponse,
    PageRowType,
    PageType,
} from "../types";
import { FlowType } from "../types/prototype";
import { openLink } from "../utils";

const GridContainerPrototype = styled.div`
    padding: 10px 20px;
    width: 100%;
    .rdg {
        border: 0;
        resize: none !important;
        background-color: #f5f5f5 !important;
        &.grid {
            &.noData {
                height: 60px !important;
            }
        }
    }
    .rdg-header-row {
        .rdg-cell {
            background-color: #f9f9fa;
            border-left: 0;
            border-right: 0;
            border-bottom: 1px solid #e0e0e0;
            padding: 0 10px;
            &.orderId {
                white-space: normal;
                line-height: 1.2;
                max-width: 5rem;
            }
            .rdg-sort-arrow {
                margin-left: 8px;
            }
            &:hover {
                &::after {
                    background-color: #e0e0e0;
                    height: 26px;
                    top: 12px;
                    width: 2px;
                }
            }
        }
    }
    .parent-with-children {
        background-color: #fff;
        color: #212121;
        border-bottom: 1px solid #e0e0e0;
        &:hover,
        &:active {
            background-color: rgb(206, 221, 247);
        }
        margin-bottom: 50px;
    }
    .highlighted-row {
        background-color: rgb(206, 221, 247) !important;
        .highlight-icon {
            display: inline-flex;
        }
    }
    .child {
        background-color: #ffffff;
        border-bottom: 0;
        color: #212121;
        .rdg-cell {
            border-bottom: 1px solid #e0e0e0;
            padding: 0 10px;
            &:first-child {
                border-bottom: 0;
            }
            .unresolved,
            .resolved {
                display: none;
            }
        }
        .rdg-checkbox-label {
            display: none;
        }
        .child-action-disable {
            color: #9e9e9e;
            cursor: not-allowed;
            pointer-events: none;
        }
    }
    .rdg-cell {
        box-shadow: none !important;
        border-right: 0;
        // border-bottom: 0;
        display: flex;
        align-items: center;
        padding: 0 10px;
    }
    .cell-childitem {
        display: block !important;
        padding: 0px;
        & > * > * {
            padding: 0;
        }
        .rdg-row {
            .rdg-cell {
                background-color: #f9f9fa !important;
            }
            &:hover {
                .rdg-cell {
                    background-color: #f0f0ff !important;
                }
            }
        }
        .rdg-header-row {
            .rdg-cell {
                background-color: #f0f0f1 !important;
                color: #4a4b57;
            }
        }
    }
`;

const PrototypePage = (props: any) => {
    const {
        filterPrototypeData,
        projectId,
        setFilterPrototypeData,
        branchKeyPrototype,
        branchName,
        customWidth,
    } = props;
    const [pageInfos, setPageInfo] = useState<PageType | undefined>();
    const [pageId, setPageId] = useState<number>(0);
    const [flowId, setFlowId] = useState<number>(0);
    const [height] = useState(window.innerHeight);
    const [sharePageModel, setSharePageModel] = useState(false);
    const [openCreateFeedbackDrawer, setOpenCreateFeedbackDrawer] = useState(false);
    const [openChipDrawer, setOpenChipDrawer] = useState(false);
    const [flowInfo, setFlowInfo] = useState<FlowType>();
    const [chipInfo, setChipInfo] = useState<ChipType>();
    const [versionId, setVersionId] = useState<number>(0);
    const [versionUser, setVersionUsers] = useState([] as any);
    const [shareVersionModel, setShareVersionModel] = useState(false);
    const [previewModal, setPreviewModal] = useState<JSX.Element>();
    const [treeDatas, setTreeDatas] = useState<PageType[]>([]);
    const [isExpandAll, setIsExpandAll] = useState(false);
    const [commentsDrawer, setCommentsDrawer] = useState<JSX.Element>();
    const { data: prototypeData, refetch: refetchPrototypeData } =
        useFetchPrototypeList(branchKeyPrototype);
    const queryClient = useQueryClient();
    useEffect(() => {
        const data = queryClient.getQueryData([COMMENTS, projectId]) as ITreeCommentResponse;
        const treeData = processPageAndPrototypeComments({
            comments: data?.data?.comments?.comments?.comments,
            pages: filterPrototypeData,
        });
        setTreeDatas(treeData);
    }, [prototypeData]);
    const [pageRow, setRows] = useState([] as any);
    const [flowNodeId, setFlowNodeId] = useState([] as any);
    const handleExpandAll = () => {
        const indexesWithFlows = pageRow
            .map((product: any, index: number) => ({
                hasFlows: product.flows.length > 0,
                index,
            }))
            .filter((item: { hasFlows: any }) => item.hasFlows)
            .map((item: { index: any }) => item.index);
        const updatedRows = [...pageRow];
        let i = indexesWithFlows.length - 1;
        while (i >= 0) {
            const index = indexesWithFlows[i];
            const row = updatedRows[index];
            if (row.type === "MASTER") {
                updatedRows[index].expanded = !row.expanded;
                if (updatedRows[index].expanded) {
                    updatedRows.splice(index + 1, 0, {
                        type: "DETAIL",
                        id: row.id + 100,
                        parentId: row.id,
                        flows: row.flows,
                    });
                } else {
                    updatedRows.splice(index + 1, 1);
                }
            }
            // eslint-disable-next-line no-plusplus
            i--;
        }
        setRows(updatedRows);
        setIsExpandAll(!isExpandAll);
    };

    const closeFeedbackDrawer = () => {
        setOpenCreateFeedbackDrawer(false);
        refetchPrototypeData();
    };
    useEffect(() => {
        const updatedRows: any = createPageRow();
        setRows(updatedRows);
    }, [treeDatas]);
    function createPageRow(): readonly PageRowType[] {
        return treeDatas.map((_: any) => ({
            type: "MASTER",
            // page_id: index + 1,
            id: _.page_id,
            name: _.name,
            expanded: false,
            pageLink: _.pageLink,
            page_users: _.page_users,
            flows: _.flows,
            node_id: _.node_id,
            comments: _.comments,
        }));
    }

    const flowMap = new Map<number, readonly FlowRowType[]>();
    function getFlows(parentId: number, childflows: any[]): readonly FlowRowType[] {
        if (flowMap.has(parentId)) {
            return flowMap.get(parentId)!;
        }
        const flowrows: FlowRowType[] = childflows.map((_: any, i: number) => ({
            type: "MASTER",
            flow_id: i + 1,
            id: _.flow_id,
            name: _.name,
            feedbackVersion: _.versions,
            flow_users: _.flow_users,
            flowLink: _.flowLink,
            node_id: _.node_id,
            comments: _.comments,
        }));
        flowMap.set(parentId, flowrows);
        return flowrows;
    }
    const handlePreviewImageClick = (imageUrl: string, title: string) => {
        setPreviewModal(
            <EcModal
                size="md"
                open
                onClose={() => {
                    setPreviewModal(undefined);
                }}
                title={title}
                showCloseIcon
                textAlign="left"
            >
                <Box
                    marginTop={3}
                    sx={{
                        maxHeight: "550px",
                        overflowY: "scroll",
                    }}
                >
                    <img
                        src={imageUrl}
                        alt="Large flow"
                        width="976px"
                        style={{
                            display: "block",
                            maxWidth: "100%",
                        }}
                    />
                </Box>
            </EcModal>
        );
    };
    const onRowsChange = (rows: any, { indexes }: any) => {
        const row = rows[indexes[0]];
        if (row.type === "MASTER") {
            if (!row.expanded) {
                rows.splice(indexes[0] + 1, 1);
            } else {
                rows.splice(indexes[0] + 1, 0, {
                    type: "DETAIL",
                    id: row.id + 100,
                    parentId: row.id,
                    flows: row.flows,
                });
            }
            setRows(rows);
        }
    };
    const handleClickPageOrFlowComment = (comments: CommentDataType[], name: string) => {
        setCommentsDrawer(
            <PageOrFlowCommentsDrawer
                title="Comments"
                subTitle={name}
                onClose={() => setCommentsDrawer(undefined)}
                data={comments}
            />
        );
    };
    const WindowContext: any = () => useContext(SizeContext);
    const { width } = WindowContext();
    const pageColumn: any = [
        {
            key: "expanded",
            // TODO: functionality is not done yet need to cover in next sprint
            name: isExpandAll ? (
                <UnfoldLessOutlinedIcon
                    onClick={handleExpandAll}
                    sx={{ verticalAlign: "middle" }}
                />
            ) : (
                <UnfoldMoreOutlinedIcon
                    onClick={handleExpandAll}
                    sx={{ verticalAlign: "middle" }}
                />
            ),
            resizable: true,
            width: 45,
            minWidth: 45,
            colSpan(args: { type: string; row: { type: string } }): 7 | undefined {
                return args.type === "ROW" && args.row.type === "DETAIL" ? 7 : undefined;
            },
            cellClass(row: { type: string }) {
                return row.type === "DETAIL"
                    ? `
                  cell-childitem
                `
                    : undefined;
            },
            formatter({ row, isCellSelected, onRowChange }: any) {
                if (row.type === "DETAIL") {
                    return (
                        <FlowGrid
                            isCellSelected={isCellSelected}
                            parentId={row.id}
                            flows={row.flows}
                        />
                    );
                }

                return (
                    <>
                        {row.flows.length > 0 && (
                            <CellExpanderFormatter
                                expanded={row.expanded}
                                isCellSelected={isCellSelected}
                                onCellExpand={() => {
                                    onRowChange({
                                        ...row,
                                        expanded: !row.expanded,
                                    });
                                }}
                            />
                        )}
                    </>
                );
            },
        },
        {
            key: "page_id",
            name: "",
            width: width > 767 ? 0 : 0,
            minWidth: width > 767 ? 0 : 0,
            resizable: true,
        },
        {
            key: "name",
            name: "Page Name",
            resizable: true,
            minWidth: 300,
            formatter: (row: any) => (
                <Cell>
                    <img
                        src={FileIcon}
                        alt="File Icon"
                        style={{
                            color: "black",
                            fontSize: "3px",
                            marginRight: "1rem",
                        }}
                    />
                    <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                        {row.row.name}
                    </Typography>
                </Cell>
            ),
        },
        {
            key: "comments",
            name: "Comments",
            width: 250,
            resizable: true,
            formatter: (row: any) => (
                <Cell
                    onClick={() => handleClickPageOrFlowComment(row.row.comments, row.row.name)}
                    style={{
                        cursor: "pointer",
                    }}
                >
                    <img
                        src={commentIcon}
                        alt="Comment Icon"
                        style={{
                            margin: "0px 7px",
                        }}
                    />
                    <Typography
                        style={{
                            fontSize: "bold",
                        }}
                    >
                        {row.row.comments.length}
                    </Typography>
                </Cell>
            ),
        },
        {
            key: "link",
            name: "Page Link",
            resizable: true,
            width: 549,
            formatter: (row: any) => (
                <Cell>
                    <img
                        src={figmaIcon}
                        alt="Prototype Icon"
                        style={{
                            color: "black",
                            marginRight: "8px",
                            width: "16px",
                            height: "16px",
                        }}
                    />
                    <Links
                        href={row.row.pageLink}
                        sx={{
                            textDecoration: "none",
                            color: "#2979FF",
                            cursor: "pointer",
                        }}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                            openLink({
                                event,
                                url: row.row.pageLink,
                                target: "_blank",
                            })
                        }
                    >
                        Link
                    </Links>
                </Cell>
            ),
        },
        {
            key: "page_users",
            name: "Users with access",
            resizable: true,
            width: 300,
            formatter: (row: any) => (
                <Cell>
                    {row.row.page_users && (
                        <UserAccessMenu max={4} pageUsers={row.row.page_users} avatarSize={26} />
                    )}
                </Cell>
            ),
        },
        {
            key: "action",
            name: "Action",
            resizable: true,
            width: 150,
            formatter: (row: any) => (
                <Cell>
                    {row.row.type === "MASTER" && (
                        <>
                            <ShareIcon
                                style={{
                                    marginRight: "8px",
                                    color: "rgb(41, 121, 255)",
                                    fontSize: "22px",
                                    cursor: "pointer",
                                }}
                                onClick={() => handleSharePageModel(row.row)}
                            />
                            {row ? (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <Links
                                        style={{
                                            cursor: "pointer",
                                            textDecoration: "none",
                                        }}
                                        onClick={() => handleSharePageModel(row.row)}
                                    >
                                        Share
                                    </Links>
                                </div>
                            ) : (
                                <span />
                            )}
                        </>
                    )}
                </Cell>
            ),
        },
    ];

    const flowColumn: any = [
        {
            key: "",
            name: "",
            width: width > 767 ? 88 : 45,
            minWidth: width > 767 ? 88 : 45,
            resizable: true,
        },
        {
            key: "name",
            name: "Prototype Names",
            width: 538,
            minWidth: 450,
            resizable: true,
            formatter: (row: any) => (
                <PrototypeNameCell
                    data={{
                        name: row.row.name,
                        prototypeLink: row.row.flowLink,
                        nodeId: row.row.node_id,
                    }}
                    projectId={projectId}
                    onClick={(url) => handlePreviewImageClick(url, row.row.name)}
                />
            ),
        },
        {
            key: "comments",
            name: "Comments",
            width: 250,
            resizable: true,
            formatter: (row: any) => (
                <Cell
                    onClick={() => handleClickPageOrFlowComment(row.row.comments, row.row.name)}
                    style={{
                        cursor: "pointer",
                    }}
                >
                    <img
                        src={commentIcon}
                        alt="Comment Icon"
                        style={{
                            margin: "0px 7px",
                        }}
                    />
                    <Typography
                        style={{
                            fontSize: "bold",
                        }}
                    >
                        {row.row.comments.length}
                    </Typography>
                </Cell>
            ),
        },
        {
            key: "flow_version",
            name: "Versions",
            width: 530,
            resizable: true,
            formatter: ({ row }: any) => (
                <Cell>
                    {row.feedbackVersion?.length >= 1 && (
                        <>
                            <span
                                style={{
                                    cursor: "pointer",
                                    color: "#2979FF",
                                    textDecoration: "none",
                                    marginRight: "1rem",
                                    width: "auto",
                                }}
                                onClick={() => handleChipClick(row, row.feedbackVersion.slice(2))}
                                onKeyPress={(event) => {
                                    // Check if the Enter key was pressed
                                    if (event.key === "Enter") {
                                        handleChipClick(row, row.feedbackVersion.slice(2));
                                    }
                                }}
                                role="button"
                                tabIndex={0}
                            >
                                {row.feedbackVersion.length}
                            </span>
                            <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                                sx={{ height: "1rem" }}
                                style={{ margin: "auto" }}
                            />
                        </>
                    )}

                    <Button
                        variant="text"
                        sx={{
                            textTransform: "none",
                            color: "#2979FF",
                            fontSize: "14px",
                            paddingLeft: "0px",
                            "&:focus": {
                                background: "transparent",
                            },
                            "&:hover": {
                                background: "transparent",
                            },
                            ...(row.feedbackVersion?.length < 1 && {
                                "& .MuiButton-startIcon": {
                                    marginLeft: "0px",
                                },
                            }),
                        }}
                        startIcon={<AddIcon sx={{ color: "#2979FF" }} />}
                        onClick={() => handleCreateNew(row)}
                    >
                        Create New
                    </Button>
                </Cell>
            ),
        },
        {
            key: "flow_users",
            name: "Users with access",
            width: "auto",
            resizable: true,
            formatter: (row: any) => (
                <Cell>
                    {row.row.feedbackVersion && row.row.feedbackVersion.length > 0 && (
                        <UserAccessMenu
                            max={4}
                            pageUsers={row.row.feedbackVersion.reduce(
                                (users: any[], feedbackVersion: any) => {
                                    if (feedbackVersion.version_users) {
                                        feedbackVersion.version_users.forEach((user: any) => {
                                            const existingUser = users.find(
                                                (u) => u.user_id === user.user_id
                                            );
                                            if (!existingUser) {
                                                users.push(user);
                                            }
                                        });
                                    }
                                    return users;
                                },
                                []
                            )}
                            avatarSize={24}
                        />
                    )}
                </Cell>
            ),
        },
    ];

    useEffect(() => {
        setFilterPrototypeData(prototypeData);
        refetchPrototypeData();
    }, [prototypeData, sharePageModel]);

    const handleSharePageModel = (pageInfo: any) => {
        setPageInfo(pageInfo);
        setPageId(pageInfo.id);
        setSharePageModel(true);
    };

    const handleCloseShareModal = () => {
        setSharePageModel(false);
    };
    const handleCloseVersionModal = () => {
        setShareVersionModel(false);
    };
    const handleCreateNew = (info: any) => {
        setFlowInfo(info);
        setOpenCreateFeedbackDrawer(true);
    };
    const handleChipClick = (flow: FlowType, item: any) => {
        setFlowInfo(flow);
        setChipInfo(item);
        setOpenChipDrawer(true);
    };
    const handleShareVersion = (params: any) => {
        const { version, node_id } = params;
        setFlowId(version.flowId);
        setVersionId(version.versionId);
        setVersionUsers(version.versionUser);
        setFlowNodeId(node_id);
        setShareVersionModel(true);
    };
    const getDetailsMaxHeight = (flows: number) => {
        if (flows === 1) {
            return 180;
        }
        if (flows === 2 || flows === 3) {
            return 320;
        }
        return 320;
    };
    return (
        <GridContainerPrototype>
            <DataGrid
                rowKeyGetter={rowKeyGetter}
                columns={pageColumn}
                rows={pageRow}
                onRowsChange={onRowsChange}
                headerRowHeight={50}
                rowHeight={(args: any) =>
                    args.row.type === "DETAIL" ? getDetailsMaxHeight(args.row.flows.length) : 65
                }
                style={{
                    height: `${height - 230}px`,
                    resize: "both",
                }}
                defaultColumnOptions={{
                    sortable: true,
                    resizable: true,
                }}
            />
            {sharePageModel && (
                <SharePageLinkModel
                    projectId={Number(projectId)}
                    pageId={pageId}
                    onClose={handleCloseShareModal}
                    pageInfos={pageInfos}
                    branchName={branchName}
                />
            )}
            {shareVersionModel && (
                <ShareFlowVersionModal
                    projectId={Number(projectId)}
                    pageId={pageId}
                    onClose={handleCloseVersionModal}
                    flowId={flowId}
                    versionId={versionId}
                    versionUsers={versionUser}
                    branchName={branchName}
                    flowNodeId={flowNodeId}
                />
            )}
            {openCreateFeedbackDrawer && (
                <CreateVersionDrawer
                    title="Create New Version"
                    openCreateFeedbackDrawer={openCreateFeedbackDrawer}
                    setOpenCreateFeedbackDrawer={setOpenCreateFeedbackDrawer}
                    flowInfo={flowInfo}
                    pageId={pageId}
                    projectId={Number(projectId)}
                    closeFeedbackDrawer={closeFeedbackDrawer}
                    branchName={branchName}
                />
            )}
            {openChipDrawer && (
                <FlowVersionDrawer
                    title="Create New"
                    openChipDrawer={openChipDrawer}
                    setOpenChipDrawer={setOpenChipDrawer}
                    chipInfo={chipInfo}
                    flowInfo={flowInfo}
                    pageId={pageId}
                    projectId={Number(projectId)}
                    onShare={handleShareVersion}
                    customWidth={customWidth}
                />
            )}
            {previewModal && previewModal}
            {commentsDrawer && commentsDrawer}
        </GridContainerPrototype>
    );

    function FlowGrid({
        parentId,
        flows,
        isCellSelected,
    }: {
        parentId: number;
        flows: any[];
        isCellSelected: boolean;
    }) {
        const gridRef: any = useRef(null);
        useEffect(() => {
            if (!isCellSelected) return;
            gridRef
                .current!.element!.querySelector('[tabindex="0"]')!
                .focus({ preventScroll: true });
        }, [isCellSelected]);

        const flowsRow = getFlows(parentId, flows);
        function onKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
            if (event.isDefaultPrevented()) {
                event.stopPropagation();
            }
        }

        const getBlockSizeMaxHeight = (BlockSize: number) => {
            if (BlockSize === 1) {
                return `${flowsRow.length * 120 + 81}px`;
            }
            if (BlockSize === 2 || BlockSize === 3) {
                return 320;
            }
            return 340;
        };
        return (
            <Box onKeyDown={(event) => onKeyDown(event)}>
                <GridContainerPrototype>
                    <DataGrid
                        ref={gridRef}
                        rows={flowsRow}
                        columns={flowColumn}
                        rowKeyGetter={rowKeyGetter}
                        style={{
                            blockSize: getBlockSizeMaxHeight(flowsRow.length),
                        }}
                        headerRowHeight={50}
                        rowHeight={(args: any) => (args.row.type === "DETAIL" ? 300 : 140)}
                    />
                </GridContainerPrototype>
            </Box>
        );
    }
};

function rowKeyGetter(row: PageRowType | FlowRowType) {
    return row.id;
}

export { PrototypePage };

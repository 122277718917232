import { Box } from "@mui/material";
import styled from "styled-components";

const LoaderWrapper = styled.div`
    .gather-info {
        color: #e3a01c;
        font-size: 14px;
        font-weight: 700;
    }
    .dot-pulse-outr {
        display: inline-block;
        vertical-align: top;
        position: relative;
        width: 55px;
        height: 17px;
        padding: 5px;
        // background: #f5f5f5;
        left: 5px;
    }
    .dot-pulse-outr .dot-pulse {
        position: absolute;
        left: -9999px;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
        box-shadow: 9999px 0 0 -5px #9880ff;
        animation: dotPulse 1.5s infinite linear;
        animation-delay: 0.25s;
        margin-left: 20px;
    }
    .dot-pulse-outr .dot-pulse::before,
    .dot-pulse::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
    }
    .dot-pulse-outr .dot-pulse::before {
        box-shadow: 9984px 0 0 -5px #9880ff;
        animation: dotPulseBefore 1.5s infinite linear;
        animation-delay: 0s;
        left: 0px;
    }
    .dot-pulse-outr .dot-pulse::after {
        box-shadow: 10014px 0 0 -5px #9880ff;
        animation: dotPulseAfter 1.5s infinite linear;
        animation-delay: 0.5s;
    }

    @keyframes dotPulseBefore {
        0% {
            box-shadow: 9984px 0 0 -5px #009cff;
        }
        30% {
            box-shadow: 9984px 0 0 2px #009cff;
        }
        60%,
        100% {
            box-shadow: 9984px 0 0 -5px #009cff;
        }
    }

    @keyframes dotPulse {
        0% {
            box-shadow: 9999px 0 0 -5px #009cff;
        }
        30% {
            box-shadow: 9999px 0 0 2px #009cff;
        }
        60%,
        100% {
            box-shadow: 9999px 0 0 -5px #009cff;
        }
    }

    @keyframes dotPulseAfter {
        0% {
            box-shadow: 10014px 0 0 -5px #009cff;
        }
        30% {
            box-shadow: 10014px 0 0 2px #009cff;
        }
        60%,
        100% {
            box-shadow: 10014px 0 0 -5px #009cff;
        }
    }

    /** dot-pulse Animation Ends **/
`;

const ProjectLoader = (props: { title?: string }) => {
    const { title } = props;
    return (
        <LoaderWrapper>
            <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                <Box className="gather-info">
                    {title}
                    <span className="dot-pulse-outr">
                        <span className="dot-pulse" />
                    </span>
                </Box>
            </Box>
        </LoaderWrapper>
    );
};

ProjectLoader.defaultProps = {
    title: "Gathering info",
};

export { ProjectLoader };

import { useState } from "react";

import NotionGif from "../assets/notion.gif";
import Drawer from "../stories/components/drawer/SwipeableDrawer";
import { EcImage } from "../stories/components/EcImage/EcImage";
import { EcPopover } from "../stories/components/EcPopover/EcPopover";
import NotionLetsStartModal from "../stories/components/NotionLetsStartModal/NotionLetsStartModal";
import { ActionButtonType } from "../types";

const NotionIntegration = (props: any) => {
    const { users, onMouseHover } = props;
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);

    // EC popover states
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(undefined);
    };
    const open = Boolean(anchorEl);
    const actionButtons: ActionButtonType[] = [
        {
            label: "Connect Now",
            onClick: () => {
                setOpenModal(!openModal);
                handleClose();
            },
            variant: "contained",
        },
        {
            label: "Ok, got it",
            onClick: () => {
                handleClose();
            },
            variant: "outlined",
        },
    ];

    const handleDrawerOpen = (e: any) => {
        if (users.notionConnected) {
            setOpenDrawer(!openDrawer);
        } else {
            handleClick(e);
        }
    };

    return (
        <>
            <EcImage
                style={{ cursor: "pointer" }}
                onClick={(e) => handleDrawerOpen(e)}
                onMouseOver={() => onMouseHover(true)}
                onMouseLeave={() => onMouseHover(false)}
                id="NB-notion-drawer"
            >
                <img width="100%" src={NotionGif} alt="Notion" />
            </EcImage>
            <Drawer
                onCloseDrawer={() => setOpenDrawer(!openDrawer)}
                openDrawer={openDrawer}
                setOpendrawer={setOpenDrawer}
                users={users}
                title="Notion Integration"
                customeWidth="true"
            />
            <EcPopover
                title="Connect to Notion"
                description="You can connect anytime from here and sync your comments seamlessly."
                open={open}
                anchorEl={anchorEl}
                actionButtons={actionButtons}
                handleClose={handleClose}
            />
            <NotionLetsStartModal open={openModal} onClose={() => setOpenModal(!openModal)} />
        </>
    );
};

export default NotionIntegration;

import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { get, isEqual } from "lodash";
import moment from "moment";
import { useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import DataGrid, { Column, FormatterProps, SortColumn } from "react-data-grid";
import { useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";

import { APP_CONSTANT } from "../constants/AppConstant";
import { NoCommentsFound } from "../containers/Comments";
import { PageNotFound } from "../containers/Common/PageNotFound";
import { SizeContext, UserContext } from "../contexts";
import {
    Projects,
    useCommonPost,
    useDeleteComment,
    useFetchFigmaUserData,
    useFetchPrototypeList,
    useFetchTreeComments,
    useMarkCommentsAsRead,
    useProjectConnect,
    useProjectDisconnect,
    useReactionMutation,
    useReplyOnComment,
    useSyncComments,
} from "../hooks";
import { CommentBox } from "../stories/components/comment-box/CommentBox";
import { CreateIssue } from "../stories/components/create-issue/CreateIssue";
import Drawer from "../stories/components/drawer/SwipeableDrawer";
import { EcModal } from "../stories/components/EcModal/EcModal";
import { ActionsCell } from "../stories/components/grid-cells/actions/ActionsCell";
import { CommentCell } from "../stories/components/grid-cells/comment/CommentCell";
import { CreatedByCell } from "../stories/components/grid-cells/created-by/CreatedByCell";
import { DateCell } from "../stories/components/grid-cells/date/DateCell";
import { StatusCell } from "../stories/components/grid-cells/status/StatusCell";
import { Loader } from "../stories/components/Loader/Loader";
import { ShareFile } from "../stories/components/share-file/ShareFile";
import { SubHeader } from "../stories/components/sub-header/SubHeader";
import {
    ActionButtonType,
    IFavoriteRequestType,
    IFileUser,
    IFiterTypeOption,
    IRowType,
    ISnackBarMessage,
    ITreeCommentResponse,
    ITreeRowType,
    ReactionActionType,
    ReactionType,
} from "../types";
import {
    CheckIcon,
    exportCommentsToExcel,
    getAllComments,
    getAllUnreadComments,
    getAllUnreadReplies,
    getLoadingImage,
    getLoadingMessage,
    getMyOwnComments,
    getResolvedFilteredComments,
    getUnResolvedFilteredComments,
    processDeleteComment,
    processReplyCommnet,
    processTreeData,
    snackbar,
    sortingTreeData,
    toggleSubRow,
} from "../utils";
import { PrototypePage } from "./PrototypePage";

const ViewByDiv = styled.div`
    @media screen and (max-width: 767px) {
        &.viewBy-option {
            margin-bottom: 5px;
            padding-right: 15px;
        }
    }
`;
const GridContainer = styled.div`
    padding: 10px 20px;
    width: 100%;
    .rdg {
        border: 0;
        resize: none !important;
        background-color: #f5f5f5 !important;
        &.grid {
            &.noData {
                height: 60px !important;
            }
        }
    }
    .rdg-header-row {
        .rdg-cell {
            background-color: #fff;
            border-left: 0;
            border-right: 0;
            border-bottom: 1px solid #e0e0e0;
            padding: 0 10px;
            &.orderId {
                white-space: normal;
                line-height: 1.2;
                max-width: 5rem;
            }
            .rdg-sort-arrow {
                margin-left: 8px;
            }
            &:hover {
                &::after {
                    background-color: #e0e0e0;
                    height: 26px;
                    top: 12px;
                    width: 2px;
                }
            }
        }
    }
    .parent {
        background-color: #fff;
        color: #212121;
        border-bottom: 1px solid #e0e0e0;
        &:hover,
        &:active {
            background-color: rgb(206, 221, 247) !important;
        }
    }
    .parent-with-children {
        background-color: #fff;
        color: #212121;
        border-bottom: 1px solid #e0e0e0;
        &:hover,
        &:active {
            background-color: rgb(206, 221, 247);
        }
    }
    .highlighted-row {
        background-color: rgb(206, 221, 247) !important;

        .highlight-icon {
            display: inline-flex;
        }
    }
    .child {
        background-color: #ffffff;
        border-bottom: 0;
        color: #212121;
        .rdg-cell {
            border-bottom: 1px solid #e0e0e0;
            padding: 0 10px;
            &:first-child {
                border-bottom: 0;
            }

            .unresolved,
            .resolved {
                display: none;
            }
        }
        .rdg-checkbox-label {
            display: none;
        }
        .child-action-disable {
            color: #9e9e9e;
            cursor: not-allowed;
            pointer-events: none;
        }
    }
    .rdg-cell {
        box-shadow: none !important;
        border-right: 0;
        border-bottom: 0;
        display: flex;
        align-items: center;
        padding: 0 10px;
    }
`;

function rowKeyGetter(row: ITreeRowType) {
    return row.id;
}
const Treeview = snackbar((props: ISnackBarMessage) => {
    const { isFetching: isFetchingFigmaUser } = useFetchFigmaUserData();
    const WindowContext: any = () => useContext(SizeContext);
    const { width } = WindowContext();
    const columns: readonly Column<ITreeRowType>[] = [
        {
            key: "orderId",
            name: width > 767 ? "ID #" : "#",
            width: width > 767 ? 60 : 45,
            minWidth: width > 767 ? 60 : 45,
            frozen: true,
            headerCellClass: "orderId",
        },

        {
            key: "message",
            name: "Comments",
            minWidth: 700,
            resizable: true,
            formatter({ row, isCellSelected }) {
                return (
                    <CommentCell
                        row={row}
                        isCellSelected={isCellSelected}
                        onCellExpand={() => {
                            setRowMethod({ id: row.id });
                        }}
                        handleAction={(para: string, prop: any) => {
                            handleAction(para, prop);
                        }}
                        onAddOrRemoveReaction={(reaction, action) =>
                            handleAddOrRemoveReaction(reaction, action, row.id)
                        }
                    />
                );
            },
        },
        {
            key: "userName",
            name: "Created By",
            width: 165,
            resizable: true,
            formatter: (row: React.PropsWithChildren<FormatterProps<ITreeRowType, unknown>>) => (
                <CreatedByCell value={row} />
            ),
        },

        {
            key: "createdAt",
            name: "Created On",
            width: 110,
            formatter: (row: React.PropsWithChildren<FormatterProps<ITreeRowType, unknown>>) => (
                <DateCell value={row} />
            ),
        },
        {
            key: "status",
            name: "Status",
            width: 110,
            formatter: (row: React.PropsWithChildren<FormatterProps<ITreeRowType, unknown>>) => (
                <StatusCell value={row} />
            ),
        },
        {
            key: "resolvedAt",
            name: "Resolved On",
            width: 150,
            formatter: (row: React.PropsWithChildren<FormatterProps<ITreeRowType, unknown>>) => (
                <DateCell value={row} />
            ),
        },

        {
            key: "action",
            name: "Actions",
            width: 100,
            resizable: false,
            formatter: (row: React.PropsWithChildren<FormatterProps<ITreeRowType, unknown>>) => (
                <ActionsCell
                    from="comments"
                    defaultLabel="Reply"
                    defaultActions="reply"
                    onAction={(para: string, prop: any) => {
                        handleAction(para, prop);
                    }}
                    value={row.row}
                />
            ),
        },
    ];

    const params: {
        projectId: string;
    } = useParams();

    const { projectId } = params;
    // const dataProjectType = "figjam";
    const queryClient = useQueryClient();
    const { user, commentType, setCommentType, isDisable, setIsDisable } = UserContext();
    const [expandedRows, setExapndedRows] = useState([] as { id: string }[]);
    const [commentsFileInfo, setCommentsFileInfo] = useState({} as ITreeCommentResponse);
    const [comments, setComments] = useState([] as ITreeRowType[]);
    const [prototypes, setPrototypes] = useState({} as any);
    const [originalComments, setOriginalComments] = useState([] as IRowType[]);
    const [commentBox, setCommentBox] = useState(false as boolean);
    const [currentRow, setCurrentRow] = useState({} as ITreeRowType);
    const [filterType, setFilterType] = useState("total" as string);
    const [shareSheet, setShareSheet] = useState(false as boolean);
    const { mutate: getFileUser, data: responseData } = useCommonPost();
    const [fileUsers, setFileUsers] = useState([] as IFileUser[]);
    const [searchText, setSearchtext] = useState("" as string);

    const [filterData, setFilterData] = useState([] as ITreeRowType[]);
    const [searchPrototypeText, setSearchPrototypeText] = useState("" as string);
    const [filterPrototypeData, setFilterPrototypeData] = useState([] as any[]);
    const [loader, setLoader] = useState(false);
    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
    const [issue, setIssue] = useState(false as boolean);
    const [height, setHeight] = useState(window.innerHeight);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [loadingImage, setLoadingImage] = useState("");
    const [openDrawer, setOpendrawer] = useState(false);
    const [notionDisconnected, setNotionDisconnected] = useState(false);
    const [notionConnected, setNotionConnected] = useState(false);
    const [syncCommentModelopen, setSyncCommentModelOpen] = useState(false);
    const [branchKey, setBranchKey] = useState("");
    const [selectedBranchName, setSelectedBranchName] = useState("");
    const [view, setView] = useState("comments");

    const handleChangeView = (event: React.MouseEvent<HTMLElement>, value: string) => {
        if (value) {
            setView(value);
        }
    };
    const syncCommentModelhandleOpen = () => setSyncCommentModelOpen(true);
    const syncCommentModelhandleClose = () => setSyncCommentModelOpen(false);
    const actionButtons: ActionButtonType = {
        label: "Let's Start Working",
        onClick: () => {
            syncCommentModelhandleClose();
        },
        variant: "contained",
    };

    const { mutate: markedAsFavorite, data: favouriteResponse } = useCommonPost({
        alertRequired: true,
    });

    const { disconnectProject } = useProjectDisconnect(props);

    const { mutate: disconnectNotionProject } = disconnectProject;

    const { connectProject } = useProjectConnect(props);

    const { mutate: connectNotionProject } = connectProject;

    const { addReaction, deleteReaction } = useReactionMutation();

    const { mutate: addReactionToComment } = addReaction;

    const { mutate: deleteReactionFromComment } = deleteReaction;

    const [selectedRows, setSelectedRows] = useState<ReadonlySet<string>>(() => new Set());

    const {
        mutate: replyComment,
        data: replyResponse,

        reset: resetreply,
    } = useReplyOnComment(props);
    const {
        mutate: deleteComment,
        data: deleteResponse,

        reset: resetDelete,
    } = useDeleteComment(props);
    const { syncComments } = useSyncComments(props);
    const {
        mutate: syncCommentsExecute,
        isSuccess: isSyncCommentsSuccess,
        isLoading: syncingComments,
    } = syncComments;

    useEffect(() => {
        if (isSyncCommentsSuccess) {
            queryClient.refetchQueries([Projects]);
        }
    }, [isSyncCommentsSuccess]);

    const { data, isLoading, refetch, error } = useFetchTreeComments(filterType, props, branchKey);
    const { mutate: getMarkAsReadResponse, data: updateMarkAsRead } = useMarkCommentsAsRead(props);

    const {
        data: prototypeData,
        isLoading: prototypeLoading,
        refetch: refetchPrototypeData,
    } = useFetchPrototypeList(branchKey);
    useEffect(() => {
        refetch();
    }, [branchKey, view]);

    useEffect(() => {
        refetchPrototypeData();
    }, [branchKey]);

    useLayoutEffect(() => {
        function updateSize() {
            setHeight(window.innerHeight);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    const callDeleteApi = (id: string) => {
        deleteComment({
            projectId,
            commentId: id,
            branchKey,
        });
    };

    const handleAddOrRemoveReaction = (
        reaction: ReactionType,
        action: ReactionActionType,
        commentId: string
    ) => {
        switch (action) {
            case "add":
                addReactionToComment({
                    projectId,
                    commentId,
                    emoji: reaction,
                    branchKey,
                });
                break;

            default:
                deleteReactionFromComment({
                    projectId,
                    commentId,
                    emoji: reaction,
                    branchKey,
                });
                break;
        }
    };

    const handleAction = (parameter: string, prop: any) => {
        if (parameter === "reply") {
            if (prop.row) {
                setCurrentRow(prop.row);
            } else if (prop.id) {
                setCurrentRow(prop);
            } else if (prop.value.row) {
                setCurrentRow(prop.value.row);
            }
            setOpendrawer(true);
            action("share", {});
        } else if (parameter === "add") {
            setCommentBox(true);
        } else if (parameter === "submit") {
            callReplyApi(prop);
            setCommentBox(false);
        } else if (parameter === "cancel-pop") {
            setCommentBox(false);
            setOpendrawer(false);
            setIssue(false);
        } else if (parameter === "delete") {
            if (prop.value) {
                setCurrentRow(prop.value.row);
                callDeleteApi(prop.value.row.id);
            } else {
                setCurrentRow(prop);
                callDeleteApi(prop.id);
            }
        } else if (parameter === "Convert into Issue") {
            setCurrentRow(prop.value.row);
            setIssue(true);
        } else if (parameter === "submit-issue") {
            setIssue(false);
        }
    };
    useEffect(() => {
        setPrototypes(prototypeData);
        setFilterPrototypeData(prototypeData);
        refetchPrototypeData();
    }, [prototypeData]);
    useEffect(() => {
        if (notionDisconnected && projectId) {
            disconnectNotionProject(parseInt(projectId, 10));
            setNotionDisconnected(false);
            refetch();
        }
        if (notionConnected && projectId) {
            connectNotionProject(parseInt(projectId, 10));
            setNotionConnected(false);
            refetch();
        }
    }, [notionDisconnected, notionConnected]);

    const callReplyApi = (value: any) => {
        replyComment({
            message: value,
            userId: user.id,
            projectId,
            branchKey,
            parentId: currentRow.id,
        });
    };

    const sortedRows = useMemo((): readonly ITreeRowType[] => {
        if (sortColumns.length === 0) return filterData;
        const sortedData = sortingTreeData(
            data && data.data.comments.sortedResponse ? data.data.comments.sortedResponse : [],
            sortColumns || [],
            expandedRows,
            searchText
        );
        return sortedData;
    }, [filterData, sortColumns]);

    const location = useLocation();
    useEffect(() => {
        setLoader(true);
    }, [commentType]);

    useEffect(() => {
        const comingCommentType: any = location.state;
        if (comingCommentType === "total") {
            setCommentType("All Comments");
        } else if (comingCommentType === "resolved") {
            setCommentType("Resolved Comments");
        } else if (comingCommentType === "unResolved") {
            setCommentType("Unresolved Comments");
        } else if (comingCommentType === "unreadComments") {
            setCommentType("Unread Comments");
        } else if (comingCommentType === "unreadReplies") {
            setCommentType("Unread Replies");
        } else if (comingCommentType === "own") {
            setCommentType("My Comments");
        }
        setLoadingMessage(getLoadingMessage("comment"));
        setLoadingImage(getLoadingImage("comment"));
        setFilterType(comingCommentType);
        refetch();
    }, [location]);

    useEffect(() => {
        const toggleButtonType: any = location.state;
        if (toggleButtonType === "prototypes") {
            setView("prototypes");
        }
    }, [location]);

    useEffect(() => {
        setLoader(false);
        resetreply();
        resetDelete();
        setLoader(false);
        if (data) {
            setCommentsFileInfo(data);
            setOriginalComments(data.data.comments.originalResponse || []);
            let commentData = get(data, ["data", "comments", "comments", "comments"]);
            commentData = processTreeData(commentData, expandedRows);
            if (openDrawer) {
                commentData.forEach((row: any) => {
                    if (row.id === currentRow.id) {
                        setCurrentRow(row);
                    }
                });
            }
            if (!isEqual(comments, commentData)) {
                setComments(commentData);
                setFilterData(commentData);
            }
        }
    }, [data]);

    useEffect(() => {
        let newData = {} as any;
        if (replyResponse && data) {
            newData = processReplyCommnet(data, replyResponse.data.data, filterType, user);
        }

        if (data && newData?.data) {
            const latestData = newData.data.comments.comments.comments;
            latestData.forEach((row: any) => {
                if (row.id === currentRow.id) {
                    setCurrentRow(row);
                }
            });
            data.data.comments.originalResponse = newData.data.comments.originalResponse;
            data.data.comments.comments.comments = newData.data.comments.comments.comments;
            let commentData = get(data, ["data", "comments", "comments", "comments"]);
            commentData = processTreeData(commentData, expandedRows);
            setComments(commentData);
            setFilterData(commentData);
        }
    }, [replyResponse]);

    useEffect(() => {
        let newData = {} as any;
        const { parentId } = currentRow;
        if (deleteResponse && data) {
            newData = processDeleteComment(data, currentRow, filterType, user);
        }
        if (data && newData?.data) {
            const latestData = newData.data.comments.comments.comments;
            latestData.forEach((row: any) => {
                if (row.id === parentId) {
                    setCurrentRow(row);
                }
            });
            data.data.comments.originalResponse = newData.data.comments.originalResponse;
            data.data.comments.comments.comments = newData.data.comments.comments.comments;
            let commentData = get(data, ["data", "comments", "comments", "comments"]);
            commentData = processTreeData(commentData, expandedRows);
            setComments(commentData);
            setFilterData(commentData);
        }
    }, [deleteResponse]);

    useEffect(() => {
        if (responseData) {
            let userResponse = responseData.data.data;
            userResponse = userResponse.map((obj: IFileUser) => ({
                ...obj,
                selected: false,
                editValue: false,
                tempEmail: "",
                validEmail: obj.email ? "valid" : "",
                iseditable: !obj.email,
            }));
            setFileUsers(userResponse);
            if (openDrawer === false) {
                setShareSheet(true);
            }
        }
    }, [responseData]);

    useEffect(() => {
        if (favouriteResponse) {
            if (data?.data?.comments?.fileInfo?.fileName) {
                data.data.comments.fileInfo.isFavorite = !data.data.comments.fileInfo?.isFavorite;
            }
        }
    }, [favouriteResponse]);

    useEffect(() => {
        if (updateMarkAsRead) {
            const treeViewComments = [...comments];
            treeViewComments.forEach((comment: ITreeRowType) => {
                if (comment.parentId !== "")
                    comment.unreadReply =
                        new Date(updateMarkAsRead.data.createdAt) < new Date(comment.createdAt);
                else
                    comment.unreadComment =
                        new Date(updateMarkAsRead.data.createdAt) < new Date(comment.createdAt);
            });
            setComments(treeViewComments);
        }
    }, [updateMarkAsRead]);

    useEffect(() => {
        if (isSyncCommentsSuccess) syncCommentModelhandleOpen();
    }, [isSyncCommentsSuccess]);

    if (isLoading) {
        if (comments && comments?.length === 0 && view === "comments") {
            return <Loader message={loadingMessage} image={loadingImage} page="comment" />;
        }
    }
    if (prototypeLoading) {
        return <Loader message={loadingMessage} image={loadingImage} page="comment" />;
    }

    const findRow = (row: ITreeRowType): string => {
        if (row.parentId) {
            if (row.unreadComment) {
                return "child highlighted-row";
            }
            return "child";
        }
        if (row.children && row.children.length) {
            if (row.unreadComment) {
                return "parent-with-children highlighted-row";
            }
            return "parent-with-children";
        }
        if (row.children && row.children.length === 0) {
            if (row.unreadComment) {
                return "parent highlighted-row";
            }
            return "parent";
        }
        if (row.unreadComment) {
            return "parent highlighted-row";
        }
        return "parent";
    };

    const setRowMethod = (value: { id: string }) => {
        const process = toggleSubRow(filterData, value.id);
        if (process) {
            setComments(process);
            setFilterData(process);
            setExapndedRows(process.filter((comment: ITreeRowType) => comment.isExpanded));
        }
    };
    const handleFilterClick = (item: string) => {
        setLoader(true);
        setFilterType(item);
        refetch();
    };

    let filterOptions: IFiterTypeOption[] = [] as IFiterTypeOption[];

    if (data) {
        if (data.data.comments.originalResponse) {
            const response = data.data.comments.originalResponse;
            let lengthOfMyOwnComments = 0;
            const myOwnComments = getMyOwnComments(response, user.figmaUserId).filteredRows;
            myOwnComments.forEach((index) => {
                if (index.user.id === user.figmaUserId) {
                    lengthOfMyOwnComments += 1;
                }
            });
            if (isDisable) {
                filterOptions = [
                    {
                        name: "All Comments",
                        id: "total",
                        record: getAllComments(response).filteredRows.length,
                    },
                    {
                        name: "Resolved Comments",
                        id: "resolved",
                        record: getResolvedFilteredComments(response).filteredRows.length,
                    },
                    {
                        name: "Unresolved Comments",
                        id: "unResolved",
                        record: getUnResolvedFilteredComments(response).filteredRows.length,
                    },
                    {
                        name: "Unread Comments",
                        id: "unreadComments",
                        record: 0,
                    },
                    {
                        name: "Unread Replies",
                        id: "unreadReplies",
                        record: 0,
                    },
                    {
                        name: "My Comments",
                        id: "own",
                        record: lengthOfMyOwnComments,
                    },
                ];
            } else {
                filterOptions = [
                    {
                        name: "All Comments",
                        id: "total",
                        record: getAllComments(response).filteredRows.length,
                    },
                    {
                        name: "Resolved Comments",
                        id: "resolved",
                        record: getResolvedFilteredComments(response).filteredRows.length,
                    },
                    {
                        name: "Unresolved Comments",
                        id: "unResolved",
                        record: getUnResolvedFilteredComments(response).filteredRows.length,
                    },
                    {
                        name: "Unread Comments",
                        id: "unreadComments",
                        record: getAllUnreadComments(response, data.data.comments.fileInfo).count,
                    },
                    {
                        name: "Unread Replies",
                        id: "unreadReplies",
                        record: getAllUnreadReplies(response, data.data.comments.fileInfo).count,
                    },
                    {
                        name: "My Comments",
                        id: "own",
                        record: lengthOfMyOwnComments,
                    },
                ];
            }
        }
    }

    const searchChange = (searchEvent: React.ChangeEvent<HTMLInputElement>) => {
        setSearchtext(searchEvent.target.value);
        if (searchEvent.target.value) {
            setFilterData(
                comments.filter((comment: ITreeRowType) =>
                    rowMatched(comment, searchEvent.target.value)
                )
            );
        } else {
            setFilterData(comments);
        }
    };
    const searchPrototypeChange = (searchEvent: React.ChangeEvent<HTMLInputElement>) => {
        setSearchPrototypeText(searchEvent.target.value);
        if (searchEvent.target.value) {
            setFilterPrototypeData(
                prototypes.filter((datas: any) =>
                    rowPrototypeMatched(datas, searchEvent.target.value)
                )
            );
        } else {
            setFilterPrototypeData(prototypes);
        }
    };
    const rowPrototypeMatched = (row: any, text: string): boolean => {
        let assumeNotMatch = false;
        Object.keys(row).forEach((key: any) => {
            if (key === "name") {
                if (row[key] && row[key].toString().toLowerCase().includes(text.toLowerCase())) {
                    assumeNotMatch = true;
                }
            }
        });
        return assumeNotMatch;
    };
    const rowMatched = (row: any, text: string): boolean => {
        let assumeNotMatch = false;
        let createdDate = "";
        let resolvedDate;
        const latest = moment(new Date());
        const createdDatefromFile = moment(row.createdAt);
        if (createdDatefromFile.diff(latest, "day") === 0) {
            createdDate = moment(new Date(row.createdAt)).format("h:mm a");
        } else {
            createdDate = moment(new Date(row.createdAt)).format("ll");
        }
        if (row.resolvedAt != null) {
            const latestDate = moment(new Date());
            const resolvedDatefromFile = moment(row.resolvedAt);
            if (resolvedDatefromFile.diff(latestDate, "day") === 0) {
                resolvedDate = moment(new Date(row.resolvedAt)).format("h:mm a");
            } else {
                resolvedDate = moment(new Date(row.resolvedAt)).format("ll");
            }
        }
        Object.keys(row).forEach((key: any) => {
            if (
                key !== "children" &&
                key !== "id" &&
                key !== "fileKey" &&
                key !== "parentId" &&
                key !== "clientMeta" &&
                key !== "commentLocation" &&
                key !== "unreadComment" &&
                key !== "unreadReply" &&
                key !== "isExpanded" &&
                key !== "myComment" &&
                key !== "createdAt" &&
                key !== "resolvedAt" &&
                key !== "markAsReadCreatedAt"
            ) {
                if (row[key] && row[key].toString().toLowerCase().includes(text.toLowerCase())) {
                    assumeNotMatch = true;
                }
            }
        });
        if (createdDate && createdDate.toString().toLowerCase().includes(text.toLowerCase())) {
            assumeNotMatch = true;
        }
        if (resolvedDate && resolvedDate.toString().toLowerCase().includes(text.toLowerCase())) {
            assumeNotMatch = true;
        }
        return assumeNotMatch;
    };

    const action = (id: string, object: any) => {
        if (id === "share") {
            getFileUser({
                body: { projectId },
                url: APP_CONSTANT.webApi.userFiles,
            });
        }
        if (id === "fav") {
            const obj: IFavoriteRequestType = {} as IFavoriteRequestType;
            obj.projectId = parseInt(projectId, 10);
            obj.status = object.isFavorite ? 0 : 1;
            markedAsFavorite({
                body: obj,
                url: APP_CONSTANT.webApi.userFavorite,
            });
        }
    };
    const close = () => {
        setShareSheet(false);
    };

    const exportExls = () => {
        exportCommentsToExcel(originalComments, filterType, user, commentsFileInfo);
    };
    // sync comments
    const handleExportToNotion = () => {
        syncCommentsExecute({ projectId: parseInt(projectId, 10) });
    };

    const handleMarkAsReadClick = () => {
        setIsDisable(true);
        getMarkAsReadResponse({
            projectId,
        });
    };

    return (
        <>
            {(loader || syncingComments || (isFetchingFigmaUser && !error)) && (
                <>
                    <Loader message={loadingMessage} image={loadingImage} page="comment" />
                </>
            )}
            <EcModal
                icon={<CheckIcon sx={{ fontSize: 60, color: "#00ca5f" }} />}
                open={syncCommentModelopen}
                onClose={syncCommentModelhandleClose}
                title="Comments Synced Successfully"
                subtitle="You have synced the comments to your Notion account successfully. You can see these comments in the Notion "
                actionButtons={actionButtons}
            />

            {!data && <PageNotFound />}

            {view === "comments" && data && !loader && (
                <div>
                    <SubHeader
                        fileName={data?.data?.comments?.fileInfo?.fileName}
                        editorType={data?.data?.comments?.fileInfo?.editorType}
                        filterOptions={filterOptions}
                        handleFilterClick={handleFilterClick}
                        filterType={filterType}
                        searchText={searchText}
                        searchChange={searchChange}
                        actions={action}
                        fileInfo={data?.data?.comments?.fileInfo}
                        exportExls={exportExls}
                        handleExportToNotion={handleExportToNotion}
                        handleMarkAsReadClick={handleMarkAsReadClick}
                        syncingComments={syncingComments}
                        aligment={view}
                        setBranchKey={setBranchKey}
                        setSelectedBranchName={setSelectedBranchName}
                        selectedBranchName={selectedBranchName}
                    />
                </div>
            )}
            {view === "prototypes" && prototypeData && !loader && (
                <div>
                    <SubHeader
                        fileName={data?.data?.comments?.fileInfo?.fileName}
                        editorType={data?.data?.comments?.fileInfo?.editorType}
                        filterOptions={filterOptions}
                        searchText={searchPrototypeText}
                        searchChange={searchPrototypeChange}
                        actions={action}
                        fileInfo={data?.data?.comments?.fileInfo}
                        exportExls={exportExls}
                        handleExportToNotion={handleExportToNotion}
                        syncingComments={syncingComments}
                        aligment={view}
                        setBranchKey={setBranchKey}
                        setSelectedBranchName={setSelectedBranchName}
                        selectedBranchName={selectedBranchName}
                    />
                </div>
            )}

            {data && !loader && view === "comments" && (
                <ViewByDiv
                    className="viewBy-option"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "98.5%",
                    }}
                >
                    <Typography
                        style={{
                            marginRight: "10px",
                            fontSize: "15px",
                            fontWeight: "bold",
                        }}
                    >
                        View by
                    </Typography>
                    <ToggleButtonGroup
                        color="primary"
                        value={view}
                        exclusive
                        onChange={handleChangeView}
                        aria-label="Platform"
                        sx={{ height: "40px" }}
                        disabled={data?.data?.comments?.fileInfo?.editorType === "figjam"}
                    >
                        <ToggleButton value="comments" sx={{ textTransform: "none" }}>
                            Comments
                        </ToggleButton>
                        <ToggleButton value="prototypes" sx={{ textTransform: "none" }}>
                            Prototypes
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ViewByDiv>
            )}
            {prototypes && !loader && view === "prototypes" && (
                <ViewByDiv
                    className="viewBy-option"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "98.5%",
                    }}
                >
                    <Typography
                        style={{
                            marginRight: "10px",
                            fontSize: "15px",
                            fontWeight: "bold",
                        }}
                    >
                        View by
                    </Typography>
                    <ToggleButtonGroup
                        color="primary"
                        value={view}
                        exclusive
                        onChange={handleChangeView}
                        aria-label="Platform"
                        sx={{ height: "40px" }}
                    >
                        <ToggleButton value="comments" sx={{ textTransform: "none" }}>
                            Comments
                        </ToggleButton>
                        <ToggleButton value="prototypes" sx={{ textTransform: "none" }}>
                            Prototypes
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ViewByDiv>
            )}

            {data &&
                comments &&
                !loader &&
                !syncingComments &&
                comments.length !== 0 &&
                filterData.length !== 0 &&
                view === "comments" && (
                    <GridContainer>
                        <DataGrid
                            className={comments.length === 0 ? "grid noData" : "grid"}
                            style={{
                                height: `${height - 210}px`,
                                resize: "both",
                            }}
                            defaultColumnOptions={{
                                sortable: true,
                                resizable: true,
                            }}
                            headerRowHeight={50}
                            rowHeight={82}
                            sortColumns={sortColumns}
                            onSortColumnsChange={setSortColumns}
                            columns={columns}
                            rows={sortedRows}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={setSelectedRows}
                            rowKeyGetter={rowKeyGetter}
                            rowClass={(row) => findRow(row)}
                        />

                        {currentRow && commentBox && (
                            <CommentBox
                                onAction={(para: string, prop: any) => handleAction(para, prop)}
                            />
                        )}

                        {currentRow && openDrawer && (
                            <Drawer
                                onCommentsDrawer={(para: string, prop: any) =>
                                    handleAction(para, prop)
                                }
                                row={currentRow}
                                openDrawer={openDrawer}
                                setOpendrawer={setOpendrawer}
                                projectId={projectId || ""}
                                users={fileUsers}
                                title="All Replies"
                                isComment
                                onAddOrRemoveReaction={(
                                    reaction: ReactionType,
                                    reactionAction: ReactionActionType,
                                    commentId: string
                                ) => {
                                    handleAddOrRemoveReaction(reaction, reactionAction, commentId);
                                }}
                                customeWidth="true"
                            />
                        )}

                        {currentRow && issue && (
                            <CreateIssue
                                row={currentRow}
                                projectId={projectId}
                                handleAction={(para: string, prop: any) => handleAction(para, prop)}
                            />
                        )}
                    </GridContainer>
                )}
            {view === "prototypes" && filterPrototypeData && (
                <PrototypePage
                    filterPrototypeData={filterPrototypeData}
                    projectId={projectId}
                    setFilterPrototypeData={setFilterPrototypeData}
                    branchKeyPrototype={branchKey}
                    branchName={selectedBranchName}
                    customWidth="true"
                />
            )}

            {((data && comments?.length === 0) || filterData?.length === 0) &&
                !loader &&
                error === null &&
                view === "comments" && <NoCommentsFound />}
            {shareSheet && !openDrawer && (
                <ShareFile
                    projectId={projectId || ""}
                    users={fileUsers}
                    close={close}
                    snackbarShowMessage={props.snackbarShowMessage}
                />
            )}
        </>
    );
});

export { Treeview };
